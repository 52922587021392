import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as QR from '../custom-files/QR';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  HStack,
  ScreenContainer,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const EnrolStudentScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [qrData, setQrData] = React.useState('');
  const [qrError, setQrError] = React.useState('');
  const xanoAPIAddLearner$Teacher$PUT = XanoAPIApi.useAddLearner$Teacher$PUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Enrol Student',
      });
      setQrData('');
      console.log(Constants['authToken']);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { flex: 1, maxHeight: dimensions.height },
        dimensions.width
      )}
    >
      <HeaderBlock />
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, gap: 20, margin: 30 },
          dimensions.width
        )}
      >
        <WebView
          allowFileAccessFromFileURLs={false}
          allowUniversalAccessFromFileURLs={false}
          cacheEnabled={true}
          incognito={false}
          javaScriptCanOpenWindowsAutomatically={false}
          javaScriptEnabled={true}
          mediaPlaybackRequiresUserAction={false}
          startInLoadingState={false}
          {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          source={{
            html: `<div style="width:100%;height:300px; border:none;" data-fillout-id="kfkqh2wLAeus" data-fillout-embed-type="standard" data-fillout-inherit-parameters data-fillout-dynamic-resize data-teacherId=${JSON.stringify(
              Constants['UUID']
            )} data-teacherName=${
              Constants['name']
            } data-authToken=${JSON.stringify(
              Constants['authToken']
            )}></div><script src="https://server.fillout.com/embed/v1/"></script>`,
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.WebViewStyles(theme)['HTML View'].style,
              { borderWidth: 0 }
            ),
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(EnrolStudentScreen);
