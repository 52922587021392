import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';

const TypewriterText = ({ text, onTypingEnd, textStyle }) => {
  const [typedText, setTypedText] = useState('');
  const isTyping = typedText.length < text.length;

  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setTypedText(text.substring(0, typedText.length + 1));
      }, 75); // This controls the speed of the typing
      return () => clearTimeout(timeout);
    } else {
      onTypingEnd && onTypingEnd();
    }
  }, [typedText, text, onTypingEnd]);

  return (
    <Text style={textStyle}>
      {typedText}
      {isTyping ? '|' : ''}
    </Text>
  );
};

export const GreetingComponent = ({ bottomText }) => {
  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <TypewriterText text={bottomText} textStyle={styles.textStyle} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 10,
  },
  textStyle: {
    fontSize: 18, // Adjust size as needed
    fontWeight: 'normal', // Adjust weight as needed
    fontFamily: 'Roboto_400Regular',
    textAlign: 'center',
  },
});

// Usage in Draftbit
// <typewriter_general.GreetingComponent bottomText={'How can I help you today?'} />
