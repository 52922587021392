export default {
  _668996f524584769Ad4dA010f3d1beb4: require('../assets/images/668996f524584769Ad4dA010f3d1beb4.png'),
  UserImage: require('../assets/images/UserImage.png'),
  EditSquare: require('../assets/images/EditSquare.png'),
  IconLock: require('../assets/images/IconLock.png'),
  Unlock: require('../assets/images/Unlock.png'),
  ShieldDone: require('../assets/images/ShieldDone.png'),
  Globe: require('../assets/images/Globe.png'),
  Layer2: require('../assets/images/Layer2.png'),
  Shield: require('../assets/images/Shield.png'),
  Question: require('../assets/images/Question.png'),
  Activity: require('../assets/images/Activity.png'),
  EditProfile: require('../assets/images/EditProfile.png'),
  EvitaAnimationOpt: require('../assets/images/EvitaAnimationOpt.gif'),
  HMBeta: require('../assets/images/HMBeta.png'),
  ScreenShot20211122At33505PM: require('../assets/images/ScreenShot20211122At33505PM.png'),
  _1: require('../assets/images/1.png'),
  _2: require('../assets/images/2.png'),
  _3: require('../assets/images/3.png'),
  _4: require('../assets/images/4.png'),
  HMBG: require('../assets/images/HMBG.png'),
  EvitaGifSmall: require('../assets/images/EvitaGifSmall.gif'),
  Recording: require('../assets/images/Recording.gif'),
  Loading: require('../assets/images/Loading.gif'),
};
