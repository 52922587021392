import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    'App Green': 'rgb(0, 204, 154)',
    'BG Gray': 'rgba(225, 218, 218, 0.15)',
    Background: 'rgb(245, 249, 255)',
    'Custom #002b4b': 'rgb(0, 43, 75)',
    'Custom #00374b': 'rgb(0, 55, 75)',
    'Custom #06303f': 'rgb(6, 48, 63)',
    'Custom #14495c': 'rgb(20, 73, 92)',
    'Custom #329fb3': 'rgb(50, 159, 179)',
    'Custom #3725c4': 'rgb(55, 37, 196)',
    'Custom #4a36e2': 'rgb(74, 54, 226)',
    'Custom #4a36e3': 'rgb(74, 54, 227)',
    'Custom #503bef': 'rgb(80, 59, 239)',
    'Custom #5f5a53': 'rgb(95, 90, 83)',
    'Custom #8a847d': 'rgb(138, 132, 125)',
    'Custom #aaa7a3': 'rgb(170, 167, 163)',
    'Custom #acacac': 'rgb(172, 172, 172)',
    'Custom #d3536d': 'rgb(211, 83, 109)',
    'Custom #d8d8d8': 'rgb(216, 216, 216)',
    'Custom #dbdbdb': 'rgb(219, 219, 219)',
    'Custom #f06454': 'rgb(240, 100, 84)',
    'Custom #ffffff': 'rgb(255, 255, 255)',
    'Custom Color': 'rgb(105, 73, 255)',
    'Custom Color_10': 'rgb(88, 115, 238)',
    'Custom Color_11': 'rgb(243, 102, 102)',
    'Custom Color_12': 'rgb(86, 198, 237)',
    'Custom Color_13': 'rgb(242, 164, 93)',
    'Custom Color_14': 'rgb(108, 108, 108)',
    'Custom Color_15': 'rgb(253, 253, 253)',
    'Custom Color_16': 'rgb(156, 164, 171)',
    'Custom Color_17': 'rgb(189, 189, 189)',
    'Custom Color_18': 'rgb(240, 237, 255)',
    'Custom Color_19': 'rgb(250, 250, 250)',
    'Custom Color_2': 'rgb(33, 33, 33)',
    'Custom Color_20': 'rgb(158, 158, 158)',
    'Custom Color_21': 'rgb(128, 128, 128)',
    'Custom Color_22': 'rgb(0, 0, 0)',
    'Custom Color_23': 'rgb(245, 245, 245)',
    'Custom Color_24': 'rgb(234, 237, 242)',
    'Custom Color_25': 'rgb(235, 237, 241)',
    'Custom Color_26': 'rgb(30, 30, 30)',
    'Custom Color_3': 'rgb(247, 85, 85)',
    'Custom Color_4': 'rgb(238, 238, 238)',
    'Custom Color_5': 'rgb(31, 70, 144)',
    'Custom Color_6': 'rgb(227, 231, 236)',
    'Custom Color_7': 'rgb(67, 78, 88)',
    'Custom Color_8': 'rgb(255, 0, 0)',
    'Custom Color_9': 'rgb(76, 217, 100)',
    Darken: 'rgba(0, 0, 0, 0.1)',
    Divider: 'rgb(232, 232, 232)',
    Error: 'rgba(255, 69, 100, 1)',
    'GetFit Orange': 'rgb(254, 151, 15)',
    'Green BG': 'rgba(0, 204, 154, 0.21)',
    'HM BG': 'rgb(249, 245, 255)',
    HM2: 'rgb(245, 248, 255)',
    Icon_Opacity: 'rgba(0, 0, 0, 0.25)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgba(255, 255, 255, 0.68)',
    Light_Inverse: 'rgba(255, 255, 255, 0.68)',
    Lighten: 'rgba(255, 255, 255, 0.1)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    Medium_Inverse: 'rgba(255, 255, 255, 0.87)',
    Opacity_Blue_for_Button: 'rgba(85, 108, 246, 0.05)',
    Option_Selected_Color: 'rgb(97, 214, 204)',
    People_Bit_Light_Brown: 'rgb(138, 132, 125)',
    Peoplebit_Dark_Blue: 'rgb(0, 43, 75)',
    Peoplebit_Dark_Emerald_Green: 'rgb(20, 73, 92)',
    Peoplebit_Earthy_Brown: 'rgb(95, 90, 83)',
    Peoplebit_Light_Brown: 'rgb(170, 167, 163)',
    Peoplebit_Light_Gray: 'rgb(219, 219, 219)',
    Peoplebit_Light_Stone_Gray: 'rgb(216, 216, 216)',
    Peoplebit_Ocean_Blue: 'rgb(50, 159, 179)',
    Peoplebit_Orange: 'rgb(240, 100, 84)',
    Peoplebit_Salmon_Red: 'rgb(211, 83, 109)',
    Peoplebit_Sapphire: 'rgb(0, 55, 75)',
    Peoplebit_Stone_Gray: 'rgb(172, 172, 172)',
    Peoplebit_Turquoise: 'rgb(6, 48, 63)',
    Peoplebit_White: 'rgb(255, 255, 255)',
    Primary: 'rgba(90, 69, 255, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Settings_Icon_BG_Color: 'rgb(175, 185, 249)',
    ShopAppBlue: 'rgb(14, 134, 212)',
    Strong: 'rgba(18, 20, 44, 1)',
    'Strong Inverse': 'rgba(255, 255, 255, 1)',
    Strong_Inverse: 'rgba(255, 255, 255, 1)',
    Studily_Blue_Night: 'rgb(80, 118, 198)',
    Studily_Bright_Blue: 'rgb(86, 184, 244)',
    Studily_Dark_Primary: 'rgb(17, 17, 17)',
    Studily_Dark_UI: 'rgb(17, 17, 17)',
    Studily_Emerald: 'rgb(126, 209, 172)',
    Studily_Forrest_Shade: 'rgb(126, 209, 172)',
    Studily_Gray_Machine: 'rgb(133, 141, 152)',
    Studily_Lavender_Purple: 'rgb(208, 215, 250)',
    Studily_Light_Blue: 'rgb(55, 66, 83)',
    Studily_Light_Navy_Blue: 'rgb(56, 67, 84)',
    Studily_Light_Navy_Secondary: 'rgb(80, 118, 198)',
    Studily_Milk_White: 'rgb(236, 240, 246)',
    Studily_Mint_Green: 'rgb(97, 214, 204)',
    Studily_Opacity_25: 'rgba(0, 0, 0, 0.25)',
    Studily_Primary: 'rgb(85, 108, 246)',
    Studily_Primary_UI_Dark: 'rgb(55, 66, 83)',
    Studily_Purple_Light: 'rgb(208, 215, 250)',
    Studily_Secondary_UI: 'rgb(133, 141, 152)',
    Studily_Silver_White: 'rgb(236, 240, 246)',
    Studily_Sky_Blue_Primary: 'rgb(86, 184, 244)',
    Studily_Slate_Blue_Dark: 'rgb(56, 67, 84)',
    Studily_Snow_White: 'rgb(230, 235, 244)',
    Studily_Washed_Lavender_White: 'rgb(227, 231, 249)',
    Studily_White: 'rgb(255, 255, 255)',
    Studily_White_BG: 'rgb(243, 245, 249)',
    Studily_White_Shade_1: 'rgb(227, 231, 249)',
    Studily_White_Shade_2: 'rgb(230, 235, 244)',
    Studily_White_Shade_3: 'rgb(231, 235, 241)',
    Studily_White_Shade_5: 'rgb(243, 245, 249)',
    Surface: 'rgba(255, 255, 255, 1)',
    Switch_Color_for_Settings_Page: 'rgb(96, 212, 204)',
    Tab_Divider: 'rgb(231, 235, 241)',
    TextPlaceholder: 'rgb(136, 144, 153)',
    ViewBG: 'rgba(152, 153, 156, 0.25)',
    White: 'rgb(255, 255, 255)',
    appGreen: 'rgb(0, 204, 154)',
    bGGray: 'rgba(225, 218, 218, 0.15)',
    background: 'rgb(245, 249, 255)',
    custom_rgb0_0_0: 'rgb(0, 0, 0)',
    custom_rgb0_43_75: 'rgb(0, 43, 75)',
    custom_rgb0_55_75: 'rgb(0, 55, 75)',
    custom_rgb105_73_255: 'rgb(105, 73, 255)',
    custom_rgb108_108_108: 'rgb(108, 108, 108)',
    custom_rgb128_128_128: 'rgb(128, 128, 128)',
    custom_rgb138_132_125: 'rgb(138, 132, 125)',
    custom_rgb156_164_171: 'rgb(156, 164, 171)',
    custom_rgb158_158_158: 'rgb(158, 158, 158)',
    custom_rgb170_167_163: 'rgb(170, 167, 163)',
    custom_rgb172_172_172: 'rgb(172, 172, 172)',
    custom_rgb189_189_189: 'rgb(189, 189, 189)',
    custom_rgb20_73_92: 'rgb(20, 73, 92)',
    custom_rgb211_83_109: 'rgb(211, 83, 109)',
    custom_rgb216_216_216: 'rgb(216, 216, 216)',
    custom_rgb219_219_219: 'rgb(219, 219, 219)',
    custom_rgb227_231_236: 'rgb(227, 231, 236)',
    custom_rgb234_237_242: 'rgb(234, 237, 242)',
    custom_rgb235_237_241: 'rgb(235, 237, 241)',
    custom_rgb238_238_238: 'rgb(238, 238, 238)',
    custom_rgb240_100_84: 'rgb(240, 100, 84)',
    custom_rgb240_237_255: 'rgb(240, 237, 255)',
    custom_rgb242_164_93: 'rgb(242, 164, 93)',
    custom_rgb243_102_102: 'rgb(243, 102, 102)',
    custom_rgb245_245_245: 'rgb(245, 245, 245)',
    custom_rgb247_85_85: 'rgb(247, 85, 85)',
    custom_rgb250_250_250: 'rgb(250, 250, 250)',
    custom_rgb253_253_253: 'rgb(253, 253, 253)',
    custom_rgb255_0_0: 'rgb(255, 0, 0)',
    custom_rgb255_255_255: 'rgb(255, 255, 255)',
    custom_rgb30_30_30: 'rgb(30, 30, 30)',
    custom_rgb31_70_144: 'rgb(31, 70, 144)',
    custom_rgb33_33_33: 'rgb(33, 33, 33)',
    custom_rgb50_159_179: 'rgb(50, 159, 179)',
    custom_rgb55_37_196: 'rgb(55, 37, 196)',
    custom_rgb67_78_88: 'rgb(67, 78, 88)',
    custom_rgb6_48_63: 'rgb(6, 48, 63)',
    custom_rgb74_54_226: 'rgb(74, 54, 226)',
    custom_rgb74_54_227: 'rgb(74, 54, 227)',
    custom_rgb76_217_100: 'rgb(76, 217, 100)',
    custom_rgb80_59_239: 'rgb(80, 59, 239)',
    custom_rgb86_198_237: 'rgb(86, 198, 237)',
    custom_rgb88_115_238: 'rgb(88, 115, 238)',
    custom_rgb95_90_83: 'rgb(95, 90, 83)',
    darken: 'rgba(0, 0, 0, 0.1)',
    divider: 'rgb(232, 232, 232)',
    error: 'rgba(255, 69, 100, 1)',
    getFitOrange: 'rgb(254, 151, 15)',
    greenBG: 'rgba(0, 204, 154, 0.21)',
    hM2: 'rgb(245, 248, 255)',
    hMBG: 'rgb(249, 245, 255)',
    iconOpacity: 'rgba(0, 0, 0, 0.25)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgba(255, 255, 255, 0.68)',
    lighten: 'rgba(255, 255, 255, 0.1)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    opacityBlueForButton: 'rgba(85, 108, 246, 0.05)',
    optionSelectedColor: 'rgb(97, 214, 204)',
    peopleBitLightBrown: 'rgb(138, 132, 125)',
    peoplebitDarkBlue: 'rgb(0, 43, 75)',
    peoplebitDarkEmeraldGreen: 'rgb(20, 73, 92)',
    peoplebitEarthyBrown: 'rgb(95, 90, 83)',
    peoplebitLightBrown: 'rgb(170, 167, 163)',
    peoplebitLightGray: 'rgb(219, 219, 219)',
    peoplebitLightStoneGray: 'rgb(216, 216, 216)',
    peoplebitOceanBlue: 'rgb(50, 159, 179)',
    peoplebitOrange: 'rgb(240, 100, 84)',
    peoplebitSalmonRed: 'rgb(211, 83, 109)',
    peoplebitSapphire: 'rgb(0, 55, 75)',
    peoplebitStoneGray: 'rgb(172, 172, 172)',
    peoplebitTurquoise: 'rgb(6, 48, 63)',
    peoplebitWhite: 'rgb(255, 255, 255)',
    primary: 'rgba(90, 69, 255, 1)',
    secondary: 'rgba(59, 201, 234, 1)',
    settingsIconBGColor: 'rgb(175, 185, 249)',
    shopAppBlue: 'rgb(14, 134, 212)',
    strong: 'rgba(18, 20, 44, 1)',
    strongInverse: 'rgba(255, 255, 255, 1)',
    studilyBlueNight: 'rgb(80, 118, 198)',
    studilyBrightBlue: 'rgb(86, 184, 244)',
    studilyDarkPrimary: 'rgb(17, 17, 17)',
    studilyDarkUI: 'rgb(17, 17, 17)',
    studilyEmerald: 'rgb(126, 209, 172)',
    studilyForrestShade: 'rgb(126, 209, 172)',
    studilyGrayMachine: 'rgb(133, 141, 152)',
    studilyLavenderPurple: 'rgb(208, 215, 250)',
    studilyLightBlue: 'rgb(55, 66, 83)',
    studilyLightNavyBlue: 'rgb(56, 67, 84)',
    studilyLightNavySecondary: 'rgb(80, 118, 198)',
    studilyMilkWhite: 'rgb(236, 240, 246)',
    studilyMintGreen: 'rgb(97, 214, 204)',
    studilyOpacity25: 'rgba(0, 0, 0, 0.25)',
    studilyPrimary: 'rgb(85, 108, 246)',
    studilyPrimaryUIDark: 'rgb(55, 66, 83)',
    studilyPurpleLight: 'rgb(208, 215, 250)',
    studilySecondaryUI: 'rgb(133, 141, 152)',
    studilySilverWhite: 'rgb(236, 240, 246)',
    studilySkyBluePrimary: 'rgb(86, 184, 244)',
    studilySlateBlueDark: 'rgb(56, 67, 84)',
    studilySnowWhite: 'rgb(230, 235, 244)',
    studilyWashedLavenderWhite: 'rgb(227, 231, 249)',
    studilyWhite: 'rgb(255, 255, 255)',
    studilyWhiteBG: 'rgb(243, 245, 249)',
    studilyWhiteShade1: 'rgb(227, 231, 249)',
    studilyWhiteShade2: 'rgb(230, 235, 244)',
    studilyWhiteShade3: 'rgb(231, 235, 241)',
    studilyWhiteShade5: 'rgb(243, 245, 249)',
    surface: 'rgba(255, 255, 255, 1)',
    switchColorForSettingsPage: 'rgb(96, 212, 204)',
    tabDivider: 'rgb(231, 235, 241)',
    'text placeholder': 'rgb(136, 144, 153)',
    textPlaceholder: 'rgb(136, 144, 153)',
    viewBG: 'rgba(152, 153, 156, 0.25)',
    white: 'rgb(255, 255, 255)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
  },
};
