import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import NavBarTeacherBlock from '../components/NavBarTeacherBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as typewriter from '../custom-files/typewriter';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  HStack,
  Icon,
  IconButton,
  Pressable,
  SVG,
  ScreenContainer,
  SimpleStyleFlatList,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const DashboardTeacherScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activePlayer, setActivePlayer] = React.useState(0);
  const [name, setName] = React.useState(Constants['name']);
  const [playerURL, setPlayerURL] = React.useState('');
  const [showDemo, setShowDemo] = React.useState(false);
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Dashboard (Teacher)',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
    >
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, padding: 20 },
          dimensions.width
        )}
      >
        {/* Header */}
        <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
          {/* Profile Picture */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-end', flex: 1 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('UserManagement', {
                    screen: 'EditProfileScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={{ uri: `${Constants['profilePicture']}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 100, height: 40, width: 40 }
                  ),
                  dimensions.width
                )}
              />
            </Pressable>
          </View>
          {/* Greeting */}
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Typewriter Component 2 */}
            <Utils.CustomCodeErrorBoundary>
              <typewriter.GreetingComponent userName={name} />
            </Utils.CustomCodeErrorBoundary>
          </View>
        </View>
        {/* Demo */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'stretch', flex: 0.75, justifyContent: 'flex-start' },
            dimensions.width
          )}
        >
          {/* Last Lesson */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontFamily: 'Roboto_500Medium',
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {'Demo Lesson'}
          </Text>
          {/* Latest Card */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                flexDirection: 'column',
                marginLeft: 5,
                marginRight: 5,
                padding: 15,
              },
              dimensions.width
            )}
          >
            {/* Top Section */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    alignItems: 'flex-start',
                    flex: 1,
                    justifyContent: 'space-between',
                  }
                ),
                dimensions.width
              )}
            >
              <View>
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontFamily: 'Roboto_500Medium' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Your Demo Lesson'}
                </Text>
              </View>
              {/* View 2 */}
              <View>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { fontSize: 9, textAlign: 'right' }
                    ),
                    dimensions.width
                  )}
                >
                  {'Today'}
                </Text>
              </View>
            </HStack>
            {/* Summary */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Roboto_300Light', fontSize: 12, marginTop: 5 }
                ),
                dimensions.width
              )}
            >
              {'This is a demo lesson to show you how everything works.'}
            </Text>
            {/* Buttons */}
            <HStack
              {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.HStackStyles(theme)['H Stack'].style,
                  {
                    alignItems: 'flex-start',
                    flex: 1,
                    justifyContent: 'flex-end',
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
            >
              {/* Icon */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'flex-end' },
                  dimensions.width
                )}
              >
                <IconButton
                  onPress={() => {
                    try {
                      navigation.navigate('TeacherStack', {
                        screen: 'DemoLessonScreen',
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  color={theme.colors['Strong']}
                  icon={'AntDesign/arrowright'}
                  size={28}
                />
              </View>
            </HStack>
          </View>
        </View>
        {/* Latest Lessons */}
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'stretch', flex: 1, justifyContent: 'flex-start' },
            dimensions.width
          )}
        >
          {/* Last Lesson */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontFamily: 'Roboto_500Medium',
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {'Latest Lesson'}
          </Text>

          <XanoAPIApi.FetchGetLessonsGET
            UUID={Constants['UUID']}
            numLessons={1}
          >
            {({ loading, error, data, refetchGetLessons }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Error */}
                  <>
                    {!(fetchData?.lessons?.length === 0) ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          dimensions.width
                        )}
                      >
                        {
                          "It looks like your students haven't had any lessons yet. Ask your students to upload or record a lesson to get started."
                        }
                      </Text>
                    )}
                  </>
                  <>
                    {!(fetchData?.lessons?.length > 0) ? null : (
                      <SimpleStyleFlatList
                        data={fetchData?.lessons}
                        inverted={false}
                        keyExtractor={(listData, index) => listData?.id}
                        keyboardShouldPersistTaps={'never'}
                        listKey={'OP3Gc3FI'}
                        nestedScrollEnabled={false}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item, index }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Latest Card */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignSelf: 'stretch',
                                    backgroundColor: theme.colors['White'],
                                    borderRadius: 10,
                                    flex: 1,
                                    flexDirection: 'column',
                                    marginLeft: 5,
                                    marginRight: 5,
                                    padding: 15,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Top Section */}
                                <HStack
                                  {...GlobalStyles.HStackStyles(theme)[
                                    'H Stack'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.HStackStyles(theme)[
                                        'H Stack'
                                      ].style,
                                      {
                                        alignItems: 'flex-start',
                                        flex: 1,
                                        justifyContent: 'space-between',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { fontFamily: 'Roboto_500Medium' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'Lesson in Progress...'}
                                    </Text>
                                  </View>
                                  {/* View 2 */}
                                  <View>
                                    {/* Text 2 */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { fontSize: 9, textAlign: 'right' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.lesson_date}
                                    </Text>
                                  </View>
                                </HStack>
                                {/* Summary */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        fontFamily: 'Roboto_300Light',
                                        fontSize: 12,
                                        marginTop: 5,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {
                                    'This is your current lesson. Click for more actions.'
                                  }
                                </Text>
                                {/* Buttons */}
                                <HStack
                                  {...GlobalStyles.HStackStyles(theme)[
                                    'H Stack'
                                  ].props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.HStackStyles(theme)[
                                        'H Stack'
                                      ].style,
                                      {
                                        alignItems: 'flex-start',
                                        flex: 1,
                                        justifyContent: 'flex-end',
                                        marginTop: 10,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {/* Icon */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { alignItems: 'flex-end' },
                                      dimensions.width
                                    )}
                                  >
                                    <IconButton
                                      onPress={() => {
                                        try {
                                          navigation.navigate('TeacherStack', {
                                            screen: 'StudentLessonsStack',
                                            params: {
                                              screen:
                                                'StudentLessonDetailsScreen',
                                              params: {
                                                lessonID: listData?.id,
                                              },
                                            },
                                          });
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      }}
                                      color={theme.colors['Strong']}
                                      icon={'AntDesign/arrowright'}
                                      size={28}
                                    />
                                  </View>
                                </HStack>
                              </View>
                            </>
                          );
                        }}
                        horizontal={false}
                        numColumns={2}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: -5,
                            marginRight: -5,
                          },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </>
              );
            }}
          </XanoAPIApi.FetchGetLessonsGET>
        </View>
        {/* Buttons */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-end',
              alignSelf: 'stretch',
              flex: 1,
              flexDirection: 'row',
              gap: 10,
              justifyContent: 'space-between',
              marginBottom: 30,
            },
            dimensions.width
          )}
        >
          {/* Student List */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                flex: 2,
                flexDirection: 'column',
                height: 150,
              },
              dimensions.width
            )}
          >
            {/* List */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked into Student List (teacher)',
                      })
                    )?.json;
                    navigation.navigate('TeacherStack', {
                      screen: 'StudentsScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                { height: '100%', width: '100%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, height: '100%', padding: 15 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { flex: 1, fontFamily: 'Roboto_500Medium', fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Student List'}
                </Text>

                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { paddingTop: 2.5 }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    'View all enrolled students, explore their lessons, leave notes, and more.'
                  }
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'flex-end' },
                    dimensions.width
                  )}
                >
                  <Icon name={'Entypo/list'} size={30} />
                </View>
              </View>
            </Pressable>
          </View>
          {/* Enrol Student */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                flex: 1,
                flexDirection: 'column',
                height: 150,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Enrol */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked to Enrol Student (teacher)',
                      })
                    )?.json;
                    navigation.navigate('TeacherStack', {
                      screen: 'EnrolStudentScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, height: '100%', padding: 15 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { flex: 1, fontFamily: 'Roboto_500Medium', fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'Enrol Student'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'flex-end' },
                    dimensions.width
                  )}
                >
                  <Icon name={'AntDesign/adduser'} size={30} />
                </View>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
      <NavBarTeacherBlock />
    </ScreenContainer>
  );
};

export default withTheme(DashboardTeacherScreen);
