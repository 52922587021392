import React from 'react';

const clearUser = async (Variables, setGlobalVariableValue) => {
  // Clear each global variable by setting their values to null
  setGlobalVariableValue({ key: 'uuid', value: null });
  setGlobalVariableValue({ key: 'authToken', value: null });
  setGlobalVariableValue({ key: 'email', value: null });
  setGlobalVariableValue({ key: 'name', value: null });
  setGlobalVariableValue({ key: 'accountType', value: null });
};

export default clearUser;
