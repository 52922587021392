import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { View } from 'react-native';

const GetHelpScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Get Help',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <HeaderBlock />
      <View
        style={StyleSheet.applyWidth(
          { height: 600, marginLeft: 10, marginRight: 10 },
          dimensions.width
        )}
      >
        <WebView
          allowFileAccessFromFileURLs={false}
          allowUniversalAccessFromFileURLs={false}
          incognito={false}
          javaScriptCanOpenWindowsAutomatically={false}
          javaScriptEnabled={true}
          mediaPlaybackRequiresUserAction={false}
          startInLoadingState={false}
          {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
          cacheEnabled={false}
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          source={{
            html: `<div style="width:100%;height:300px; border:none;" data-fillout-id="hotvKMbocVus" data-fillout-embed-type="standard" data-fillout-inherit-parameters data-fillout-dynamic-resize data-email=${Constants['email']} data-name=${Constants['name']} data-UUID=${Constants['UUID']}></div><script src="https://server.fillout.com/embed/v1/"></script>`,
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.WebViewStyles(theme)['HTML View'].style,
              {
                alignSelf: 'center',
                borderWidth: 0,
                marginLeft: 10,
                marginRight: 10,
              }
            ),
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(GetHelpScreen);
