import React, { useRef } from 'react';
import { Platform, View } from 'react-native';
import LottieView from 'lottie-react-native';

export const LottieAnimation = props => {
  const animationRef = useRef(null);

  // Construct the URLs based on the slug
  const lottieBaseUrl = 'https://lottie.host/';
  const lottieWebUrl = `${lottieBaseUrl}embed/${props.slug}.json`; // URL for the iframe
  const lottieNativeUrl = `${lottieBaseUrl}${props.slug}.json`; // URL for Lottie-React-Native

  if (Platform.OS === 'web') {
    // Use an iframe for web
    return (
      <View
        style={{
          width: props.width || 100,
          height: props.height || 100,
          backgroundColor: props.backgroundColor || 'transparent',
        }}
      >
        <iframe
          src={lottieWebUrl}
          frameBorder="0"
          style={{ width: '100%', height: '100%' }}
          allowFullScreen
        ></iframe>
      </View>
    );
  } else {
    // Use LottieView for native platforms
    return (
      <LottieView
        autoPlay={true}
        loop={true}
        ref={animationRef}
        style={{
          width: props.width || 100,
          height: props.height || 100,
          backgroundColor: props.backgroundColor || 'none',
        }}
        source={{ uri: lottieNativeUrl }}
      />
    );
  }
};
