import React from 'react';

const beautifyDate = lesson_date => {
  // Create a new date object from the lesson_date string
  const date = new Date(lesson_date);

  // Define an array of month names
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Get the day, month and year from the date object
  const day = String(date.getDate()).padStart(2, '0');
  const monthIndex = date.getMonth();
  const year = date.getFullYear().toString().substr(-2);

  // Format the date in the desired format "01 Jan '24"
  return `${day} ${monthNames[monthIndex]} '${year}`;

  const lessonDate = '2024-12-01';
  beautifyDate(lessonDate).then(formattedDate => {
    console.log(formattedDate); // Outputs: "01 Dec '24"
  });
};

export default beautifyDate;
