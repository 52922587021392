import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as LottieAnimation from '../custom-files/LottieAnimation';
import clearUser from '../global-functions/clearUser';
import deviceCheck from '../global-functions/deviceCheck';
import sleep from '../global-functions/sleep';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, withTheme } from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ImageBackground, View } from 'react-native';

const LoadingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const loadingCheckAuth = async (
    Variables,
    setGlobalVariableValue,
    myData
  ) => {
    if (myData && myData.code) {
      if (myData.code === 'ERROR_CODE_UNAUTHORIZED') {
        console.error('Unauthorized access error:', myData);
        return false;
      }
      // You can add additional error checks here if needed
    }
    return true; // No error codes found, response is OK to proceed
  };

  const loadingSetVars = async (Variables, setGlobalVariableValue, myData) => {
    // Check if the `myData` object is structured as expected
    if (!myData || !myData.id) {
      console.error("The 'myData' object is incomplete or undefined:", myData);
      return false;
    }

    try {
      await Promise.all([
        setGlobalVariableValue({ key: 'email', value: myData.email }),
        setGlobalVariableValue({
          key: 'accountType',
          value: myData.account_type,
        }),
        setGlobalVariableValue({
          key: 'profilePicture',
          value: myData.avatar.url,
        }),
        setGlobalVariableValue({ key: 'UUID', value: myData.UUID }),
      ]);

      return true;
    } catch (error) {
      // If there's an error, log it and return false
      console.error('Error setting variables:', error);
      return false;
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        setGlobalVariableValue({
          key: 'activePage',
          value: 'loading',
        });
        setGlobalVariableValue({
          key: 'device',
          value: deviceCheck(Variables, setGlobalVariableValue),
        });
        if (Constants['authToken']?.length > 3) {
          const meResponse = (await XanoAPIApi.meGET(Constants))?.json;
          /* hidden 'Log to Console' action */
          const checkAuth = await loadingCheckAuth(
            Variables,
            setGlobalVariableValue,
            meResponse
          );
          if (checkAuth) {
            await loadingSetVars(Variables, setGlobalVariableValue, meResponse);
            const sleep_response = await sleep(setGlobalVariableValue, 2000);
            if (Constants['onboarded'] === true) {
              if (Constants['accountType'] === 'Student') {
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('StudentStack', {
                  screen: 'DashboardStudentScreen',
                });
              } else {
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('TeacherStack', {
                  screen: 'DashboardTeacherScreen',
                });
              }
            } else {
              if (navigation.canGoBack()) {
                navigation.popToTop();
              }
              navigation.replace('UserManagement', {
                screen: 'OnboardingScreen',
              });
            }
          } else {
            await clearUser(Variables, setGlobalVariableValue);

            showAlertUtil({
              title: 'Session Expired',
              message:
                'Your session has expired. Please login again to continue.',
              buttonText: 'Okay',
            });

            if (navigation.canGoBack()) {
              navigation.popToTop();
            }
            navigation.replace('UserManagement', {
              screen: 'LoginAndSignupScreen',
            });
          }
        } else {
          await sleep(setGlobalVariableValue, 2000);
          if (navigation.canGoBack()) {
            navigation.popToTop();
          }
          navigation.replace('UserManagement', {
            screen: 'LoginAndSignupScreen',
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignSelf: 'center',
          backgroundColor: theme.colors['White'],
          justifyContent: 'center',
        },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        {...GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].props}
        source={Images.HMBG}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.ImageBackgroundStyles(theme)['Image Background'].style,
            {
              alignItems: 'center',
              justifyContent: 'center',
              width: dimensions.width,
            }
          ),
          dimensions.width
        )}
      >
        {/* Evita Animation */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignContent: 'center',
              alignSelf: 'center',
              flex: 1,
              height: 120,
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          {/* Animation Component */}
          <Utils.CustomCodeErrorBoundary>
            <LottieAnimation.LottieAnimation
              width={250}
              height={250}
              slug="217f6dfe-bd26-4200-9071-0a327006a151/I8aIfmtaTw"
            />
          </Utils.CustomCodeErrorBoundary>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(LoadingScreen);
