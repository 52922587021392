import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import beautifyDate from '../global-functions/beautifyDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const StudentsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [refreshing9daWBCFP, setRefreshing9daWBCFP] = React.useState(false);
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'students',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <HeaderBlock />
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        refreshColor={theme.colors['Primary']}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <XanoAPIApi.FetchStudentsGET UUID={Constants['UUID']}>
          {({ loading, error, data, refetchStudents }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <>
                  {!(fetchData?.length > 0) ? null : (
                    <SimpleStyleFlatList
                      data={fetchData}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) => listData}
                      keyboardShouldPersistTaps={'never'}
                      listKey={'9daWBCFP'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      refreshControl={
                        <RefreshControl
                          refreshing={refreshing9daWBCFP}
                          onRefresh={() => {
                            const handler = async () => {
                              try {
                                setRefreshing9daWBCFP(true);
                                await refetchStudents();
                                setRefreshing9daWBCFP(false);
                              } catch (err) {
                                console.error(err);
                                setRefreshing9daWBCFP(false);
                              }
                            };
                            handler();
                          }}
                          colors={[theme.colors['Primary']]}
                          tintColor={theme.colors['Primary']}
                        />
                      }
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Card */}
                            <View
                              collapsable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Custom #ffffff'],
                                  borderColor: theme.colors['Light'],
                                  borderRadius: 4,
                                  borderWidth: 0,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 10,
                                  minHeight: 70,
                                  paddingBottom: 10,
                                  paddingLeft: 20,
                                  paddingRight: 15,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Avatar */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { alignItems: 'flex-start' },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  {...GlobalStyles.ImageStyles(theme)['Image']
                                    .props}
                                  source={{ uri: `${listData?.avatar?.url}` }}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ImageStyles(theme)['Image']
                                        .style,
                                      {
                                        borderRadius: 10,
                                        height: 50,
                                        marginRight: 10,
                                        width: 50,
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                />
                              </View>
                              {/* Mid */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'flex-start',
                                    flex: 1,
                                    flexDirection: 'column',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        fontFamily: 'System',
                                        fontWeight: '600',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.name}
                                </Text>
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      { fontSize: 11 }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.email}
                                </Text>
                              </View>
                              {/* Button */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-end',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Open */}
                                <Button
                                  onPress={() => {
                                    try {
                                      navigation.navigate('TeacherStack', {
                                        screen: 'StudentLessonsStack',
                                        params: {
                                          screen: 'StudentLessonsScreen',
                                          params: { learnerID: listData?.id },
                                        },
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  {...GlobalStyles.ButtonStyles(theme)['Button']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.ButtonStyles(theme)['Button']
                                        .style,
                                      {
                                        backgroundColor: theme.colors['Strong'],
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                  title={'Open'}
                                />
                              </View>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        { paddingTop: 15 },
                        dimensions.width
                      )}
                    />
                  )}
                </>
                {/* Error */}
                <>
                  {!(fetchData?.length === 0) ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {
                        "Looks like you haven't enrolled any students yet. Please enrol a student to continue. "
                      }
                    </Text>
                  )}
                </>
              </>
            );
          }}
        </XanoAPIApi.FetchStudentsGET>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(StudentsScreen);
