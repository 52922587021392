import React from 'react';

// Control visibility of the back button in the header
const headerElementsBack = Variables => {
  const activePage = Variables.activePage;
  const pageNames = ['Dashboard (Student)', 'Dashboard (Teacher)', 'Uploader']; // Pages to exclude back button from

  // Function to check if activePage matches any page in the list
  function isActivePageValid() {
    // Check if the activePage is in the pageNames list
    if (pageNames.includes(activePage)) {
      return true;
    } else {
      return false;
    }
  }

  // Call the function to perform the validation and return the result
  return isActivePageValid();
};

export default headerElementsBack;
