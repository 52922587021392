import React from 'react';

const feedbackPromptCheck = (created_at, feedback_given) => {
  // Parse the created_at date
  const createdAtDate = new Date(Number(created_at));
  // Get today's date
  const today = new Date();
  // Calculate the difference in milliseconds
  const difference = today - createdAtDate;
  // Convert the difference to days
  const daysDifference = difference / (1000 * 60 * 60 * 24);
  // Check if the difference is 2 days or more AND feedback_given is false
  if (daysDifference >= 2 && feedback_given === false) {
    return true;
  } else {
    return false;
  }
};

export default feedbackPromptCheck;
