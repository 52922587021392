import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import NavBarStudentBlock from '../components/NavBarStudentBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as AV from '../custom-files/AV';
import * as FS from '../custom-files/FS';
import * as recordingTimer from '../custom-files/recordingTimer';
import * as typewriter_general from '../custom-files/typewriter_general';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useIsOnline from '../utils/useIsOnline';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Pressable,
  ScreenContainer,
  TextField,
  withTheme,
} from '@draftbit/ui';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import { Modal, Platform, Text, View } from 'react-native';

const RecordScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activateSubmission_Button, setActivateSubmission_Button] =
    React.useState(false);
  const [activeLessonInstance, setActiveLessonInstance] = React.useState('');
  const [dropdownToggle, setDropdownToggle] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [intervalId, setIntervalId] = React.useState(0);
  const [lessonTitle_Input, setLessonTitle_Input] = React.useState('');
  const [newest_lesson_id, setNewest_lesson_id] = React.useState(0);
  const [processingRecording, setProcessingRecording] = React.useState(false);
  const [recording, setRecording] = React.useState({});
  const [recording_uri, setRecording_uri] = React.useState('');
  const [stateStatus, setStateStatus] = React.useState('');
  const [teacherName_Input, setTeacherName_Input] = React.useState('');
  const [uploadProgress, setUploadProgress] = React.useState('');
  const startRecording = async () => {
    try {
      console.log('Requesting permissions..');
      await AV.Audio.requestPermissionsAsync();
      await AV.Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      console.log('Starting recording..');
      const { recording } = await AV.Audio.Recording.createAsync(
        AV.Audio.RecordingOptionsPresets.HIGH_QUALITY
      );
      setStateStatus('recording');
      setRecording(recording);
      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const pauseRecording = async () => {
    try {
      console.log('Pausing recording...');
      if (recording) {
        await recording.pauseAsync(); // This pauses the recording
        setStateStatus('recording paused'); // Update your UI state to reflect that recording is paused
        console.log('Recording paused');
      } else {
        console.log('No recording to pause');
      }
    } catch (err) {
      console.error('Failed to pause recording', err);
    }
  };

  const playRecording = async uri => {
    try {
      console.log(`Trying to load Sound from: ${uri}`);

      const sound = new AV.Audio.Sound();

      await sound.loadAsync({ uri });
      console.log('Sound is loaded');

      sound.setOnPlaybackStatusUpdate(status => {
        if (status.didJustFinish) {
          console.log('Playback finished');
          sound.unloadAsync();
        }
      });

      await sound.playAsync();
      console.log('Playback started');
    } catch (error) {
      console.error('Error during sound playback:', error);
    }
  };

  const moveRecording = async lesson_uri => {
    try {
      // Define the directory name where you want to store the recording
      const directoryName = 'EvitaRecordings';
      const newDirectory = `${FS.documentDirectory}${directoryName}`;

      // Ensure the new directory exists
      await FS.makeDirectoryAsync(newDirectory, { intermediates: true });

      // Define the new URI for the file
      const filename = lesson_uri.split('/').pop(); // Extract the file name from the lesson_uri
      const newUri = `${newDirectory}/${filename}`;

      // Move the file to the new directory
      await FS.moveAsync({
        from: lesson_uri,
        to: newUri,
      });

      console.log('File moved to new location:', newUri);
      return newUri; // Return the new URI for further use
    } catch (error) {
      console.error('Failed to move file:', error);
      return ''; // Return an empty string or handle the error as needed
    }
  };

  const stopRecording = async () => {
    console.log('Stopping recording...');

    let uri = '';
    if (recording) {
      try {
        await recording.stopAndUnloadAsync(); // Stop the recording
        uri = recording.getURI(); // Get the recording URI
        console.log('Recording stopped and stored at', uri);

        // Since Audio is imported as AV, use AV.Audio.Sound.createAsync
        const creationResult = await AV.Audio.Sound.createAsync(
          { uri: uri },
          { shouldPlay: false }
        );
        const sound = creationResult.sound;

        // Use a timeout to allow the metadata to load
        setTimeout(async () => {
          const status = await sound.getStatusAsync();
          if (status.isLoaded) {
            console.log('Sound is loaded');
          } else {
            console.log('Failed to load sound');
          }

          // Unload the sound to clean up
          await sound.unloadAsync();
        }, 1000); // Adjust the delay time as needed
      } catch (error) {
        console.error('Failed to stop recording', error);
      }
    }

    return uri; // Return the URI for the player to use
  };

  const resumeRecording = async setGlobalVariableValue => {
    try {
      console.log('Resuming recording...');
      const status = await recording.getStatusAsync();
      if (status.isRecording) {
        console.log('Recording is already in progress');
      } else if (status.isDoneRecording) {
        console.log('Recording has finished and cannot be resumed');
      } else {
        await recording.startAsync(); // This should resume the recording
        setStateStatus('recording'); // Update your UI state to reflect that recording has resumed
        console.log('Recording resumed');
      }
    } catch (err) {
      console.error('Failed to resume recording', err);
    }
  };

  const formValidation = () => {
    if (
      lessonTitle_Input &&
      lessonTitle_Input.length > 0 &&
      teacherName_Input &&
      teacherName_Input.length > 0
    ) {
      console.log('Inputs are valid!');
      setErrorMessage('');
      return true;
    } else {
      setErrorMessage('Please complete the fields');
      return false;
    }
  };

  const uploadLessonDepreciated = async (
    Variables,
    lesson_title,
    teacher_name,
    lesson_date,
    lesson_uri
  ) => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('lesson_title', lesson_title);
    formData.append('teacher_name', teacher_name);
    formData.append('lesson_date', lesson_date);
    formData.append('local_uri_path', lesson_uri);

    formData.append('lesson_recording', {
      uri: lesson_uri,
      type: 'audio/mpeg',
      name: lesson_title + ' - ' + lesson_date + '.m4a',
    });

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `${authToken}`,
      },
      body: formData,
    };

    try {
      // Create an XMLHttpRequest object
      const xhr = new XMLHttpRequest();

      // Define what happens on successful data submission
      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          console.log('Response:', xhr.response);
          console.log('Response Headers:', xhr.getAllResponseHeaders());

          if (xhr.status === 200) {
            const result = JSON.parse(xhr.response);
            console.log('Upload successful:', result);
            setStateStatus('uploaded');

            const lesson_id = result.id;
            console.log('Lesson ID:', lesson_id);
            return lesson_id;
          } else {
            console.error('HTTP error status:', xhr.status);
          }
        }
      };

      // Define what happens in case of an error
      xhr.onerror = function () {
        console.error('Error during file upload:', xhr.statusText);
      };

      // Set up our request
      xhr.open(
        'POST',
        'https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/upload',
        true
      );

      // Set request headers
      xhr.setRequestHeader('Authorization', `${authToken}`);
      // Note: Do not set Content-Type for FormData with XMLHttpRequest
      // as the browser will set it with the correct boundary

      // Update progress variable
      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100) + '%';
          console.log('Upload progress:', progress);
          setUploadProgress(progress);
          setStateStatus('uploading');
        }
      };

      // Send our FormData object
      xhr.send(formData);
    } catch (error) {
      console.error('Error during file upload:', error.message);
    }
  };

  const handleRecording = async Variables => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;
    const deviceCheck = Platform.OS;
    const userName = Variables.name;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('conversationId', conversationId);
    formData.append('device', deviceCheck);
    formData.append('userName', userName);

    // Use the recording object to dynamically set the MIME type and extension
    let fileType = 'audio/m4a'; // Default MIME type
    let fileExtension = 'm4a'; // Default extension
    let fileUri = recording.getURI(); // Method to get URI from the recording object

    if (Platform.OS === 'android') {
      fileType = `audio/${recording._options.android.extension.replace(
        '.',
        ''
      )}`;
      fileExtension = recording._options.android.extension.replace('.', '');
    } else if (Platform.OS === 'ios') {
      fileType = `audio/${recording._options.ios.extension
        .trim()
        .replace('.', '')}`;
      fileExtension = recording._options.ios.extension.trim().replace('.', '');
    } else if (Platform.OS === 'web') {
      fileType = recording._options.web.mimeType;
      fileExtension = fileType.split('/')[1];
    }

    // When creating the name, only add one period between the UUID and file extension
    const audioFile = {
      uri: fileUri,
      type: fileType,
      name: `recording-${UUID}.${fileExtension}`, // Ensure only one period here
    };

    if (Platform.OS === 'web') {
      try {
        const response = await fetch(fileUri);
        const blob = await response.blob();
        formData.append(
          'message_audio',
          blob,
          `recording-${UUID}.${fileExtension}`
        );

        // Convert blob to base64
        const base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Resolve only the base64 string without the Data URI scheme prefix
            const result = reader.result;
            const base64String = result.split(',')[1]; // This removes the Data URI prefix
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        // Append base64 data to formData
        formData.append('message_audio_raw', base64data);
      } catch (error) {
        console.error('Error fetching the blob:', error);
        return; // Exit the function if an error occurs
      }
    } else {
      formData.append('message_audio', audioFile);
    }

    // Xano API endpoint
    const apiEndpoint =
      'https://api.heymaestro.me/api:EJWp6KFE/conversations/message';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse the JSON response
        const url = responseData.url; // Extract the URL
        const assistantMessage = responseData.message; // Extract the message
        const user_message_record = responseData.user_message_record;
        setAssistantMessage(assistantMessage);
        console.log(
          'Upload Success:',
          url,
          assistantMessage,
          user_message_record
        );

        // Set up the audio playback environment
        await AV.Audio.setAudioModeAsync({
          staysActiveInBackground: true,
          allowsRecordingIOS: false,
          playsInSilentModeIOS: true,
          shouldDuckAndroid: true,
          playThroughEarpieceAndroid: false,
        });

        // Create a new sound object for playback
        const sound = new AV.Audio.Sound();

        try {
          // Load the sound from the URL
          await sound.loadAsync({ uri: url });
          // Play the loaded sound
          await sound.playAsync();
        } catch (playbackError) {
          console.error('Playback error:', playbackError);
        }
      } else {
        console.error('Upload Failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };

  const uploadLesson = async (
    Variables,
    setGlobalVariableValue,
    lesson_title,
    teacher_name,
    lesson_uri,
    activeLessonInstance
  ) => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;
    const deviceCheck = Platform.OS;

    let fileType = 'audio/m4a'; // Default MIME type
    let fileExtension = 'm4a'; // Default extension
    let fileUri = lesson_uri; // Use lesson_uri for the audio file

    if (Platform.OS === 'android') {
      fileType = `audio/${recording._options.android.extension.replace(
        '.',
        ''
      )}`;
      fileExtension = recording._options.android.extension.replace('.', '');
    } else if (Platform.OS === 'ios') {
      fileType = `audio/${recording._options.ios.extension
        .trim()
        .replace('.', '')}`;
      fileExtension = recording._options.ios.extension.trim().replace('.', '');
    } else if (Platform.OS === 'web') {
      fileType = recording._options.web.mimeType;
      fileExtension = fileType.split('/')[1];
    }

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('lesson_title', lesson_title);
    formData.append('teacher_name', teacher_name);
    formData.append('activeLessonInstance', activeLessonInstance);
    formData.append('device', deviceCheck);

    console.log(
      UUID,
      lesson_title,
      teacher_name,
      activeLessonInstance,
      deviceCheck,
      fileUri
    );

    if (Platform.OS === 'web') {
      try {
        const response = await fetch(fileUri);
        const blob = await response.blob();
        formData.append(
          'lesson_recording',
          blob,
          `recording-${UUID}.${fileExtension}`
        );
      } catch (error) {
        console.error('Error fetching the blob:', error);
        return; // Exit if unable to fetch blob
      }
    } else {
      const audioFile = {
        uri: fileUri,
        type: fileType,
        name: `recording-${UUID}.${fileExtension}`,
      };
      formData.append('lesson_recording', audioFile);
    }

    sendFormData(formData, authToken);

    function sendFormData(formData, authToken) {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          console.log('Response:', xhr.response);
          console.log('Response Headers:', xhr.getAllResponseHeaders());

          if (xhr.status === 200) {
            const result = JSON.parse(xhr.response);
            console.log('Upload successful:', result);
          } else {
            console.error('HTTP error status:', xhr.status);
          }
        }
      };

      xhr.onerror = function () {
        console.error('Error during file upload:', xhr.statusText);
      };

      xhr.open(
        'POST',
        'https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/record',
        true
      );
      xhr.setRequestHeader('Authorization', authToken);

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100) + '%';
          console.log('Upload progress:', progress);
          setUploadProgress(progress);
          setStateStatus('uploading');
        }
      };

      xhr.send(formData);
    }
  };
  const isOnline = useIsOnline();
  const xanoAPICreateLessonInstancePOST =
    XanoAPIApi.useCreateLessonInstancePOST();
  const xanoAPIDestroyLessonInstanceDELETE =
    XanoAPIApi.useDestroyLessonInstanceDELETE();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Record',
      });
      setActiveLessonInstance('');
      setActivateSubmission_Button(false);
      setDropdownToggle(false);
      setStateStatus('ready');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['HM2'],
          height: '100%',
          justifyContent: 'flex-start',
          maxHeight: dimensions.height,
          paddingBottom: 60,
          width: '100%',
        },
        dimensions.width
      )}
    >
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        {/* Info Area */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, padding: 30 },
            dimensions.width
          )}
        >
          {/* Typewriter */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                marginBottom: 20,
                marginTop: 80,
                maxHeight: 200,
                minHeight: 50,
                paddingLeft: 20,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            {/* Ready text */}
            <>
              {!(stateStatus === 'ready') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={"I'm ready to record your lesson."}
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Recording text */}
            <>
              {!(stateStatus === 'recording') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={"I'm recording your lesson. Have fun!"}
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Recording paused */}
            <>
              {!(stateStatus === 'recording paused') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={
                      "I have paused recording. Press 'resume' when you're ready to continue."
                    }
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Recording stopped */}
            <>
              {!(stateStatus === 'recording complete') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={
                      'Amazing lesson! Please choose a name for your recording and click the save button so I can analyse your lesson.'
                    }
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Uploading */}
            <>
              {!(stateStatus === 'uploading') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={"I'm uploading your lesson now."}
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Uploaded */}
            <>
              {!(stateStatus === 'uploaded') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={
                      "All set. I've uploaded your lesson. I'll begin analysing it now."
                    }
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
          </View>
          <>
            {!(stateStatus === 'uploading') ? null : (
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { textAlign: 'center' }
                  ),
                  dimensions.width
                )}
              >
                {'Upload Progress: '}
                {uploadProgress}
              </Text>
            )}
          </>
        </View>
        {/* Timer */}
        <View style={StyleSheet.applyWidth({ flex: 3 }, dimensions.width)}>
          {/* Timer */}
          <Utils.CustomCodeErrorBoundary>
            <recordingTimer.TimerComponent stateStatus={stateStatus} />
          </Utils.CustomCodeErrorBoundary>
        </View>
        {/* Recording Buttons */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 2, justifyContent: 'flex-end', marginBottom: 50 },
            dimensions.width
          )}
        >
          {/* Buttons */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'flex-end',
                alignSelf: 'center',
                flex: 1,
                flexDirection: 'row',
                gap: 5,
              },
              dimensions.width
            )}
          >
            {/* Start Recording */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    setActivateSubmission_Button(false);
                    await startRecording();
                    setStateStatus('recording');
                    const lessonInstanceReponse = (
                      await xanoAPICreateLessonInstancePOST.mutateAsync({
                        UUID: Constants['UUID'],
                      })
                    )?.json;
                    setActiveLessonInstance(lessonInstanceReponse);
                    console.log(lessonInstanceReponse);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              disabled={stateStatus !== 'ready'}
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['White'],
                    borderRadius: 4,
                    height: 150,
                    justifyContent: 'center',
                    width: 100,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors['Error']}
                  name={'Entypo/controller-record'}
                />
              </View>
            </Pressable>
            {/* Pause Recording */}
            <>
              {!(stateStatus !== 'recording paused') ? null : (
                <Pressable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await pauseRecording();
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  disabled={stateStatus !== 'recording'}
                  style={StyleSheet.applyWidth(
                    { height: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['White'],
                        borderRadius: 4,
                        height: 150,
                        justifyContent: 'center',
                        width: 100,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      color={theme.colors['Custom Color_26']}
                      name={'FontAwesome/pause'}
                      size={19}
                    />
                  </View>
                </Pressable>
              )}
            </>
            {/* Resume Recording */}
            <>
              {!(stateStatus === 'recording paused') ? null : (
                <Pressable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        await resumeRecording(setGlobalVariableValue);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    { height: '100%' },
                    dimensions.width
                  )}
                >
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['White'],
                        borderRadius: 4,
                        height: 150,
                        justifyContent: 'center',
                        width: 100,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          { fontFamily: 'Roboto_900Black' }
                        ),
                        dimensions.width
                      )}
                    >
                      {'RESUME'}
                    </Text>
                  </View>
                </Pressable>
              )}
            </>
            {/* Stop Recording */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    const recordingResult = await stopRecording();
                    setStateStatus('recording complete');
                    const uriExtracted = recordingResult;
                    setRecording_uri(uriExtracted);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              disabled={stateStatus !== 'recording'}
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['White'],
                    borderRadius: 4,
                    height: 150,
                    justifyContent: 'center',
                    width: 100,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  size={24}
                  color={theme.colors['Custom Color_26']}
                  name={'Ionicons/stop'}
                />
              </View>
            </Pressable>
          </View>

          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: theme.colors['Medium'],
                fontSize: 10,
                paddingLeft: 20,
                paddingRight: 20,
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {
              '\nPlease note: this feature is experimental. We recommend having your teacher record the lesson externally as well, just incase.'
            }
          </Text>
        </View>
      </View>
      {/* Save Lesson */}
      <Modal
        animationType={'slide'}
        presentationStyle={'overFullScreen'}
        transparent={true}
        visible={stateStatus === 'recording complete'}
      >
        {/* View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['White'],
              borderTopLeftRadius: 30,
              borderTopRightRadius: 30,
              bottom: 0,
              flexDirection: 'column',
              justifyContent: 'flex-end',
              paddingBottom: 30,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 30,
              position: 'absolute',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Heading */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                alignSelf: 'auto',
                color: theme.colors['Medium'],
                fontFamily: 'Roboto_500Medium',
                fontSize: 18,
                letterSpacing: -1,
                marginBottom: 10,
                textAlign: 'center',
                textTransform: 'none',
              }),
              dimensions.width
            )}
          >
            {'Save Your Lesson'}
          </Text>
          {/* Form */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignItems: 'stretch',
                alignSelf: 'auto',
                borderRadius: 10,
                marginLeft: 5,
                marginRight: 5,
                padding: 20,
                position: 'relative',
              },
              dimensions.width
            )}
          >
            {/* Lesson Title Field */}
            <TextField
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newLessonTitleFieldValue => {
                try {
                  setLessonTitle_Input(newLessonTitleFieldValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              underlineColor={theme.colors.light}
              webShowOutline={true}
              activeBorderColor={theme.colors['Strong']}
              label={'Name Your Recording:'}
              placeholder={'My Lesson Recording'}
              style={StyleSheet.applyWidth(
                { marginBottom: 20 },
                dimensions.width
              )}
              type={'underline'}
              value={lessonTitle_Input}
            />
            {/* Lesson Teacher Field */}
            <TextField
              autoCapitalize={'none'}
              autoCorrect={true}
              changeTextDelay={500}
              onChangeText={newLessonTeacherFieldValue => {
                try {
                  setTeacherName_Input(newLessonTeacherFieldValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              underlineColor={theme.colors.light}
              webShowOutline={true}
              activeBorderColor={theme.colors['Strong']}
              label={'Teacher Name:'}
              placeholder={'Awesome Teacher'}
              style={StyleSheet.applyWidth(
                { marginBottom: 10 },
                dimensions.width
              )}
              type={'underline'}
              value={teacherName_Input}
            />
            {/* Error */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { color: theme.colors['Error'], fontSize: 10 }
                ),
                dimensions.width
              )}
            >
              {errorMessage}
            </Text>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'center',
                alignSelf: 'stretch',
                justifyContent: 'flex-end',
                marginTop: 10,
                position: 'relative',
              },
              dimensions.width
            )}
          >
            {/* Save Recording */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const formValidation_Response = formValidation();
                    if (!formValidation_Response) {
                      return;
                    }
                    setProcessingRecording(true);
                    setStateStatus('uploading');
                    const lessonUpload_Response = await uploadLesson(
                      Variables,
                      setGlobalVariableValue,
                      lessonTitle_Input,
                      teacherName_Input,
                      recording_uri,
                      activeLessonInstance
                    );
                    setStateStatus('uploaded');
                    setGlobalVariableValue({
                      key: 'newestLessonID',
                      value: lessonUpload_Response,
                    });
                    setProcessingRecording(false);
                    setActivateSubmission_Button(false);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors['Strong'],
                    marginLeft: 10,
                    marginRight: 10,
                  }
                ),
                dimensions.width
              )}
              title={'Save Recording'}
            />
            {/* Discard Recording */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    setProcessingRecording(false);
                    setStateStatus('ready');
                    (
                      await xanoAPIDestroyLessonInstanceDELETE.mutateAsync({
                        UUID: Constants['UUID'],
                        activeLessonInstance: activeLessonInstance,
                      })
                    )?.json;
                    setActiveLessonInstance('');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors['Strong'],
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }
                ),
                dimensions.width
              )}
              title={'Discard Recording'}
            />
          </View>
        </View>
      </Modal>
      <NavBarStudentBlock />
    </ScreenContainer>
  );
};

export default withTheme(RecordScreen);
