import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  Link,
  Picker,
  ScreenContainer,
  TabView,
  TabViewItem,
  TextInput,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const LoginAndSignupScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [account_typeValue, setAccount_typeValue] = React.useState('');
  const [emailValue, setEmailValue] = React.useState('');
  const [emailValueSignup, setEmailValueSignup] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [errorMessageSignup, setErrorMessageSignup] = React.useState('');
  const [inviteCode, setInviteCode] = React.useState('');
  const [nameValue, setNameValue] = React.useState('');
  const [passwordReset, setPasswordReset] = React.useState(false);
  const [passwordValue, setPasswordValue] = React.useState('');
  const [passwordValueSignup, setPasswordValueSignup] = React.useState('');
  const [pickerValue, setPickerValue] = React.useState('');
  const formValidation_Login = () => {
    var emailPattern = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;

    if (emailValue.trim() === '' || passwordValue.trim() === '') {
      setErrorMessage('Both email and password are required');
      return false;
    }

    if (!emailValue.match(emailPattern)) {
      setErrorMessage('Invalid email address!');
      return false;
    }

    console.log('Inputs are valid!'); // All checks passed
    setErrorMessage(''); // Clear any previous error message
    return true;
  };

  const formValidation_Signup = () => {
    var emailPattern = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/;
    var passwordPattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\W).+$/; // Pattern for alphanumeric + special char
    var inviteCodes = ['z8j8bE', 'JAMIEREAD', 'DYLANJACOBS', 'DUGUID']; // Array of valid invite codes

    // Check if name or account type is empty
    if (nameValue.trim() === '' || account_typeValue.trim() === '') {
      setErrorMessageSignup('Name and account type are required');
      return false;
    }

    // Check if email or password is empty
    if (emailValueSignup.trim() === '' || passwordValueSignup.trim() === '') {
      setErrorMessageSignup('Both email and password are required');
      return false;
    }

    // Validate email pattern
    if (!emailValueSignup.match(emailPattern)) {
      setErrorMessageSignup('Invalid email address!');
      return false;
    }

    // Validate password pattern
    if (!passwordValueSignup.match(passwordPattern)) {
      setErrorMessageSignup(
        'Password must contain alphanumeric characters and at least one special character.'
      );
      return false;
    }

    // Check if inviteCode is valid
    if (!inviteCodes.includes(inviteCode)) {
      setErrorMessageSignup('Invalid invite code!');
      return false;
    }

    console.log('Inputs are valid!'); // All checks passed
    setErrorMessageSignup(''); // Clear any previous error message
    return true;
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Login & Signup',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['White'], marginTop: 25 },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          { height: '100%', justifyContent: 'flex-start' },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignSelf: 'center' },
            dimensions.width
          )}
        >
          {/* Logo */}
          <Image
            resizeMode={'contain'}
            source={Images.HMBeta}
            style={StyleSheet.applyWidth(
              { height: 120, marginBottom: 20, marginTop: 20, width: 250 },
              dimensions.width
            )}
          />
        </View>
        <>
          {passwordReset ? null : (
            <TabView
              initialTabIndex={0}
              keyboardDismissMode={'auto'}
              pressColor={theme.colors.primary}
              scrollEnabled={false}
              tabBarPosition={'top'}
              activeColor={theme.colors['Strong']}
              indicatorColor={theme.colors['Strong']}
              style={StyleSheet.applyWidth(
                { backgroundColor: theme.colors['White'], paddingTop: 10 },
                dimensions.width
              )}
              swipeEnabled={false}
              tabsBackgroundColor={theme.colors['White']}
            >
              {/* Login */}
              <TabViewItem
                {...GlobalStyles.TabViewItemStyles(theme)['Tab View Item']
                  .props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TabViewItemStyles(theme)['Tab View Item']
                      .style,
                    {
                      backgroundColor: theme.colors['White'],
                      justifyContent: 'flex-start',
                      paddingBottom: 5,
                      paddingTop: 10,
                    }
                  ),
                  dimensions.width
                )}
                title={'Login'}
              >
                <KeyboardAwareScrollView
                  enableAutomaticScroll={false}
                  enableResetScrollToCoords={false}
                  keyboardShouldPersistTaps={'never'}
                  showsVerticalScrollIndicator={true}
                  enableOnAndroid={true}
                  viewIsInsideTabBar={true}
                >
                  {/* Title */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        marginTop: 20,
                        top: 0,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Login to Your Account */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          alignSelf: 'auto',
                          color: theme.colors.strong,
                          fontFamily: 'Roboto_700Bold',
                          fontSize: 28,
                        },
                        dimensions.width
                      )}
                    >
                      {'Login to Your Account'}
                    </Text>
                  </View>
                  {/* Main Wrapper */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'stretch',
                        flexDirection: 'column',
                        height: '100%',
                        justifyContent: 'space-evenly',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Inputs Wrapper */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          alignSelf: 'auto',
                          flex: 1,
                          justifyContent: 'center',
                          paddingLeft: 24,
                          paddingRight: 24,
                          paddingTop: 0,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Email */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['BG Gray'],
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            borderLeftWidth: 1,
                            borderRadius: 16,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            height: 60,
                            justifyContent: 'space-between',
                            marginBottom: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Custom Color_20']}
                          name={'MaterialCommunityIcons/email'}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, paddingLeft: 10, paddingRight: 10 },
                            dimensions.width
                          )}
                        >
                          <TextInput
                            autoCapitalize={'none'}
                            autoCorrect={true}
                            changeTextDelay={500}
                            onChangeText={newTextInputValue => {
                              try {
                                setEmailValue(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            editable={true}
                            placeholder={'Email'}
                            placeholderTextColor={
                              theme.colors['Custom Color_20']
                            }
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={emailValue?.toLowerCase()}
                          />
                        </View>
                      </View>
                      {/* Password */}
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            backgroundColor: theme.colors['BG Gray'],
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Divider'],
                            borderLeftWidth: 1,
                            borderRadius: 16,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            flexDirection: 'row',
                            height: 60,
                            justifyContent: 'space-between',
                            marginTop: 10,
                            paddingLeft: 20,
                            paddingRight: 20,
                            width: '100%',
                          },
                          dimensions.width
                        )}
                      >
                        <Icon
                          size={24}
                          color={theme.colors['Custom Color_20']}
                          name={'FontAwesome/lock'}
                        />
                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1, paddingLeft: 10, paddingRight: 10 },
                            dimensions.width
                          )}
                        >
                          <TextInput
                            autoCapitalize={'none'}
                            autoCorrect={true}
                            changeTextDelay={500}
                            onChangeText={newTextInputValue => {
                              try {
                                setPasswordValue(newTextInputValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            webShowOutline={true}
                            editable={true}
                            placeholder={'Password'}
                            placeholderTextColor={
                              theme.colors['Custom Color_20']
                            }
                            secureTextEntry={true}
                            style={StyleSheet.applyWidth(
                              {
                                borderRadius: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                paddingRight: 8,
                                paddingTop: 8,
                              },
                              dimensions.width
                            )}
                            value={passwordValue}
                          />
                        </View>
                      </View>
                    </View>
                    {/* Button Wrapper */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'stretch',
                          alignSelf: 'center',
                          justifyContent: 'flex-end',
                          marginBottom: 20,
                          marginTop: 20,
                          width: '80%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Login */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const formCheck_Response = formValidation_Login();
                              if (!formCheck_Response) {
                                return;
                              }
                              const loginResponse = (
                                await XanoAPIApi.loginPOST(Constants, {
                                  email: emailValue,
                                  password: passwordValue,
                                })
                              )?.json;
                              const errorMessage_API = loginResponse?.message;
                              console.log(errorMessage_API);
                              if (!errorMessage_API) {
                                const authToken = loginResponse?.user.authToken;
                                setGlobalVariableValue({
                                  key: 'authToken',
                                  value: 'Bearer ' + authToken,
                                });
                                const UUID = loginResponse?.user.UUID;
                                setGlobalVariableValue({
                                  key: 'UUID',
                                  value: UUID,
                                });
                                const username = loginResponse?.user.name;
                                const name = setGlobalVariableValue({
                                  key: 'name',
                                  value: username,
                                });
                                const email = loginResponse?.user.email;
                                setGlobalVariableValue({
                                  key: 'email',
                                  value: email,
                                });
                                const accountType =
                                  loginResponse?.user.account_type;
                                setGlobalVariableValue({
                                  key: 'accountType',
                                  value: accountType,
                                });
                                const onboarded = loginResponse?.user.Onboarded;
                                setGlobalVariableValue({
                                  key: 'onboarded',
                                  value: onboarded,
                                });
                                if (navigation.canGoBack()) {
                                  navigation.popToTop();
                                }
                                navigation.replace('LoadingScreen');
                              } else {
                                showAlertUtil({
                                  title: 'Error',
                                  message: errorMessage_API,
                                  buttonText: 'Okay',
                                });
                              }
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['Strong'],
                            borderRadius: 4,
                            fontFamily: 'Inter_600SemiBold',
                            fontSize: 15,
                            height: 58,
                            textAlign: 'center',
                            width: '100%',
                          },
                          dimensions.width
                        )}
                        title={'Login'}
                      />
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            setPasswordReset(true);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['Link'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            { alignSelf: 'center', paddingTop: 10 }
                          ),
                          dimensions.width
                        )}
                        title={'Forgot password?'}
                      />
                    </View>
                    {/* Error */}
                    <>
                      {!errorMessage ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          disabled={false}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: 'rgb(255, 1, 1)', textAlign: 'center' }
                            ),
                            dimensions.width
                          )}
                        >
                          {errorMessage}
                        </Text>
                      )}
                    </>
                  </View>
                </KeyboardAwareScrollView>
              </TabViewItem>
            </TabView>
          )}
        </>
        <>
          {!passwordReset ? null : (
            <WebView
              allowFileAccessFromFileURLs={false}
              allowUniversalAccessFromFileURLs={false}
              cacheEnabled={true}
              incognito={false}
              javaScriptCanOpenWindowsAutomatically={false}
              javaScriptEnabled={true}
              mediaPlaybackRequiresUserAction={false}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
              startInLoadingState={false}
              {...GlobalStyles.WebViewStyles(theme)['HTML View'].props}
              source={{
                html: '<div style="width:100%;height:500px;" data-fillout-id="e41EnbMzxRus" data-fillout-embed-type="standard" data-fillout-inherit-parameters data-fillout-dynamic-resize data-fillout-domain="forms.heymaestro.me"></div><script src="https://server.fillout.com/embed/v1/"></script>',
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.WebViewStyles(theme)['HTML View'].style,
                  { borderWidth: 0 }
                ),
                dimensions.width
              )}
            />
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LoginAndSignupScreen);
