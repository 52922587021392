import React, { useState, useEffect } from 'react';
import { Text, View, StyleSheet } from 'react-native';

const TypewriterText = ({ text, onTypingEnd, textStyle }) => {
  const [typedText, setTypedText] = useState('');
  const isTyping = typedText.length < text.length;

  useEffect(() => {
    if (isTyping) {
      const timeout = setTimeout(() => {
        setTypedText(text.substring(0, typedText.length + 1));
      }, 100); // This controls the speed of the typing
      return () => clearTimeout(timeout);
    } else {
      onTypingEnd && onTypingEnd();
    }
  }, [typedText, text, onTypingEnd]);

  return (
    <Text style={textStyle}>
      {typedText}
      {isTyping ? '|' : ''}
    </Text>
  );
};

export const GreetingComponent = ({ userName }) => {
  const firstName = userName.split(' ')[0];
  const greeting = `Good ${getTimeOfDayGreeting()}, ${firstName}`;

  return (
    <View style={styles.container}>
      <View style={styles.textContainer}>
        <TypewriterText text={greeting} textStyle={styles.greetingStyle} />
      </View>
    </View>
  );
};

const getTimeOfDayGreeting = () => {
  const hours = new Date().getHours();
  if (hours < 12) return 'Morning';
  else if (hours < 18) return 'Afternoon';
  else return 'Evening';
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'left',
    alignItems: 'left',
  },
  textContainer: {
    justifyContent: 'left',
    alignItems: 'left',
    minHeight: 10,
  },
  greetingStyle: {
    fontSize: 24, // Adjust size as needed
    fontWeight: 'bold', // Adjust weight as needed
    fontFamily: 'Roboto_400Regular',
    textAlign: 'left',
  },
});

// Usage in Draftbit
// <typewriter.GreetingComponent userName={name}/>
