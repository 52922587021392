import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import NavBarStudentBlock from '../components/NavBarStudentBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as typewriter from '../custom-files/typewriter';
import beautifyDate from '../global-functions/beautifyDate';
import sleep from '../global-functions/sleep';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  HStack,
  Icon,
  IconButton,
  Pressable,
  SVG,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const DashboardStudentScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [actionClick, setActionClick] = React.useState(0);
  const [activePlayer, setActivePlayer] = React.useState(0);
  const [name, setName] = React.useState(Constants['name']);
  const [playerURL, setPlayerURL] = React.useState('');
  const audioPlayerUrl = async url => {
    setPlayerURL(url);
    return;
  };
  const xanoAPICreateConversationPOST = XanoAPIApi.useCreateConversationPOST();
  const xanoAPIActionStatusPUT = XanoAPIApi.useActionStatusPUT();
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Dashboard (Student)',
      });
      setActivePlayer(0);
      setActionClick(0);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const audioPlayerP4PfAexCRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['HM2'], maxHeight: dimensions.height },
        dimensions.width
      )}
    >
      <NavBarStudentBlock />
      {/* Main Area */}
      <View
        style={StyleSheet.applyWidth(
          {
            flex: 1,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* Header */}
        <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
          {/* Profile Picture */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-end', flex: 1 },
              dimensions.width
            )}
          >
            <Pressable
              onPress={() => {
                try {
                  navigation.navigate('UserManagement', {
                    screen: 'EditProfileScreen',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                {...GlobalStyles.ImageStyles(theme)['Image'].props}
                source={{ uri: `${Constants['profilePicture']}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ImageStyles(theme)['Image'].style,
                    { borderRadius: 100, height: 40, width: 40 }
                  ),
                  dimensions.width
                )}
              />
            </Pressable>
          </View>
          {/* Greeting */}
          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Typewriter Component 2 */}
            <Utils.CustomCodeErrorBoundary>
              <typewriter.GreetingComponent userName={name} />
            </Utils.CustomCodeErrorBoundary>
          </View>
        </View>
        {/* Fetch Cards */}
        <View style={StyleSheet.applyWidth({ flex: 2 }, dimensions.width)}>
          {/* Latest Lessons */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                alignSelf: 'stretch',
                flex: 1,
                justifyContent: 'flex-start',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Roboto_500Medium', marginBottom: 10 }
                ),
                dimensions.width
              )}
            >
              {'Latest Lesson'}
            </Text>

            <XanoAPIApi.FetchGetLessonsGET
              UUID={Constants['UUID']}
              numLessons={2}
            >
              {({ loading, error, data, refetchGetLessons }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Error */}
                    <>
                      {!(fetchData?.lessons?.length === 0) ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {
                            "It looks like you haven't had any lessons yet. Please record or upload a lesson to get started."
                          }
                        </Text>
                      )}
                    </>
                    <>
                      {!(fetchData?.lessons?.length > 0) ? null : (
                        <SimpleStyleFlatList
                          data={fetchData?.lessons}
                          inverted={false}
                          keyExtractor={(listData, index) => listData?.id}
                          keyboardShouldPersistTaps={'never'}
                          listKey={'KotUGfEN'}
                          nestedScrollEnabled={false}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Latest Card */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'stretch',
                                      backgroundColor: theme.colors['White'],
                                      borderRadius: 10,
                                      flex: 1,
                                      flexDirection: 'column',
                                      marginLeft: 5,
                                      marginRight: 5,
                                      padding: 15,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Top Section */}
                                  <HStack
                                    {...GlobalStyles.HStackStyles(theme)[
                                      'H Stack'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.HStackStyles(theme)[
                                          'H Stack'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flex: 1,
                                          justifyContent: 'space-between',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { fontFamily: 'Roboto_500Medium' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.lesson_title}
                                      </Text>
                                    </View>
                                    {/* View 2 */}
                                    <View>
                                      {/* Text 2 */}
                                      <Text
                                        accessible={true}
                                        {...GlobalStyles.TextStyles(theme)[
                                          'Text'
                                        ].props}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ].style,
                                            { fontSize: 9, textAlign: 'right' }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {beautifyDate(listData?.lesson_date)}
                                      </Text>
                                    </View>
                                  </HStack>
                                  {/* Summary */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          fontFamily: 'Roboto_300Light',
                                          fontSize: 12,
                                          marginTop: 5,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {(listData?.lesson_summary).slice(0, 75)}
                                    {'...'}
                                  </Text>
                                  {/* Buttons */}
                                  <HStack
                                    {...GlobalStyles.HStackStyles(theme)[
                                      'H Stack'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.HStackStyles(theme)[
                                          'H Stack'
                                        ].style,
                                        {
                                          alignItems: 'flex-start',
                                          flex: 1,
                                          justifyContent: 'space-between',
                                          marginTop: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <IconButton
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              if (
                                                activePlayer === listData?.id
                                              ) {
                                                setActivePlayer(0);
                                                audioPlayerP4PfAexCRef.current.pause();
                                              } else {
                                                await audioPlayerUrl(
                                                  listData?.lesson_recording
                                                    ?.url
                                                );
                                                setActivePlayer(listData?.id);
                                                audioPlayerP4PfAexCRef.current.play();
                                              }
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        color={theme.colors['Strong']}
                                        icon={
                                          activePlayer === listData?.id
                                            ? 'AntDesign/pausecircle'
                                            : 'AntDesign/play'
                                        }
                                        size={28}
                                      />
                                      <AudioPlayer
                                        completedTrackColor={
                                          theme.colors.background
                                        }
                                        hideDuration={false}
                                        hidePlaybackIcon={false}
                                        hideSlider={false}
                                        interruptionMode={'lower volume'}
                                        playThroughEarpieceAndroid={false}
                                        playsInBackground={false}
                                        playsInSilentModeIOS={false}
                                        remainingTrackColor={
                                          theme.colors.divider
                                        }
                                        thumbColor={theme.colors.primary}
                                        togglePlaybackIconColor={
                                          theme.colors.primary
                                        }
                                        togglePlaybackIconSize={24}
                                        {...GlobalStyles.AudioPlayerStyles(
                                          theme
                                        )['Audio Player'].props}
                                        mode={'headless'}
                                        ref={audioPlayerP4PfAexCRef}
                                        source={{ uri: `${playerURL}` }}
                                        style={StyleSheet.applyWidth(
                                          GlobalStyles.AudioPlayerStyles(theme)[
                                            'Audio Player'
                                          ].style,
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                    {/* View 2 */}
                                    <View>
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'StudentStack',
                                              {
                                                screen: 'LessonsStack',
                                                params: {
                                                  screen: 'LessonDetailsScreen',
                                                  params: {
                                                    lessonID: listData?.id,
                                                  },
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={theme.colors['Strong']}
                                        icon={'AntDesign/arrowright'}
                                        size={28}
                                      />
                                    </View>
                                  </HStack>
                                </View>
                              </>
                            );
                          }}
                          horizontal={false}
                          numColumns={2}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              marginLeft: -5,
                              marginRight: -5,
                            },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </>
                );
              }}
            </XanoAPIApi.FetchGetLessonsGET>
          </View>
          {/* My Tasks */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                alignSelf: 'stretch',
                flex: 1,
                justifyContent: 'center',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Roboto_500Medium', marginBottom: 10 }
                ),
                dimensions.width
              )}
            >
              {'My Tasks'}
            </Text>

            <XanoAPIApi.FetchAllActionsGET
              UUID={Constants['UUID']}
              filter={'To Do'}
              numActions={3}
            >
              {({ loading, error, data, refetchAllActions }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <>
                    {/* Error 2 */}
                    <>
                      {!(fetchData?.length === 0) ? null : (
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            dimensions.width
                          )}
                        >
                          {
                            "It looks like you haven't had any lessons yet. Please record or upload a lesson to get started."
                          }
                        </Text>
                      )}
                    </>
                    <>
                      {!(fetchData?.length > 0) ? null : (
                        <SimpleStyleFlatList
                          data={fetchData}
                          inverted={false}
                          keyExtractor={(listData, index) => listData?.id}
                          keyboardShouldPersistTaps={'never'}
                          listKey={'CIalIQa9'}
                          nestedScrollEnabled={false}
                          onEndReachedThreshold={0.5}
                          renderItem={({ item, index }) => {
                            const listData = item;
                            return (
                              <>
                                {/* Task Card */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignSelf: 'stretch',
                                      backgroundColor: theme.colors['White'],
                                      borderRadius: 10,
                                      flex: 1,
                                      flexDirection: 'column',
                                      marginLeft: 5,
                                      marginRight: 5,
                                      padding: 15,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { flex: 1 },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            flex: 1,
                                            fontFamily: 'Roboto_500Medium',
                                            fontSize: 12,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.action_title}
                                    </Text>
                                  </View>
                                  {/* Buttons */}
                                  <HStack
                                    {...GlobalStyles.HStackStyles(theme)[
                                      'H Stack'
                                    ].props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.HStackStyles(theme)[
                                          'H Stack'
                                        ].style,
                                        {
                                          alignItems: 'flex-end',
                                          flex: 1,
                                          justifyContent: 'space-between',
                                          marginTop: 10,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    <View>
                                      <IconButton
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              setActionClick(listData?.id);
                                              (
                                                await xanoAPIActionStatusPUT.mutateAsync(
                                                  {
                                                    UUID: Constants['UUID'],
                                                    action_id: listData?.id,
                                                    lesson_id: 4,
                                                    status: 'Done',
                                                  }
                                                )
                                              )?.json;
                                              await refetchAllActions();
                                              await sleep(
                                                setGlobalVariableValue,
                                                2000
                                              );
                                              setActionClick(0);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        color={
                                          actionClick === listData?.id
                                            ? theme.colors['App Green']
                                            : theme.colors['Strong']
                                        }
                                        icon={'AntDesign/checkcircleo'}
                                        size={28}
                                      />
                                    </View>
                                    {/* View 2 */}
                                    <View>
                                      <IconButton
                                        onPress={() => {
                                          try {
                                            navigation.navigate(
                                              'StudentStack',
                                              {
                                                screen: 'LessonsStack',
                                                params: {
                                                  screen: 'MyTasksScreen',
                                                },
                                              }
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        color={theme.colors['Strong']}
                                        icon={'AntDesign/arrowright'}
                                        size={28}
                                      />
                                    </View>
                                  </HStack>
                                </View>
                              </>
                            );
                          }}
                          horizontal={false}
                          numColumns={3}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              marginLeft: -5,
                              marginRight: -5,
                            },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                  </>
                );
              }}
            </XanoAPIApi.FetchAllActionsGET>
          </View>
        </View>
        {/* Buttons */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-end',
              alignSelf: 'stretch',
              flex: 1,
              flexDirection: 'row',
              gap: 10,
              justifyContent: 'space-between',
              marginBottom: 30,
            },
            dimensions.width
          )}
        >
          {/* Upload */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'stretch',
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                flex: 1,
                flexDirection: 'column',
                height: 150,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Upload */}
            <Pressable
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked Upload (On Dashboard)',
                      })
                    )?.json;
                    navigation.navigate('StudentStack', {
                      screen: 'UploaderScreen',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                { height: '100%' },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, height: '100%', padding: 15 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      { flex: 1, fontFamily: 'Roboto_500Medium', fontSize: 18 }
                    ),
                    dimensions.width
                  )}
                >
                  {'UPLOAD'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'flex-end' },
                    dimensions.width
                  )}
                >
                  <Icon name={'AntDesign/clouduploado'} size={30} />
                </View>
              </View>
            </Pressable>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DashboardStudentScreen);
