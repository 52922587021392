import React, { useState, useEffect, useRef } from 'react';
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from 'react-native';
import { Camera } from 'expo-camera';

export const QRCodeScanner = ({ qrData, setQrData, qrError, setQrError }) => {
  const [hasPermission, setHasPermission] = useState(null);
  const [scanned, setScanned] = useState(false);
  const [cameraRatio, setCameraRatio] = useState('1:1');
  const cameraRef = useRef(null);

  const requestPermissions = async () => {
    if (Platform.OS === 'web') {
      try {
        // Use the Web API to request camera access
        await navigator.mediaDevices.getUserMedia({ video: true });
        setHasPermission(true); // Permission granted
      } catch (error) {
        console.error('Camera access denied', error);
        setHasPermission(false); // Permission denied
      }
    } else {
      // Use Expo's Camera API for iOS/Android
      const { status, canAskAgain } =
        await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    }
  };

  useEffect(() => {
    requestPermissions();
  }, []);

  const isValidUUIDv4 = data => {
    const regex =
      /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89ABab][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return regex.test(data);
  };

  const handleBarCodeScanned = ({ data }) => {
    if (isValidUUIDv4(data)) {
      setScanned(true);
      setQrData(data);
      setQrError(''); // Clear any previous error
      cameraRef.current?.pausePreview();
    } else {
      setQrError('Please scan a valid student ID');
    }
  };

  if (hasPermission === null) {
    return <View />;
  }

  return (
    <View style={styles.container}>
      {hasPermission === false ? (
        <View style={styles.permissionContainer}>
          <Text style={styles.text}>Camera permission not granted</Text>
          <TouchableOpacity
            style={styles.button}
            onPress={requestPermissions} // Call requestPermissions function on press
          >
            <Text style={styles.buttonText}>Grant Permission</Text>
          </TouchableOpacity>
        </View>
      ) : (
        <>
          <View style={styles.cameraContainer}>
            <Camera
              ref={cameraRef}
              onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
              style={StyleSheet.absoluteFillObject}
              ratio={cameraRatio}
              barCodeScannerSettings={{
                barCodeTypes: ['qr'],
              }}
            />
          </View>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              setScanned(false);
              setQrData(null);
              cameraRef.current?.resumePreview();
            }}
            disabled={!scanned}
          >
            <Text style={styles.buttonText}>Rescan</Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  permissionContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
  },
  cameraContainer: {
    width: '90%',
    aspectRatio: 1,
    overflow: 'hidden',
    borderRadius: 10,
    marginBottom: 10,
  },
  button: {
    marginTop: 5,
    paddingVertical: 10,
    paddingHorizontal: 20,
    backgroundColor: '#1e1e1e',
    borderRadius: 5,
  },
  buttonText: {
    color: 'white',
  },
  text: {
    textAlign: 'center',
    margin: 10,
  },
});
