import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import encodeQueryParam from '../utils/encodeQueryParam';
import * as GlobalVariables from '../config/GlobalVariableContext';

const cleanHeaders = headers =>
  Object.fromEntries(Object.entries(headers).filter(kv => kv[1] != null));

export const actionStatusPUT = (
  Constants,
  { UUID, action_id, lesson_id, status },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/action/${
      typeof action_id === 'string'
        ? action_id
        : JSON.stringify(action_id ?? '')
    }/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }/status?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&status=${encodeQueryParam(
      `${typeof status === 'string' ? status : JSON.stringify(status ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useActionStatusPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => actionStatusPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lesson Actions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson Action');
        queryClient.invalidateQueries('Lesson Actions');
      },
    }
  );
};

export const addLearner$Teacher$PUT = (
  Constants,
  { UUID, learnerUUID },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/add-learner?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&learnerUUID=${encodeQueryParam(
      `${
        typeof learnerUUID === 'string'
          ? learnerUUID
          : JSON.stringify(learnerUUID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useAddLearner$Teacher$PUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      addLearner$Teacher$PUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Teacher', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Teacher');
        queryClient.invalidateQueries('Teachers');
      },
    }
  );
};

export const allActionsGET = (
  Constants,
  { UUID, filter, numActions },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/action/all?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&numActions=${encodeQueryParam(
      `${
        typeof numActions === 'string'
          ? numActions
          : JSON.stringify(numActions ?? '')
      }`
    )}&filter=${encodeQueryParam(
      `${typeof filter === 'string' ? filter : JSON.stringify(filter ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useAllActionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Lesson Actions', args],
    () => allActionsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchAllActionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  filter,
  numActions,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAllActionsGET(
    { UUID, filter, numActions },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAllActions: refetch });
};

export const createConversationPOST = (Constants, { UUID }, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/conversations`, {
    body: JSON.stringify({ uuid: UUID }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateConversationPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createConversationPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Conversation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Conversation');
        queryClient.invalidateQueries('Conversations');
      },
    }
  );
};

export const FetchCreateConversationPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateConversationPOST(
    { UUID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateConversation: refetch });
};

export const createLessonInstancePOST = (Constants, { UUID }, handlers = {}) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/create-instance?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useCreateLessonInstancePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      createLessonInstancePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson');
        queryClient.invalidateQueries('Lessons');
      },
    }
  );
};

export const FetchCreateLessonInstancePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateLessonInstancePOST(
    { UUID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchCreateLessonInstance: refetch,
  });
};

export const createMessagePOST = (
  Constants,
  { UUID, conversation_Id, user_message },
  handlers = {}
) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/conversations/message`, {
    body: JSON.stringify({
      UUID: UUID,
      conversationId: conversation_Id,
      user_message: user_message,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useCreateMessagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => createMessagePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Conversation', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Conversation');
        queryClient.invalidateQueries('Conversations');
      },
    }
  );
};

export const FetchCreateMessagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  conversation_Id,
  user_message,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useCreateMessagePOST(
    { UUID, conversation_Id, user_message },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchCreateMessage: refetch });
};

export const deleteActionDELETE = (
  Constants,
  { UUID, action_id, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/action/${
      typeof action_id === 'string'
        ? action_id
        : JSON.stringify(action_id ?? '')
    }/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }/delete?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteActionDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteActionDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lesson Actions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson Action');
        queryClient.invalidateQueries('Lesson Actions');
      },
    }
  );
};

export const destroyLessonInstanceDELETE = (
  Constants,
  { UUID, activeLessonInstance },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/destroy-instance?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&activeLessonInstance=${encodeQueryParam(
      `${
        typeof activeLessonInstance === 'string'
          ? activeLessonInstance
          : JSON.stringify(activeLessonInstance ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDestroyLessonInstanceDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      destroyLessonInstanceDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson');
        queryClient.invalidateQueries('Lessons');
      },
    }
  );
};

export const editActionPUT = (
  Constants,
  {
    UUID,
    action_date,
    action_id,
    action_item,
    action_title,
    lesson_id,
    status,
  },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/action/${
      typeof action_id === 'string'
        ? action_id
        : JSON.stringify(action_id ?? '')
    }/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }/edit`,
    {
      body: JSON.stringify({
        UUID: UUID,
        status: status,
        action_title: action_title,
        action_date: action_date,
        action_item: action_item,
      }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useEditActionPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => editActionPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lesson Actions', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson Action');
        queryClient.invalidateQueries('Lesson Actions');
      },
    }
  );
};

export const getConversation$Single$GET = (
  Constants,
  { conversations_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/conversations/${
      typeof conversations_id === 'string'
        ? conversations_id
        : JSON.stringify(conversations_id ?? '')
    }`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetConversation$Single$GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Conversation', args],
    () => getConversation$Single$GET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Conversations']),
    }
  );
};

export const FetchGetConversation$Single$GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  conversations_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetConversation$Single$GET(
    { conversations_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetConversation$Single$: refetch,
  });
};

export const getConversationsGET = (Constants, _args, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/conversations`, {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
  }).then(res => handleResponse(res, handlers));

export const useGetConversationsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Conversations', args],
    () => getConversationsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetConversationsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetConversationsGET(
    {},
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetConversations: refetch });
};

export const getLessonsGET = (Constants, { UUID, numLessons }, handlers = {}) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&numLessons=${encodeQueryParam(
      `${
        typeof numLessons === 'string'
          ? numLessons
          : JSON.stringify(numLessons ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetLessonsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Lessons', args],
    () => getLessonsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetLessonsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  numLessons,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetLessonsGET(
    { UUID, numLessons },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetLessons: refetch });
};

export const getQRGET = (Constants, { UUID }, handlers = {}) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth/qr?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetQRGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAPIGetQRGET', args],
    () => getQRGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAPIGetQRGETS']),
    }
  );
};

export const FetchGetQRGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetQRGET(
    { UUID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetQR: refetch });
};

export const getSingleLessonGET = (
  Constants,
  { UUID, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/single/lesson/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSingleLessonGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Lesson', args],
    () => getSingleLessonGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Lessons']),
    }
  );
};

export const FetchGetSingleLessonGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSingleLessonGET(
    { UUID, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetSingleLesson: refetch });
};

export const getSingleLessonActionsGET = (
  Constants,
  { UUID, filter, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/single/extras/actions/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&filter=${encodeQueryParam(
      `${typeof filter === 'string' ? filter : JSON.stringify(filter ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSingleLessonActionsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Lesson Action', args],
    () => getSingleLessonActionsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Lesson Actions']),
    }
  );
};

export const FetchGetSingleLessonActionsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  filter,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSingleLessonActionsGET(
    { UUID, filter, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetSingleLessonActions: refetch,
  });
};

export const getSingleLessonSnippetsGET = (
  Constants,
  { UUID, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/single/extras/snippets/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useGetSingleLessonSnippetsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Lesson Snippet', args],
    () => getSingleLessonSnippetsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Lesson Snippets']),
    }
  );
};

export const FetchGetSingleLessonSnippetsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetSingleLessonSnippetsGET(
    { UUID, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetSingleLessonSnippets: refetch,
  });
};

export const interactionTrackingPOST = (
  Constants,
  { UUID, action },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/interaction_tracking?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      body: JSON.stringify({ action: action }),
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useInteractionTrackingPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      interactionTrackingPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Tracking', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Tracking');
        queryClient.invalidateQueries('Trackings');
      },
    }
  );
};

export const FetchInteractionTrackingPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  action,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useInteractionTrackingPOST(
    { UUID, action },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchInteractionTracking: refetch,
  });
};

export const loginPOST = (Constants, { email, password }, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth/login`, {
    body: JSON.stringify({ email: email, password: password }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useLoginPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAPILoginPOST', args],
    () => loginPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAPILoginPOSTS']),
    }
  );
};

export const FetchLoginPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLoginPOST(
    { email, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogin: refetch });
};

export const meGET = (Constants, _args, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth/me`, {
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
  }).then(res => handleResponse(res, handlers));

export const useMeGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAPIMeGET', args],
    () => meGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAPIMeGETS']),
    }
  );
};

export const FetchMeGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useMeGET({}, { refetchInterval, handlers: { onData, ...handlers } });

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchMe: refetch });
};

export const onboardingPUT = (Constants, { UUID }, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/surveys/onboarding`, {
    body: JSON.stringify({ UUID: UUID }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  }).then(res => handleResponse(res, handlers));

export const useOnboardingPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => onboardingPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Surveys', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Survey');
        queryClient.invalidateQueries('Surveys');
      },
    }
  );
};

export const recordLessonPOST = (
  Constants,
  { UUID, lesson_recording },
  handlers = {}
) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/record`, {
    body: JSON.stringify({ UUID: UUID, lesson_recording: lesson_recording }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useRecordLessonPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => recordLessonPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson');
        queryClient.invalidateQueries('Lessons');
      },
    }
  );
};

export const FetchRecordLessonPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  lesson_recording,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useRecordLessonPOST(
    { UUID, lesson_recording },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchRecordLesson: refetch });
};

export const scanLearner$Teacher$GET = (
  Constants,
  { UUID, learnerUUID },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/scan-learner?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&learnerUUID=${encodeQueryParam(
      `${
        typeof learnerUUID === 'string'
          ? learnerUUID
          : JSON.stringify(learnerUUID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useScanLearner$Teacher$GET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Teacher', args],
    () => scanLearner$Teacher$GET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Teachers']),
    }
  );
};

export const FetchScanLearner$Teacher$GET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  learnerUUID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useScanLearner$Teacher$GET(
    { UUID, learnerUUID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchScanLearner$Teacher$: refetch,
  });
};

export const signupPOST = (
  Constants,
  { accountType, email, inviteCode, name, password },
  handlers = {}
) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth/signup`, {
    body: JSON.stringify({
      name: name,
      email: email,
      password: password,
      account_type: accountType,
      inviteCode: inviteCode,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useSignupPOST = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['xanoAPISignupPOST', args],
    () => signupPOST(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['xanoAPISignupPOSTS']),
    }
  );
};

export const FetchSignupPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  accountType,
  email,
  inviteCode,
  name,
  password,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSignupPOST(
    { accountType, email, inviteCode, name, password },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSignup: refetch });
};

export const singleActionGET = (
  Constants,
  { UUID, action_id, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/action/${
      typeof action_id === 'string'
        ? action_id
        : JSON.stringify(action_id ?? '')
    }/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useSingleActionGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Lesson Action', args],
    () => singleActionGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Lesson Actions']),
    }
  );
};

export const FetchSingleActionGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  action_id,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useSingleActionGET(
    { UUID, action_id, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSingleAction: refetch });
};

export const studentLessonGET = (
  Constants,
  { UUID, learnerID, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/list-lessons/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&learnerID=${encodeQueryParam(
      `${
        typeof learnerID === 'string'
          ? learnerID
          : JSON.stringify(learnerID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useStudentLessonGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Teacher', args],
    () => studentLessonGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Teachers']),
    }
  );
};

export const FetchStudentLessonGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  learnerID,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStudentLessonGET(
    { UUID, learnerID, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStudentLesson: refetch });
};

export const studentLessonSnippetsGET = (
  Constants,
  { UUID, learnerID, lesson_id },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/list-lessons/${
      typeof lesson_id === 'string'
        ? lesson_id
        : JSON.stringify(lesson_id ?? '')
    }/snippets?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&learnerID=${encodeQueryParam(
      `${
        typeof learnerID === 'string'
          ? learnerID
          : JSON.stringify(learnerID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useStudentLessonSnippetsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Teachers', args],
    () => studentLessonSnippetsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchStudentLessonSnippetsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  learnerID,
  lesson_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStudentLessonSnippetsGET(
    { UUID, learnerID, lesson_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchStudentLessonSnippets: refetch,
  });
};

export const studentLessonsGET = (
  Constants,
  { UUID, learnerID },
  handlers = {}
) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/list-lessons?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}&learnerID=${encodeQueryParam(
      `${
        typeof learnerID === 'string'
          ? learnerID
          : JSON.stringify(learnerID ?? '')
      }`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useStudentLessonsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['Teacher', args],
    () => studentLessonsGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['Teachers']),
    }
  );
};

export const FetchStudentLessonsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  learnerID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStudentLessonsGET(
    { UUID, learnerID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStudentLessons: refetch });
};

export const studentsGET = (Constants, { UUID }, handlers = {}) =>
  fetch(
    `https://api.heymaestro.me/api:EJWp6KFE/teacher/list-students?UUID=${encodeQueryParam(
      `${typeof UUID === 'string' ? UUID : JSON.stringify(UUID ?? '')}`
    )}`,
    {
      headers: cleanHeaders({
        Accept: 'application/json',
        Authorization: Constants['authToken'],
        'Content-Type': 'application/json',
      }),
    }
  ).then(res => handleResponse(res, handlers));

export const useStudentsGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['Teachers', args],
    () => studentsGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchStudentsGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useStudentsGET(
    { UUID },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStudents: refetch });
};

export const teacherNotePOST = (
  Constants,
  { UUID, lesson_id, note, pathway },
  handlers = {}
) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/teacher/lesson-notes`, {
    body: JSON.stringify({
      UUID: UUID,
      pathway: pathway,
      lesson_id: lesson_id,
      note: note,
    }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useTeacherNotePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => teacherNotePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Teacher', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Teacher');
        queryClient.invalidateQueries('Teachers');
      },
    }
  );
};

export const FetchTeacherNotePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  lesson_id,
  note,
  pathway,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useTeacherNotePOST(
    { UUID, lesson_id, note, pathway },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchTeacherNote: refetch });
};

export const updateAvatarPUT = (Constants, { UUID, avatar }, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth/edit/avatar`, {
    body: JSON.stringify({ UUID: UUID, avatar: avatar }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  }).then(res => handleResponse(res, handlers));

export const useUpdateAvatarPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateAvatarPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Update User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Update User');
        queryClient.invalidateQueries('Update Users');
      },
    }
  );
};

export const updateUserPUT = (Constants, { UUID, dob, name }, handlers = {}) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth/edit/user`, {
    body: JSON.stringify({ UUID: UUID, name: name, dob: dob }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
  }).then(res => handleResponse(res, handlers));

export const useUpdateUserPUT = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateUserPUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Update User', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Update User');
        queryClient.invalidateQueries('Update Users');
      },
    }
  );
};

export const uploadLessonPOST = (
  Constants,
  { UUID, lesson_recording },
  handlers = {}
) =>
  fetch(`https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/upload`, {
    body: JSON.stringify({ UUID: UUID, lesson_recording: lesson_recording }),
    headers: cleanHeaders({
      Accept: 'application/json',
      Authorization: Constants['authToken'],
      'Content-Type': 'application/json',
    }),
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useUploadLessonPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => uploadLessonPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('Lessons', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('Lesson');
        queryClient.invalidateQueries('Lessons');
      },
    }
  );
};

export const FetchUploadLessonPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  UUID,
  lesson_recording,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadLessonPOST(
    { UUID, lesson_recording },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUploadLesson: refetch });
};
