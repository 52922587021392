import React, { useState, useEffect, useRef } from 'react';
import { View, Text, StyleSheet, Animated, Easing } from 'react-native';

export const TimerComponent = ({ stateStatus }) => {
  const [timerMilliseconds, setTimerMilliseconds] = useState(0);
  const spinValue = useRef(new Animated.Value(0)).current;
  const animationRunning = useRef(false);

  const formatTime = () => {
    const minutes = Math.floor(timerMilliseconds / 60000)
      .toString()
      .padStart(2, '0');
    const seconds = Math.floor((timerMilliseconds % 60000) / 1000)
      .toString()
      .padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  const runSpinAnimation = () => {
    // Start the animation from the current value
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 1000 - spinValue.__getValue() * 1000, // Adjust duration based on current value
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      if (stateStatus === 'recording' && animationRunning.current) {
        spinValue.setValue(0); // Reset only when we want to loop
        runSpinAnimation(); // Loop the animation by calling it inside the start callback
      }
    });
  };

  useEffect(() => {
    let intervalId;

    if (stateStatus === 'recording') {
      animationRunning.current = true;
      intervalId = setInterval(() => {
        setTimerMilliseconds(prevMilliseconds => prevMilliseconds + 1000);
      }, 1000);
      runSpinAnimation(); // Ensure the spinner starts with the recording
    } else {
      clearInterval(intervalId);
      if (stateStatus === 'recording paused') {
        animationRunning.current = false;
        spinValue.stopAnimation(); // Explicitly stop the animation without resetting
      } else if (stateStatus === 'recording complete') {
        animationRunning.current = false;
        setTimerMilliseconds(0);
        spinValue.setValue(0); // Reset the spinner only when recording is complete
      }
    }

    return () => {
      clearInterval(intervalId); // Cleanup interval on component unmount
      animationRunning.current = false;
      spinValue.stopAnimation(); // Ensure animation is stopped when component unmounts or state changes
    };
  }, [stateStatus]);

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  });

  return (
    <View style={styles.container}>
      {/* Animated circle with the spinning marker */}
      <Animated.View style={[styles.circle, { transform: [{ rotate: spin }] }]}>
        <View style={styles.marker} />
      </Animated.View>
      {/* Timer text */}
      <Text style={styles.timerText}>{formatTime()}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 0,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginBottom: 20,
  },
  timerText: {
    fontSize: 55,
    color: 'black',
    fontFamily: 'Roboto_400Regular',
    position: 'absolute',
  },
  circle: {
    width: 250,
    height: 250,
    borderRadius: '100%',
    borderWidth: 2,
    borderColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  marker: {
    width: 15,
    height: 15,
    borderRadius: '100%',
    backgroundColor: '#1e1e1e',
    position: 'absolute',
    top: 5,
    right: '45%',
  },
});
