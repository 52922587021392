import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Icon,
  ScreenContainer,
  Swiper,
  SwiperItem,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';

const OnboardingScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const xanoAPIOnboardingPUT = XanoAPIApi.useOnboardingPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'onboarding',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['White'],
          maxHeight: dimensions.height,
        },
        dimensions.width
      )}
    >
      {/* Student */}
      <>
        {!(Constants['accountType'] === 'Student') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flex: 1,
                justifyContent: 'space-evenly',
                marginBottom: 40,
                marginLeft: 10,
                marginRight: 10,
                marginTop: 40,
              },
              dimensions.width
            )}
          >
            <Swiper
              dotActiveColor={theme.colors.primary}
              dotColor={theme.colors.light}
              dotsTouchable={true}
              loop={false}
              minDistanceForAction={0.2}
              minDistanceToCapture={5}
              timeout={0}
              vertical={false}
              {...GlobalStyles.SwiperStyles(theme)['Swiper'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.SwiperStyles(theme)['Swiper'].style,
                  {
                    alignSelf: 'center',
                    backgroundColor: theme.colors['White'],
                    flex: 1,
                    height: null,
                  }
                ),
                dimensions.width
              )}
            >
              <SwiperItem
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'space-evenly',
                    marginBottom: '30%',
                    paddingLeft: 25,
                    paddingRight: 25,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'auto', flex: 1, justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'Roboto_500Medium',
                          fontSize: 24,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Progress Faster for the same effort!'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'Roboto_400Regular',
                          fontSize: 16,
                          marginTop: 15,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      '1. Record your lesson & upload it to Hey Maestro\n\n2. Hey Maestro generates your supercharging practice guide: comprehensive, personalised practice notes and rich snippets based upon what your teacher says in lessons\n\n3. Use it during your practice to help boost your progress'
                    }
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignSelf: 'center',
                        justifyContent: 'center',
                        marginTop: 20,
                      },
                      dimensions.width
                    )}
                  >
                    <Icon
                      size={24}
                      name={'MaterialCommunityIcons/gesture-swipe-right'}
                    />
                  </View>
                </View>
              </SwiperItem>
              {/* Swiper Item 4 */}
              <SwiperItem
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    justifyContent: 'center',
                    marginBottom: '30%',
                    paddingLeft: 25,
                    paddingRight: 25,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignSelf: 'auto', justifyContent: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Title */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'Roboto_500Medium',
                          fontSize: 24,
                          marginTop: 10,
                          textAlign: 'left',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Feed back your thoughts'}
                  </Text>

                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'Roboto_400Regular',
                          fontSize: 16,
                          marginTop: 15,
                          textAlign: 'justify',
                          whiteSpace: 'pre-line',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {
                      'Your feedback is critical to helping us to supercharge your progress. We’ll ask for feedback from the practice notes, and your general progress.'
                    }
                  </Text>
                </View>
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setGlobalVariableValue({
                          key: 'onboarded',
                          value: true,
                        });
                        (
                          await xanoAPIOnboardingPUT.mutateAsync({
                            UUID: Constants['UUID'],
                          })
                        )?.json;
                        if (Constants['accountType'] === 'Student') {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('StudentStack', {
                            screen: 'DashboardStudentScreen',
                          });
                        } else {
                          if (navigation.canGoBack()) {
                            navigation.popToTop();
                          }
                          navigation.replace('TeacherStack', {
                            screen: 'DashboardTeacherScreen',
                          });
                        }
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'].style,
                      { backgroundColor: theme.colors['Strong'], marginTop: 30 }
                    ),
                    dimensions.width
                  )}
                  title={'Get Started'}
                />
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontSize: 10,
                        marginTop: 25,
                        paddingLeft: 20,
                        paddingRight: 20,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "Please note: Our app is currently in Beta. Things might not work as expected, but we're constantly making improvements. Please report any bugs or issues so we can make your experience even better."
                  }
                </Text>
              </SwiperItem>
            </Swiper>
          </View>
        )}
      </>
      {/* Teacher */}
      <>
        {!(Constants['accountType'] === 'Teacher') ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flex: 1, justifyContent: 'center' },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', justifyContent: 'center', padding: 30 },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: 'Roboto_500Medium',
                      fontSize: 16,
                      marginBottom: 10,
                    }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Auto-generated lesson notes, saving you time and helping students practice better.'
                }
              </Text>

              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    { fontFamily: 'Roboto_400Regular' }
                  ),
                  dimensions.width
                )}
              >
                {
                  'Give your students the best help in-between lessons in 3 easy steps:\n\n1) Explore the demo lesson on your dashboard\n2) Get your student to download the app too\n3) Enrol your student (by scanning their QR code)'
                }
              </Text>
            </View>
            {/* Button 2 */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    setGlobalVariableValue({
                      key: 'onboarded',
                      value: true,
                    });
                    (
                      await xanoAPIOnboardingPUT.mutateAsync({
                        UUID: Constants['UUID'],
                      })
                    )?.json;
                    if (Constants['accountType'] === 'Student') {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('StudentStack', {
                        screen: 'DashboardStudentScreen',
                      });
                    } else {
                      if (navigation.canGoBack()) {
                        navigation.popToTop();
                      }
                      navigation.replace('TeacherStack', {
                        screen: 'DashboardTeacherScreen',
                      });
                    }
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  { backgroundColor: theme.colors['Strong'], marginTop: 30 }
                ),
                dimensions.width
              )}
              title={'Get Started'}
            />
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(OnboardingScreen);
