import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AV from '../custom-files/AV';
import sleep from '../global-functions/sleep';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, Platform, Text, View } from 'react-native';

const DemoLessonScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [lessonNotes, setLessonNotes] = React.useState('');
  const [lessonRecording_URL, setLessonRecording_URL] = React.useState('');
  const [lessonStatus, setLessonStatus] = React.useState('');
  const [lesson_id, setLesson_id] = React.useState(0);
  const [recording, setRecording] = React.useState({});
  const [recording_uri, setRecording_uri] = React.useState('');
  const [saveButtonColour, setSaveButtonColour] = React.useState('#1e1e1e');
  const [stateStatus, setStateStatus] = React.useState('');
  const [summary_button, setSummary_button] = React.useState('Show More');
  const [summary_readmore, setSummary_readmore] = React.useState(false);
  const startRecording = async () => {
    try {
      console.log('Requesting permissions..');
      await AV.Audio.requestPermissionsAsync();
      await AV.Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      console.log('Starting recording..');
      const { recording } = await AV.Audio.Recording.createAsync(
        AV.Audio.RecordingOptionsPresets.HIGH_QUALITY
      );
      setRecording(recording);
      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const stopRecording = async (Variables, setGlobalVariableValue) => {
    console.log('Stopping recording...');

    let uri = '';
    if (recording) {
      try {
        await recording.stopAndUnloadAsync(); // Stop the recording
        uri = recording.getURI(); // Get the recording URI
        setRecording_uri(uri);

        // Since Audio is imported as AV, use AV.Audio.Sound.createAsync
        const creationResult = await AV.Audio.Sound.createAsync(
          { uri: uri },
          { shouldPlay: false }
        );
        const sound = creationResult.sound;

        // Use a timeout to allow the metadata to load
        setTimeout(async () => {
          const status = await sound.getStatusAsync();
          if (status.isLoaded) {
            console.log('Sound is loaded');
          } else {
            console.log('Failed to load sound');
          }

          // Unload the sound to clean up
          await sound.unloadAsync();
        }, 1000); // Adjust the delay time as needed
      } catch (error) {
        console.error('Failed to stop recording', error);
      }
    }

    console.log('recording data from expo-av: ', recording);

    return uri;
  };

  const handleRecording = async Variables => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;
    const deviceCheck = Platform.OS;
    const userName = Variables.name;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('lesson_id', lesson_id);
    formData.append('device', deviceCheck);
    formData.append('pathway', 'audio');

    // Use the recording object to dynamically set the MIME type and extension
    let fileType = 'audio/m4a'; // Default MIME type
    let fileExtension = 'm4a'; // Default extension
    let fileUri = recording.getURI(); // Method to get URI from the recording object

    if (Platform.OS === 'android') {
      fileType = `audio/${recording._options.android.extension.replace(
        '.',
        ''
      )}`;
      fileExtension = recording._options.android.extension.replace('.', '');
    } else if (Platform.OS === 'ios') {
      fileType = `audio/${recording._options.ios.extension
        .trim()
        .replace('.', '')}`;
      fileExtension = recording._options.ios.extension.trim().replace('.', '');
    } else if (Platform.OS === 'web') {
      fileType = recording._options.web.mimeType;
      fileExtension = fileType.split('/')[1];
    }

    // When creating the name, only add one period between the UUID and file extension
    const audioFile = {
      uri: fileUri,
      type: fileType,
      name: `recording-${UUID}.${fileExtension}`, // Ensure only one period here
    };

    if (Platform.OS === 'web') {
      try {
        const response = await fetch(fileUri);
        const blob = await response.blob();
        formData.append(
          'message_audio',
          blob,
          `recording-${UUID}.${fileExtension}`
        );

        // Convert blob to base64
        const base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Resolve only the base64 string without the Data URI scheme prefix
            const result = reader.result;
            const base64String = result.split(',')[1]; // This removes the Data URI prefix
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        // Append base64 data to formData
        formData.append('message_audio_raw', base64data);
      } catch (error) {
        console.error('Error fetching the blob:', error);
        return; // Exit the function if an error occurs
      }
    } else {
      formData.append('message_audio', audioFile);
    }

    // Xano API endpoint
    const apiEndpoint =
      'https://api.heymaestro.me/api:EJWp6KFE/teacher/lesson-notes';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse the JSON response
        const teacherNote = responseData.teacherNote; // Access the teacherNote property
        setLessonNotes(teacherNote);
        setStateStatus('ready');
      } else {
        console.error('Upload Failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };

  const handleRecordingDemo = async Variables => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;
    const deviceCheck = Platform.OS;
    const userName = Variables.name;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('device', deviceCheck);
    formData.append('pathway', 'audio');

    // Use the recording object to dynamically set the MIME type and extension
    let fileType = 'audio/m4a'; // Default MIME type
    let fileExtension = 'm4a'; // Default extension
    let fileUri = recording.getURI(); // Method to get URI from the recording object

    if (Platform.OS === 'android') {
      fileType = `audio/${recording._options.android.extension.replace(
        '.',
        ''
      )}`;
      fileExtension = recording._options.android.extension.replace('.', '');
    } else if (Platform.OS === 'ios') {
      fileType = `audio/${recording._options.ios.extension
        .trim()
        .replace('.', '')}`;
      fileExtension = recording._options.ios.extension.trim().replace('.', '');
    } else if (Platform.OS === 'web') {
      fileType = recording._options.web.mimeType;
      fileExtension = fileType.split('/')[1];
    }

    // When creating the name, only add one period between the UUID and file extension
    const audioFile = {
      uri: fileUri,
      type: fileType,
      name: `recording-${UUID}.${fileExtension}`, // Ensure only one period here
    };

    if (Platform.OS === 'web') {
      try {
        const response = await fetch(fileUri);
        const blob = await response.blob();
        formData.append(
          'message_audio',
          blob,
          `recording-${UUID}.${fileExtension}`
        );

        // Convert blob to base64
        const base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Resolve only the base64 string without the Data URI scheme prefix
            const result = reader.result;
            const base64String = result.split(',')[1]; // This removes the Data URI prefix
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        // Append base64 data to formData
        formData.append('message_audio_raw', base64data);
      } catch (error) {
        console.error('Error fetching the blob:', error);
        return; // Exit the function if an error occurs
      }
    } else {
      formData.append('message_audio', audioFile);
    }

    // Xano API endpoint
    const apiEndpoint =
      'https://api.heymaestro.me/api:EJWp6KFE/teacher/lesson-notes/demo';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse the JSON response
        const teacherNote = responseData.teacherNote; // Access the teacherNote property
        setLessonNotes(teacherNote);
        setStateStatus('ready');
      } else {
        console.error('Upload Failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };
  const xanoAPITeacherNotePOST = XanoAPIApi.useTeacherNotePOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'student lesson details',
      });
      setStateStatus('ready');
      setLesson_id(props.route?.params?.lessonID ?? 67);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth({ paddingBottom: 20 }, dimensions.width)}
    >
      <HeaderBlock />
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, marginTop: 0, paddingLeft: 20, paddingRight: 20 },
          dimensions.width
        )}
      >
        {/* Summary */}
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'flex-start', marginTop: 0, paddingBottom: 20 },
            dimensions.width
          )}
        >
          {/* Lesson Title */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 20,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 10,
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    fontSize: 20,
                    textAlign: 'center',
                    textTransform: 'capitalize',
                  }
                ),
                dimensions.width
              )}
            >
              {'This Is My Demo Lesson'}
            </Text>
          </View>
          {/* Summary */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['White'],
                borderRadius: 4,
                marginLeft: 20,
                marginRight: 20,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Heading */}
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'System', fontSize: 16, fontWeight: '700' }
                ),
                dimensions.width
              )}
            >
              {'Summary'}
            </Text>
            {/* Preview Text */}
            <>
              {summary_readmore ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  ellipsizeMode={'tail'}
                  numberOfLines={5}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        paddingTop: 10,
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "In today's singing lesson with the legendary tenor Luciano Pavarotti, students experienced a masterclass in operatic technique. Pavarotti emphasized the importance of breath control, demonstrating exercises to enhance vocal stability and projection. He passionately discussed the emotional aspects of singing, instructing students on how to connect deeply with the music to convey genuine emotion. He also provided personalized feedback, focusing on improving diction and resonance for each student. The lesson concluded with Pavarotti sharing anecdotes from his illustrious career, inspiring the students with his dedication and love for opera. This session not only enriched the students' technical skills but also left them motivated to express their artistic voices with authenticity and precision."
                  }
                </Text>
              )}
            </>
            {/* Full Text */}
            <>
              {!summary_readmore ? null : (
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  ellipsizeMode={'tail'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        fontSize: 14,
                        paddingTop: 10,
                        textAlign: 'justify',
                        whiteSpace: 'pre-line',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {
                    "In today's singing lesson with the legendary tenor Luciano Pavarotti, students experienced a masterclass in operatic technique. Pavarotti emphasized the importance of breath control, demonstrating exercises to enhance vocal stability and projection. He passionately discussed the emotional aspects of singing, instructing students on how to connect deeply with the music to convey genuine emotion. He also provided personalized feedback, focusing on improving diction and resonance for each student. The lesson concluded with Pavarotti sharing anecdotes from his illustrious career, inspiring the students with his dedication and love for opera. This session not only enriched the students' technical skills but also left them motivated to express their artistic voices with authenticity and precision."
                  }
                </Text>
              )}
            </>
            {/* Summary Toggle Button */}
            <Link
              accessible={true}
              onPress={() => {
                try {
                  setSummary_readmore(!summary_readmore);
                  if (summary_readmore === true) {
                    setSummary_button('Show More');
                  }
                  if (summary_readmore === false) {
                    setSummary_button('Show Less');
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
              {...GlobalStyles.LinkStyles(theme)['Link'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.LinkStyles(theme)['Link'].style,
                  { alignSelf: 'flex-end', marginTop: 10 }
                ),
                dimensions.width
              )}
              title={`${summary_button}`}
            />
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontSize: 10, textAlign: 'justify', whiteSpace: 'pre-line' }
                ),
                dimensions.width
              )}
            >
              {
                '\nNOTE: Your student will also be able to hear their lesson recording, see their actionable tasks and playable lesson snippets.'
              }
            </Text>
          </View>
        </View>
        {/* Notes */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 2, marginTop: 20 },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                color: null,
              }),
              dimensions.width
            )}
          >
            {'Lesson Notes'}
          </Text>

          <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
            {/* Recording */}
            <>
              {!(stateStatus === 'recording') ? null : (
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={Images.Recording}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: 10,
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        width: 10,
                      }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* Processing */}
            <>
              {!(stateStatus === 'processing') ? null : (
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={Images.Loading}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      {
                        height: 10,
                        position: 'absolute',
                        right: 10,
                        top: 20,
                        width: 10,
                      }
                    ),
                    dimensions.width
                  )}
                />
              )}
            </>
            <TextInput
              autoCorrect={true}
              changeTextDelay={500}
              multiline={true}
              onChangeText={newTextAreaValue => {
                try {
                  setLessonNotes(newTextAreaValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              textAlignVertical={'top'}
              webShowOutline={true}
              {...GlobalStyles.TextInputStyles(theme)['Text Area'].props}
              numberOfLines={10}
              placeholder={'Your lesson notes here...'}
              placeholderTextColor={theme.colors['Light']}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Area'].style,
                  { marginTop: 10 }
                ),
                dimensions.width
              )}
              value={lessonNotes}
            />
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end', marginRight: 10, marginTop: -30 },
                dimensions.width
              )}
            >
              <IconButton
                onPress={() => {
                  const handler = async () => {
                    try {
                      setStateStatus('processing');
                      /* hidden 'API Request' action */
                      setSaveButtonColour('#32CD32');
                      await sleep(setGlobalVariableValue, 1000);
                      setSaveButtonColour('#1e1e1e');
                      setStateStatus('ready');
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                color={saveButtonColour}
                icon={'FontAwesome/save'}
                size={20}
              />
            </View>
          </View>
          {/* Recording Buttons */}
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: 'flex-end', marginTop: 20 },
              dimensions.width
            )}
          >
            {/* Buttons */}
            <View
              style={StyleSheet.applyWidth(
                { alignSelf: 'center', flex: 1, flexDirection: 'row', gap: 5 },
                dimensions.width
              )}
            >
              {/* Start Recording */}
              <Pressable
                onPress={() => {
                  const handler = async () => {
                    try {
                      setStateStatus('recording');
                      await startRecording();
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                disabled={stateStatus !== 'ready'}
                style={StyleSheet.applyWidth(
                  { height: '100%' },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['White'],
                      borderRadius: 4,
                      height: 150,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={theme.colors['Error']}
                    name={'Entypo/controller-record'}
                  />
                </View>
              </Pressable>
              {/* Stop Recording */}
              <Pressable
                onPress={() => {
                  const handler = async () => {
                    try {
                      setStateStatus('recording complete');
                      await stopRecording(Variables, setGlobalVariableValue);
                      setStateStatus('processing');
                      await handleRecordingDemo(Variables);
                    } catch (err) {
                      console.error(err);
                    }
                  };
                  handler();
                }}
                disabled={stateStatus !== 'recording'}
                style={StyleSheet.applyWidth(
                  { height: '100%' },
                  dimensions.width
                )}
              >
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['White'],
                      borderRadius: 4,
                      height: 150,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    size={24}
                    color={theme.colors['Custom Color_26']}
                    name={'Ionicons/stop'}
                  />
                </View>
              </Pressable>
            </View>

            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  {
                    color: theme.colors['Medium'],
                    fontSize: 10,
                    textAlign: 'center',
                  }
                ),
                dimensions.width
              )}
            >
              {'Recording will replace any text in your lesson notes above.'}
            </Text>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(DemoLessonScreen);
