import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Picker,
  ScreenContainer,
  SwipeableItem,
  SwipeableItemButton,
  TextField,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  FlatList,
  Modal,
  RefreshControl,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const MyTasksScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [actionsToggle, setActionsToggle] = React.useState(false);
  const [edit_action_item_description, setEdit_action_item_description] =
    React.useState('');
  const [edit_action_item_id, setEdit_action_item_id] = React.useState(null);
  const [edit_action_item_status, setEdit_action_item_status] =
    React.useState('');
  const [edit_action_item_title, setEdit_action_item_title] =
    React.useState('');
  const [edit_action_lesson_id, setEdit_action_lesson_id] = React.useState(0);
  const [edit_action_toggle, setEdit_action_toggle] = React.useState(false);
  const [refreshinguy4xZ2tx, setRefreshinguy4xZ2tx] = React.useState(false);
  const xanoAPIDeleteActionDELETE = XanoAPIApi.useDeleteActionDELETE();
  const xanoAPIActionStatusPUT = XanoAPIApi.useActionStatusPUT();
  const xanoAPIEditActionPUT = XanoAPIApi.useEditActionPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'my tasks',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={false}
      style={StyleSheet.applyWidth({ marginTop: 0 }, dimensions.width)}
    >
      <HeaderBlock />
      <ScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 80 },
          dimensions.width
        )}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* Actions */}
        <View>
          {/* Title */}
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Text'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'].style, {
                fontFamily: 'Roboto_700Bold',
                fontSize: 16,
                marginBottom: 5,
                marginLeft: 20,
                marginRight: 20,
                marginTop: 20,
              }),
              dimensions.width
            )}
          >
            {'Actions'}
          </Text>
          {/* Fetch_Actions */}
          <XanoAPIApi.FetchAllActionsGET
            UUID={Constants['UUID']}
            filter={'To Do'}
            numActions={0}
          >
            {({ loading, error, data, refetchAllActions }) => {
              const fetchActionsData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Catch - No Actions */}
                  <>
                    {fetchActionsData && fetchActionsData[0] ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            { marginLeft: 20, marginRight: 20 }
                          ),
                          dimensions.width
                        )}
                      >
                        {
                          "Ooops. We can't find any actions for this lesson. That might be because you've completed them all!"
                        }
                      </Text>
                    )}
                  </>
                  {/* Actions */}
                  <FlatList
                    data={fetchActionsData}
                    horizontal={false}
                    inverted={false}
                    keyExtractor={(actionsData, index) => actionsData?.id}
                    keyboardShouldPersistTaps={'never'}
                    listKey={'uy4xZ2tx'}
                    nestedScrollEnabled={false}
                    numColumns={1}
                    onEndReachedThreshold={0.5}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshinguy4xZ2tx}
                        onRefresh={() => {
                          const handler = async () => {
                            try {
                              setRefreshinguy4xZ2tx(true);
                              await refetchGetSingleLessonActions();
                              setRefreshinguy4xZ2tx(false);
                            } catch (err) {
                              console.error(err);
                              setRefreshinguy4xZ2tx(false);
                            }
                          };
                          handler();
                        }}
                        colors={[theme.colors['Primary']]}
                        tintColor={theme.colors['Primary']}
                      />
                    }
                    renderItem={({ item, index }) => {
                      const actionsData = item;
                      return (
                        <SwipeableItem
                          closeOnPress={true}
                          friction={20}
                          swipeActivationPercentage={80}
                          swipeToClosePercent={50}
                          swipeToOpenPercent={50}
                          {...GlobalStyles.SwipeableItemStyles(theme)[
                            'Swipeable Item'
                          ].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.SwipeableItemStyles(theme)[
                                'Swipeable Item'
                              ].style,
                              {
                                marginBottom: 10,
                                marginTop: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {/* Swipe Left - Edit */}
                          <SwipeableItemButton
                            closeOnPress={true}
                            onPress={() => {
                              try {
                                setEdit_action_item_id(actionsData?.id);
                                setEdit_action_lesson_id(
                                  actionsData?.lessons_id
                                );
                                setEdit_action_toggle(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            revealSwipeDirection={'left'}
                            backgroundColor={theme.colors['Primary']}
                            icon={'Feather/edit'}
                            title={'Edit'}
                          />
                          {/* Swipe Left - Delete */}
                          <SwipeableItemButton
                            closeOnPress={true}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  const deleteResponse = (
                                    await xanoAPIDeleteActionDELETE.mutateAsync(
                                      {
                                        UUID: Constants['UUID'],
                                        action_id: actionsData?.id,
                                        lesson_id: actionsData?.lessons_id,
                                      }
                                    )
                                  )?.json;

                                  showAlertUtil({
                                    title: 'Delete Complete',
                                    message: deleteResponse,
                                    buttonText: 'Okay',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            revealSwipeDirection={'left'}
                            backgroundColor={theme.colors['Error']}
                            icon={'Feather/trash'}
                            title={'Delete'}
                          />
                          {/* Swipe Right - Mark Done */}
                          <SwipeableItemButton
                            closeOnPress={true}
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  (
                                    await xanoAPIActionStatusPUT.mutateAsync({
                                      UUID: Constants['UUID'],
                                      action_id: actionsData?.id,
                                      lesson_id: actionsData?.lessons_id,
                                      status: 'Done',
                                    })
                                  )?.json;
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            {...GlobalStyles.SwipeableItemButtonStyles(theme)[
                              'Swipeable Button'
                            ].props}
                            backgroundColor={theme.colors['App Green']}
                            icon={'Feather/check-square'}
                            revealSwipeDirection={'right'}
                            style={StyleSheet.applyWidth(
                              GlobalStyles.SwipeableItemButtonStyles(theme)[
                                'Swipeable Button'
                              ].style,
                              dimensions.width
                            )}
                            title={'Done'}
                          />
                          {/* Container Outer */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'stretch',
                                alignSelf: 'auto',
                                backgroundColor: theme.colors['White'],
                                borderRadius: 4,
                                flexDirection: 'column',
                                flexWrap: 'nowrap',
                                padding: 20,
                              },
                              dimensions.width
                            )}
                          >
                            {/* H Stack */}
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'stretch', flexDirection: 'row' },
                                dimensions.width
                              )}
                            >
                              {/* Left */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    flex: 4,
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Action Title */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        fontFamily: 'System',
                                        fontSize: 14,
                                        fontWeight: '700',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {actionsData?.action_title}
                                </Text>
                              </View>
                              {/* Right */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignContent: 'flex-end',
                                    alignItems: 'stretch',
                                    alignSelf: 'center',
                                    flex: 1,
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    justifyContent: 'center',
                                  },
                                  dimensions.width
                                )}
                              />
                            </View>
                            {/* Action Summary */}
                            <Text
                              accessible={true}
                              {...GlobalStyles.TextStyles(theme)['Text'].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'].style,
                                  {
                                    paddingTop: 5,
                                    textAlign: 'justify',
                                    whiteSpace: 'pre-line',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {actionsData?.action_item}
                            </Text>
                          </View>
                        </SwipeableItem>
                      );
                    }}
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    contentContainerStyle={StyleSheet.applyWidth(
                      { flexDirection: 'column' },
                      dimensions.width
                    )}
                  />
                </>
              );
            }}
          </XanoAPIApi.FetchAllActionsGET>
        </View>
      </ScrollView>
      {/* Edit Action */}
      <Modal
        animationType={'none'}
        transparent={true}
        visible={edit_action_toggle}
      >
        <BlurView
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={10}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center' }
            ),
            dimensions.width
          )}
          tint={'dark'}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                height: '80%',
                margin: 30,
                marginBottom: 40,
                marginTop: 40,
                padding: 30,
                width: '80%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Roboto_700Bold' }
                ),
                dimensions.width
              )}
            >
              {'Edit Action'}
            </Text>

            <XanoAPIApi.FetchSingleActionGET
              UUID={Constants['UUID']}
              action_id={edit_action_item_id}
              handlers={{
                onData: fetchData => {
                  try {
                    setEdit_action_item_status(fetchData?.action_status);
                    setEdit_action_item_title(fetchData?.action_title);
                    setEdit_action_item_description(fetchData?.action_item);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              lesson_id={edit_action_lesson_id}
            >
              {({ loading, error, data, refetchSingleAction }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, justifyContent: 'flex-start', marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Status Input */}
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newStatusInputValue => {
                        try {
                          setEdit_action_item_status(newStatusInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      dropDownBackgroundColor={theme.colors['White']}
                      options={[
                        { label: 'To Do', value: 'To Do' },
                        { label: 'In Progress', value: 'In Progress' },
                        { label: 'Done', value: 'Done' },
                      ]}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_status}
                    />
                    {/* Title Input */}
                    <TextField
                      activeBorderColor={theme.colors.primary}
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTitleInputValue => {
                        try {
                          setEdit_action_item_title(newTitleInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      }
                      type={'solid'}
                      underlineColor={theme.colors.light}
                      webShowOutline={true}
                      label={'Title'}
                      maxLength={75}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_title}
                    />
                    {/* Task Description Input */}
                    <TextField
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      multiline={true}
                      onChangeText={newTaskDescriptionInputValue => {
                        try {
                          setEdit_action_item_description(
                            newTaskDescriptionInputValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      }
                      type={'solid'}
                      webShowOutline={true}
                      label={'Task Description'}
                      maxLength={600}
                      numberOfLines={10}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_description}
                    />
                    {/* Update */}
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await xanoAPIEditActionPUT.mutateAsync({
                                UUID: Constants['UUID'],
                                action_date: fetchData?.action_date,
                                action_id: edit_action_item_id,
                                action_item: edit_action_item_description,
                                action_title: edit_action_item_title,
                                lesson_id: fetchData?.lessons_id,
                                status: edit_action_item_status,
                              })
                            )?.json;

                            showAlertUtil({
                              title: undefined,
                              message: 'You successfully updated this Action.',
                              buttonText: 'Okay',
                            });

                            setEdit_action_toggle(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                    {/* Cancel */}
                    <Button
                      onPress={() => {
                        try {
                          setEdit_action_toggle(false);
                          setEdit_action_item_id('');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { marginTop: 15 }
                        ),
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                );
              }}
            </XanoAPIApi.FetchSingleActionGET>
          </View>
        </BlurView>
      </Modal>
    </ScreenContainer>
  );
};

export default withTheme(MyTasksScreen);
