import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import NavBarStudentBlock from '../components/NavBarStudentBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import beautifyDate from '../global-functions/beautifyDate';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  Button,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, RefreshControl, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const LessonsScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [refreshingVagkxgVK, setRefreshingVagkxgVK] = React.useState(false);
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'lessons',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Background'] },
        dimensions.width
      )}
    >
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        refreshColor={theme.colors['Primary']}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        <XanoAPIApi.FetchGetLessonsGET UUID={Constants['UUID']} numLessons={0}>
          {({ loading, error, data, refetchGetLessons }) => {
            const fetchData = data?.json;
            if (loading) {
              return <ActivityIndicator />;
            }

            if (error || data?.status < 200 || data?.status >= 300) {
              return <ActivityIndicator />;
            }

            return (
              <>
                <>
                  {!(fetchData?.lessons?.length > 0) ? null : (
                    <SimpleStyleFlatList
                      data={fetchData?.lessons}
                      horizontal={false}
                      inverted={false}
                      keyExtractor={(listData, index) => listData?.id}
                      keyboardShouldPersistTaps={'never'}
                      listKey={'VagkxgVK'}
                      nestedScrollEnabled={false}
                      numColumns={1}
                      onEndReachedThreshold={0.5}
                      refreshControl={
                        <RefreshControl
                          refreshing={refreshingVagkxgVK}
                          onRefresh={() => {
                            const handler = async () => {
                              try {
                                setRefreshingVagkxgVK(true);
                                await refetchGetLessons();
                                setRefreshingVagkxgVK(false);
                              } catch (err) {
                                console.error(err);
                                setRefreshingVagkxgVK(false);
                              }
                            };
                            handler();
                          }}
                          colors={[theme.colors['Primary']]}
                          tintColor={theme.colors['Primary']}
                        />
                      }
                      renderItem={({ item, index }) => {
                        const listData = item;
                        return (
                          <>
                            {/* Card */}
                            <View
                              collapsable={false}
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  backgroundColor:
                                    theme.colors['Custom #ffffff'],
                                  borderColor: theme.colors['Light'],
                                  borderRadius: 4,
                                  borderWidth: 0,
                                  flexDirection: 'row',
                                  justifyContent: 'space-between',
                                  marginLeft: 20,
                                  marginRight: 20,
                                  marginTop: 10,
                                  minHeight: 70,
                                  paddingBottom: 10,
                                  paddingLeft: 20,
                                  paddingRight: 15,
                                  paddingTop: 10,
                                },
                                dimensions.width
                              )}
                            >
                              {/* Left */}
                              <View>
                                {/* Title */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        fontFamily: 'System',
                                        fontWeight: '600',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {listData?.lesson_title}
                                </Text>
                                {/* Date */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    dimensions.width
                                  )}
                                >
                                  {'Date: '}
                                  {beautifyDate(listData?.lesson_date)}
                                </Text>
                              </View>
                              {/* Status DONE */}
                              <>
                                {!(listData?.status === 'done') ? null : (
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'flex-end',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-end',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Open */}
                                    <Button
                                      onPress={() => {
                                        const handler = async () => {
                                          try {
                                            navigation.navigate(
                                              'StudentStack',
                                              {
                                                screen: 'LessonsStack',
                                                params: {
                                                  screen: 'LessonDetailsScreen',
                                                  params: {
                                                    lessonID: listData?.id,
                                                  },
                                                },
                                              }
                                            );
                                            (
                                              await xanoAPIInteractionTrackingPOST.mutateAsync(
                                                {
                                                  UUID: Constants['UUID'],
                                                  action:
                                                    'Lesson Opened: ' +
                                                    listData?.id,
                                                }
                                              )
                                            )?.json;
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        };
                                        handler();
                                      }}
                                      {...GlobalStyles.ButtonStyles(theme)[
                                        'Button'
                                      ].props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.ButtonStyles(theme)[
                                            'Button'
                                          ].style,
                                          {
                                            backgroundColor:
                                              theme.colors['Strong'],
                                            borderRadius: 4,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                      title={'Open'}
                                    />
                                  </View>
                                )}
                              </>
                              {/* Status OTHER */}
                              <>
                                {!(listData?.status !== 'done') ? null : (
                                  <View>
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { fontFamily: 'Roboto_500Medium' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.status?.toUpperCase()}
                                    </Text>
                                  </View>
                                )}
                              </>
                            </View>
                          </>
                        );
                      }}
                      showsHorizontalScrollIndicator={false}
                      showsVerticalScrollIndicator={false}
                      style={StyleSheet.applyWidth(
                        { paddingTop: 15 },
                        dimensions.width
                      )}
                    />
                  )}
                </>
                {/* Error */}
                <>
                  {!(fetchData?.lessons?.length === 0) ? null : (
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        dimensions.width
                      )}
                    >
                      {
                        "It looks like you haven't had any lessons yet. Please record or upload a lesson to get started."
                      }
                    </Text>
                  )}
                </>
              </>
            );
          }}
        </XanoAPIApi.FetchGetLessonsGET>
      </SimpleStyleScrollView>
      <NavBarStudentBlock />
    </ScreenContainer>
  );
};

export default withTheme(LessonsScreen);
