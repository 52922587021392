import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import NavBarStudentBlock from '../components/NavBarStudentBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AccordionGroup,
  Button,
  DatePicker,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { ActivityIndicator, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const EditProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [accountType, setAccountType] = React.useState('');
  const [dob, setDob] = React.useState(new Date());
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const xanoAPIUpdateAvatarPUT = XanoAPIApi.useUpdateAvatarPUT();
  const xanoAPIUpdateUserPUT = XanoAPIApi.useUpdateUserPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'edit profile',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      scrollable={false}
      hasSafeArea={true}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth({ paddingTop: 20 }, dimensions.width)}
    >
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { flex: 1, paddingBottom: 80, paddingLeft: 20, paddingRight: 20 },
          dimensions.width
        )}
      >
        {/* Editable */}
        <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
          {/* Fetch Editable */}
          <XanoAPIApi.FetchMeGET
            handlers={{
              onData: fetchEditableData => {
                try {
                  setDob(fetchEditableData?.dob);
                  setName(fetchEditableData?.name);
                  setEmail(fetchEditableData?.email);
                  setAccountType(fetchEditableData?.account_type);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
          >
            {({ loading, error, data, refetchMe }) => {
              const fetchEditableData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Top */}
                  <View>
                    {/* Account Type */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          fontSize: 12,
                          marginLeft: 10,
                          textAlign: 'right',
                        },
                        dimensions.width
                      )}
                    >
                      {'Account Type: '}
                      {Constants['accountType']}
                    </Text>
                    {/* UUID */}
                    <Text
                      accessible={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors.strong,
                          fontFamily: 'Inter_500Medium',
                          fontSize: 12,
                          marginLeft: 10,
                          textAlign: 'right',
                        },
                        dimensions.width
                      )}
                    >
                      {'UUID: '}
                      {Constants['UUID']}
                    </Text>
                    {/* Profile Pic */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 20 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center' },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={{ uri: `${fetchEditableData?.avatar?.url}` }}
                          style={StyleSheet.applyWidth(
                            {
                              borderRadius: 55,
                              height: 110,
                              position: 'absolute',
                              top: 5,
                              width: 110,
                            },
                            dimensions.width
                          )}
                        />
                        <Touchable
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const avatar = await openImagePickerUtil({
                                  mediaTypes: 'Images',
                                  allowsEditing: false,
                                  quality: 0.6,
                                  allowsMultipleSelection: false,
                                  permissionErrorMessage:
                                    'Sorry, we need media library permissions to make this work.',
                                  showAlertOnPermissionError: true,
                                });

                                (
                                  await xanoAPIUpdateAvatarPUT.mutateAsync({
                                    UUID: Constants['UUID'],
                                    avatar: avatar,
                                  })
                                )?.json;
                                await refetchMe();
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          activeOpacity={0.8}
                          disabledOpacity={0.8}
                        >
                          <Image
                            resizeMode={'cover'}
                            source={Images.EditProfile}
                            style={StyleSheet.applyWidth(
                              { height: 137, width: 120 },
                              dimensions.width
                            )}
                          />
                        </Touchable>
                      </View>
                    </View>
                  </View>
                  {/* Edit Details */}
                  <AccordionGroup
                    caretSize={24}
                    expanded={false}
                    iconSize={24}
                    {...GlobalStyles.AccordionGroupStyles(theme)['Accordion']
                      .props}
                    closedColor={theme.colors['Strong']}
                    label={'Edit Details'}
                    openColor={theme.colors['Primary']}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.AccordionGroupStyles(theme)['Accordion']
                          .style,
                        { marginTop: 20, paddingLeft: null, paddingRight: null }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Full name */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 10 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Inter_400Regular',
                            opacity: 0.85,
                          },
                          dimensions.width
                        )}
                      >
                        {'Full Name'}
                      </Text>
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newTextInputValue => {
                          try {
                            setName(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Light'],
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            fontFamily: 'Inter_400Regular',
                            height: 48,
                            marginTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        value={name}
                      />
                    </View>
                    {/* Email */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 20 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Inter_400Regular',
                            opacity: 0.85,
                          },
                          dimensions.width
                        )}
                      >
                        {'Email address'}
                      </Text>
                      <TextInput
                        autoCapitalize={'none'}
                        autoCorrect={true}
                        changeTextDelay={500}
                        onChangeText={newTextInputValue => {
                          try {
                            setEmail(newTextInputValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        placeholder={'Enter a value...'}
                        webShowOutline={true}
                        disabled={true}
                        editable={false}
                        style={StyleSheet.applyWidth(
                          {
                            borderBottomWidth: 1,
                            borderColor: theme.colors['Light'],
                            borderLeftWidth: 1,
                            borderRadius: 8,
                            borderRightWidth: 1,
                            borderTopWidth: 1,
                            fontFamily: 'Inter_400Regular',
                            height: 48,
                            marginTop: 8,
                            paddingBottom: 8,
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 8,
                          },
                          dimensions.width
                        )}
                        value={email}
                      />
                    </View>
                    {/* Birth Date */}
                    <View
                      style={StyleSheet.applyWidth(
                        { marginTop: 20 },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors.strong,
                            fontFamily: 'Inter_400Regular',
                            opacity: 0.85,
                          },
                          dimensions.width
                        )}
                      >
                        {'Birth Date'}
                      </Text>
                      <DatePicker
                        autoDismissKeyboard={true}
                        disabled={false}
                        hideLabel={false}
                        leftIconMode={'inset'}
                        mode={'date'}
                        onDateChange={newDatePickerValue => {
                          try {
                            setDob(newDatePickerValue);
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        type={'solid'}
                        date={dob}
                        format={'dd-mm-yyyy'}
                        label={' '}
                        rightIconName={'Feather/calendar'}
                        style={StyleSheet.applyWidth(
                          { marginTop: 4 },
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Update */}
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await xanoAPIUpdateUserPUT.mutateAsync({
                                UUID: Constants['UUID'],
                                dob: dob,
                                name: name,
                              })
                            )?.json;
                            await refetchMe();

                            showAlertUtil({
                              title: 'Success',
                              message: 'Your details have been updated.',
                              buttonText: 'Done',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      activeOpacity={0.8}
                      disabledOpacity={0.8}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors.primary,
                          borderRadius: 12,
                          fontFamily: 'System',
                          fontWeight: '700',
                          height: 52,
                          marginTop: 20,
                          textAlign: 'center',
                        },
                        dimensions.width
                      )}
                      title={'Update '}
                    />
                  </AccordionGroup>
                  {/* QR */}
                  <>
                    {!(accountType === 'Student') ? null : (
                      <AccordionGroup
                        caretSize={24}
                        expanded={false}
                        iconSize={24}
                        {...GlobalStyles.AccordionGroupStyles(theme)[
                          'Accordion'
                        ].props}
                        closedColor={theme.colors['Strong']}
                        label={'Share Profile (QR)'}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.AccordionGroupStyles(theme)[
                              'Accordion'
                            ].style,
                            {
                              marginTop: 10,
                              paddingLeft: null,
                              paddingRight: null,
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {/* QR */}
                        <View>
                          {/* Fetch QR */}
                          <>
                            {!(accountType === 'Student') ? null : (
                              <XanoAPIApi.FetchGetQRGET
                                UUID={Constants['UUID']}
                              >
                                {({ loading, error, data, refetchGetQR }) => {
                                  const fetchQRData = data?.json;
                                  if (loading) {
                                    return <ActivityIndicator />;
                                  }

                                  if (
                                    error ||
                                    data?.status < 200 ||
                                    data?.status >= 300
                                  ) {
                                    return <ActivityIndicator />;
                                  }

                                  return (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignSelf: 'center',
                                          marginBottom: 20,
                                          marginTop: 20,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* QR */}
                                      <Image
                                        resizeMode={'cover'}
                                        {...GlobalStyles.ImageStyles(theme)[
                                          'Image'
                                        ].props}
                                        source={{
                                          uri: `${fetchEditableData?.qr?.url}`,
                                        }}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.ImageStyles(theme)[
                                              'Image'
                                            ].style,
                                            {
                                              borderRadius: 5,
                                              height: 250,
                                              width: 250,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      />
                                    </View>
                                  );
                                }}
                              </XanoAPIApi.FetchGetQRGET>
                            )}
                          </>
                        </View>
                      </AccordionGroup>
                    )}
                  </>
                </>
              );
            }}
          </XanoAPIApi.FetchMeGET>
        </View>
        {/* Signout */}
        <View>
          {/* Signout */}
          <Button
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'name',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'UUID',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'email',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'authToken',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'accountType',
                  value: '',
                });
                setGlobalVariableValue({
                  key: 'newestLessonID',
                  value: '',
                });
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('UserManagement', {
                  screen: 'LoginAndSignupScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            activeOpacity={0.8}
            disabledOpacity={0.8}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors.primary,
                borderRadius: 12,
                fontFamily: 'System',
                fontWeight: '700',
                height: 52,
                marginBottom: 20,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'Signout'}
          />
        </View>
        <NavBarStudentBlock />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(EditProfileScreen);
