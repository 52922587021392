import React from 'react';

// Control the visibility of the Evita animation in the header
const headerElementsEvita = Variables => {
  const activePage = Variables.activePage;
  const pageNames = [
    'Dashboard (Student)',
    'Dashboard (Teacher)',
    'Record',
    'Uploader',
    'chat',
  ]; // Pages to exclude Evita animation from

  // Function to check if activePage matches any page in the list
  function isActivePageValid() {
    // Check if the activePage is in the pageNames list
    if (pageNames.includes(activePage)) {
      return true;
    } else {
      return false;
    }
  }

  // Call the function to perform the validation and return the result
  return isActivePageValid();
};

export default headerElementsEvita;
