import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { HStack, IconButton, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Image, Text, View } from 'react-native';

const NavBarStudentBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const audioPlayerUrl = async url => {
    setPlayerURL(url);
    return;
  };

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['White'],
          bottom: 0,
          height: 60,
          left: 0,
          paddingLeft: 20,
          paddingRight: 20,
          position: 'absolute',
          width: '100%',
          zIndex: 999,
        },
        dimensions.width
      )}
    >
      <HStack
        {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.HStackStyles(theme)['H Stack'].style,
            {
              alignContent: 'center',
              alignSelf: 'center',
              flex: 1,
              gap: 10,
              justifyContent: 'space-between',
            }
          ),
          dimensions.width
        )}
      >
        {/* L1 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', width: 60 },
            dimensions.width
          )}
        >
          <IconButton
            onPress={() => {
              try {
                if (navigation.canGoBack()) {
                  navigation.popToTop();
                }
                navigation.replace('StudentStack', {
                  screen: 'DashboardStudentScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors['Strong']}
            icon={'MaterialCommunityIcons/view-dashboard'}
            size={28}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Tab Nav'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Tab Nav'].style,
              dimensions.width
            )}
          >
            {'Dashboard'}
          </Text>
        </View>
        {/* L2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', width: 60 },
            dimensions.width
          )}
        >
          <IconButton
            onPress={() => {
              try {
                navigation.navigate('StudentStack', {
                  screen: 'LessonsStack',
                  params: { screen: 'LessonsScreen' },
                });
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors['Strong']}
            icon={'MaterialCommunityIcons/cards'}
            size={28}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Tab Nav'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Tab Nav'].style,
              dimensions.width
            )}
          >
            {'Lessons'}
          </Text>
        </View>
        {/* Mid */}
        <View>
          <Image
            resizeMode={'cover'}
            {...GlobalStyles.ImageStyles(theme)['Image'].props}
            source={Images.EvitaGifSmall}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.ImageStyles(theme)['Image'].style,
                {
                  borderColor: theme.colors['HM2'],
                  borderRadius: 100,
                  borderWidth: 6,
                  height: 75,
                  marginTop: -50,
                  width: 75,
                }
              ),
              dimensions.width
            )}
          />
        </View>
        {/* R2 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', width: 60 },
            dimensions.width
          )}
        >
          <IconButton
            onPress={() => {
              try {
                navigation.navigate('StudentStack', {
                  screen: 'UploaderScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors['Strong']}
            icon={'AntDesign/cloudupload'}
            size={28}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Tab Nav'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Tab Nav'].style,
              dimensions.width
            )}
          >
            {'Upload'}
          </Text>
        </View>
        {/* R1 */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', width: 60 },
            dimensions.width
          )}
        >
          <IconButton
            onPress={() => {
              try {
                navigation.navigate('UserManagement', {
                  screen: 'GetHelpScreen',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            color={theme.colors['Strong']}
            icon={'MaterialCommunityIcons/help-box'}
            size={30}
          />
          <Text
            accessible={true}
            {...GlobalStyles.TextStyles(theme)['Tab Nav'].props}
            style={StyleSheet.applyWidth(
              GlobalStyles.TextStyles(theme)['Tab Nav'].style,
              dimensions.width
            )}
          >
            {'Help'}
          </Text>
        </View>
      </HStack>
    </View>
  );
};

export default withTheme(NavBarStudentBlock);
