import { I18nManager, Platform, StyleSheet, Text, View } from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import React from 'react';
import * as XanoAPIApi from './apis/XanoAPIApi.js';
import * as GlobalVariables from './config/GlobalVariableContext';
import clearUser from './global-functions/clearUser';
import deviceCheck from './global-functions/deviceCheck';
import sleep from './global-functions/sleep';
import Breakpoints from './utils/Breakpoints';
import showAlertUtil from './utils/showAlert';
import useWindowDimensions from './utils/useWindowDimensions';

import DashboardStudentScreen from './screens/DashboardStudentScreen';
import DashboardTeacherScreen from './screens/DashboardTeacherScreen';
import DemoLessonScreen from './screens/DemoLessonScreen';
import EditProfileScreen from './screens/EditProfileScreen';
import EnrolStudentScreen from './screens/EnrolStudentScreen';
import GetHelpScreen from './screens/GetHelpScreen';
import LessonDetailsScreen from './screens/LessonDetailsScreen';
import LessonsScreen from './screens/LessonsScreen';
import LoadingScreen from './screens/LoadingScreen';
import LoginAndSignupScreen from './screens/LoginAndSignupScreen';
import MyTasksScreen from './screens/MyTasksScreen';
import OnboardingScreen from './screens/OnboardingScreen';
import RecordScreen from './screens/RecordScreen';
import StudentLessonDetailsScreen from './screens/StudentLessonDetailsScreen';
import StudentLessonsScreen from './screens/StudentLessonsScreen';
import StudentsScreen from './screens/StudentsScreen';
import UploaderScreen from './screens/UploaderScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

function DefaultAndroidBackIcon({ tintColor }) {
  return (
    <View style={[styles.headerContainer, styles.headerContainerLeft]}>
      <Icon
        name="AntDesign/arrowleft"
        size={24}
        color={tintColor}
        style={[styles.headerIcon, styles.headerIconLeft]}
      />
    </View>
  );
}

function AdminTestingStack() {
  /* Navigator has no children, add a child screen or navigator to have it rendered here */
}

function LessonsStack() {
  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      initialRouteName="LessonsScreen"
      presentation="card"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor, canGoBack }) =>
          canGoBack ? (
            <View style={[styles.headerContainer, styles.headerContainerLeft]}>
              <Icon
                name="AntDesign/arrowleft"
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['Primary']}
                style={[styles.headerIcon, styles.headerIconLeft]}
              />
            </View>
          ) : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="LessonsScreen"
        component={LessonsScreen}
        options={({ navigation }) => ({
          title: 'Lessons',
        })}
      />
      <Stack.Screen
        name="LessonDetailsScreen"
        component={LessonDetailsScreen}
        options={({ navigation }) => ({
          title: 'Lesson Details',
        })}
      />
      <Stack.Screen
        name="MyTasksScreen"
        component={MyTasksScreen}
        options={({ navigation }) => ({
          title: 'My Tasks',
        })}
      />
    </Stack.Navigator>
  );
}

function StudentStack() {
  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      initialRouteName="DashboardStudentScreen"
      presentation="card"
      screenOptions={({ navigation }) => ({
        headerLeft: ({ tintColor, canGoBack }) =>
          canGoBack ? (
            <View style={[styles.headerContainer, styles.headerContainerLeft]}>
              <Icon
                name="AntDesign/arrowleft"
                size={Platform.OS === 'ios' ? 21 : 24}
                color={theme.colors['Primary']}
                style={[styles.headerIcon, styles.headerIconLeft]}
              />
            </View>
          ) : null,
        headerRight: ({ tintColor }) => (
          <View style={[styles.headerContainer, styles.headerContainerRight]}>
            <Icon
              name="Feather/menu"
              size={Platform.OS === 'ios' ? 21 : 24}
              color={tintColor}
              style={[styles.headerIcon, styles.headerIconRight]}
            />
          </View>
        ),
        headerShown: false,
        headerTitle: ' ',
        headerTitleAlign: 'center',
      })}
    >
      <Stack.Screen
        name="RecordScreen"
        component={RecordScreen}
        options={({ navigation }) => ({
          title: 'Record',
        })}
      />
      <Stack.Screen
        name="DashboardStudentScreen"
        component={DashboardStudentScreen}
        options={({ navigation }) => ({
          title: 'Dashboard (Student)',
        })}
      />
      <Stack.Screen
        name="UploaderScreen"
        component={UploaderScreen}
        options={({ navigation }) => ({
          title: 'Uploader',
        })}
      />
      <Stack.Screen
        name="LessonsStack"
        component={LessonsStack}
        options={({ navigation }) => ({
          title: 'Lessons Stack',
        })}
      />
    </Stack.Navigator>
  );
}

function StudentLessonsStack() {
  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      screenOptions={({ navigation }) => ({
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="StudentLessonsScreen"
        component={StudentLessonsScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Student Lessons',
        })}
      />
      <Stack.Screen
        name="StudentLessonDetailsScreen"
        component={StudentLessonDetailsScreen}
        options={({ navigation }) => ({
          headerShown: false,
          title: 'Student Lesson Details',
        })}
      />
    </Stack.Navigator>
  );
}

function TeacherStack() {
  const Constants = GlobalVariables.useValues();

  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      initialRouteName="DashboardTeacherScreen"
      presentation="card"
      screenOptions={({ navigation }) => ({
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="DashboardTeacherScreen"
        component={DashboardTeacherScreen}
        options={({ navigation }) => ({
          title: 'Dashboard (Teacher)',
        })}
      />
      <Stack.Screen
        name="EnrolStudentScreen"
        component={EnrolStudentScreen}
        options={({ navigation }) => ({
          title: 'Enrol Student',
        })}
      />
      <Stack.Screen
        name="StudentsScreen"
        component={StudentsScreen}
        options={({ navigation }) => ({
          title: 'Students',
        })}
      />
      <Stack.Screen
        name="DemoLessonScreen"
        component={DemoLessonScreen}
        options={({ navigation }) => ({
          title: 'Demo Lesson',
        })}
      />
      <Stack.Screen
        name="StudentLessonsStack"
        component={StudentLessonsStack}
        options={({ navigation }) => ({
          title: 'Student Lessons Stack',
        })}
      />
    </Stack.Navigator>
  );
}

function UserManagement() {
  const dimensions = useWindowDimensions();

  return (
    <Stack.Navigator
      initialRouteName="LoginAndSignupScreen"
      presentation="modal"
      screenOptions={({ navigation }) => ({
        headerBackImage:
          Platform.OS === 'android' ? DefaultAndroidBackIcon : null,
        headerShown: false,
      })}
    >
      <Stack.Screen
        name="LoginAndSignupScreen"
        component={LoginAndSignupScreen}
        options={({ navigation }) => ({
          title: 'Login & Signup',
        })}
      />
      <Stack.Screen
        name="EditProfileScreen"
        component={EditProfileScreen}
        options={({ navigation }) => ({
          title: 'Edit Profile',
        })}
      />
      <Stack.Screen
        name="GetHelpScreen"
        component={GetHelpScreen}
        options={({ navigation }) => ({
          title: 'Get Help',
        })}
      />
      <Stack.Screen
        name="OnboardingScreen"
        component={OnboardingScreen}
        options={({ navigation }) => ({
          title: 'Onboarding',
        })}
      />
    </Stack.Navigator>
  );
}

export default function RootAppNavigator() {
  const Constants = GlobalVariables.useValues();

  return (
    <NavigationContainer
      theme={{
        ...DefaultTheme,
        colors: {
          ...DefaultTheme.colors,
          background: '#F5F9FF',
        },
      }}
      linking={LinkingConfiguration}
    >
      <Stack.Navigator
        initialRouteName="LoadingScreen"
        presentation="card"
        screenOptions={({ navigation }) => ({
          headerLeft: ({ tintColor, canGoBack }) =>
            canGoBack ? (
              <View
                style={[styles.headerContainer, styles.headerContainerLeft]}
              >
                <Icon
                  name="AntDesign/arrowleft"
                  size={Platform.OS === 'ios' ? 21 : 24}
                  color={theme.colors['Primary']}
                  style={[styles.headerIcon, styles.headerIconLeft]}
                />
              </View>
            ) : null,
          headerShown: false,
        })}
      >
        <Stack.Screen
          name="LoadingScreen"
          component={LoadingScreen}
          options={({ navigation }) => ({
            title: 'Loading',
          })}
        />
        <Stack.Screen name="AdminTestingStack" component={AdminTestingStack} />
        <Stack.Screen name="StudentStack" component={StudentStack} />
        <Stack.Screen name="TeacherStack" component={TeacherStack} />
        <Stack.Screen name="UserManagement" component={UserManagement} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    ...Platform.select({
      ios: null,
      default: {
        marginVertical: 3,
        marginHorizontal: 11,
      },
    }),
  },
  headerContainerLeft: Platform.select({ ios: { marginLeft: 8 } }),
  headerContainerRight: Platform.select({ ios: { marginRight: 8 } }),
  headerIcon: Platform.select({
    ios: {
      marginVertical: 12,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
    default: {
      margin: 3,
      resizeMode: 'contain',
      transform: [{ scaleX: I18nManager.isRTL ? -1 : 1 }],
    },
  }),
  headerIconLeft: Platform.select({ ios: { marginRight: 6 } }),
  headerIconRight: Platform.select({ ios: { marginLeft: 6 } }),
});
