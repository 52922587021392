import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import headerElementsBack from '../global-functions/headerElementsBack';
import headerElementsEvita from '../global-functions/headerElementsEvita';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { HStack, IconButton, Pressable, withTheme } from '@draftbit/ui';
import { useNavigation } from '@react-navigation/native';
import { Image, View } from 'react-native';

const HeaderBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const navigation = useNavigation();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  return (
    <View
      style={StyleSheet.applyWidth(
        {
          backgroundColor: theme.colors['White'],
          height: 75,
          marginBottom: 20,
          paddingTop: 20,
          zIndex: 99999,
        },
        dimensions.width
      )}
    >
      <HStack
        {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(
            GlobalStyles.HStackStyles(theme)['H Stack'].style,
            { height: '100%', paddingLeft: 20, paddingRight: 20 }
          ),
          dimensions.width
        )}
      >
        {/* Left */}
        <>
          {!!headerElementsBack(Variables) ? null : (
            <View style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}>
              {/* Back */}
              <IconButton
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                color={theme.colors['Strong']}
                icon={'AntDesign/arrowleft'}
                size={25}
              />
            </View>
          )}
        </>
        {/* Right */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flex: 2,
              flexDirection: 'row',
              justifyContent: 'flex-end',
            },
            dimensions.width
          )}
        >
          {/* Evita */}
          <>
            {!!headerElementsEvita(Variables) ? null : (
              <Pressable
                style={StyleSheet.applyWidth(
                  { marginTop: -5 },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  {...GlobalStyles.ImageStyles(theme)['Image'].props}
                  source={Images.EvitaAnimationOpt}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'].style,
                      { height: 40, width: 50 }
                    ),
                    dimensions.width
                  )}
                />
              </Pressable>
            )}
          </>
        </View>
      </HStack>
    </View>
  );
};

export default withTheme(HeaderBlock);
