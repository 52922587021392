import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import * as AV from '../custom-files/AV';
import * as typewriter_general from '../custom-files/typewriter_general';
import * as Utils from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import selectFileUtil from '../utils/selectFile';
import showAlertUtil from '../utils/showAlert';
import useIsOnline from '../utils/useIsOnline';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  Button,
  DatePicker,
  Icon,
  Pressable,
  ScreenContainer,
  TextField,
  withTheme,
} from '@draftbit/ui';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useIsFocused } from '@react-navigation/native';
import { Platform, Text, View } from 'react-native';

const UploaderScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [datePickerValue, setDatePickerValue] = React.useState('');
  const [dropdownToggle, setDropdownToggle] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [executionStatus, setExecutionStatus] = React.useState('');
  const [fileLength, setFileLength] = React.useState(0);
  const [fileSize, setFileSize] = React.useState(0);
  const [lessonTitle_Input, setLessonTitle_Input] = React.useState('');
  const [mimeType, setMimeType] = React.useState('');
  const [newest_lesson_id, setNewest_lesson_id] = React.useState(0);
  const [notices, setNotices] = React.useState(
    'Please select a file to upload. Only audio files less than 40MB are currently supported.'
  );
  const [recording_uri, setRecording_uri] = React.useState('');
  const [stateStatus, setStateStatus] = React.useState('ready');
  const [teacherName_Input, setTeacherName_Input] = React.useState('');
  const [uploadProgress, setUploadProgress] = React.useState('');
  const [date, setDate] = React.useState(new Date());
  const validateFile = async pickedFile => {
    const ACCEPTED_AUDIO_TYPES = [
      'audio/mpeg',
      'audio/wav',
      'audio/ogg',
      'audio/mp4',
      'audio/x-aiff',
      'audio/x-m4a',
    ];
    const MAX_SIZE_MB = 45;
    const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024;
    const MIN_DURATION_MS = 900000; // 15 minutes in milliseconds

    const base64 = pickedFile.value;

    // Extract MIME type from the base64 string
    const mimeTypeMatch = base64.match(
      /data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,/
    );
    const mimeType = mimeTypeMatch ? mimeTypeMatch[1] : undefined;

    if (!mimeType) {
      return 'Error: Could not extract MIME type';
    }

    console.log('Extracted MIME type:', mimeType); // Debug: Check the extracted MIME type

    if (!ACCEPTED_AUDIO_TYPES.includes(mimeType)) {
      return `Error: Unsupported file type .${mimeType.split('/')[1]}`;
    }

    try {
      const blob = await fetch(base64).then(res => res.blob());
      const fileSizeBytes = blob.size;

      console.log('File size in bytes:', fileSizeBytes); // Debug: Check the file size

      if (fileSizeBytes > MAX_SIZE_BYTES) {
        return `Error: File exceeds maximum size of ${MAX_SIZE_MB}MB`;
      }

      const uri = URL.createObjectURL(blob);
      setRecording_uri(uri);

      if (Platform.OS !== 'web') {
        const { sound } = await AV.Audio.Sound.createAsync({ uri });
        const status = await sound.getStatusAsync();
        await sound.unloadAsync();
        URL.revokeObjectURL(uri); // Clean up the created URL to release memory
        if (status.isLoaded && status.durationMillis >= MIN_DURATION_MS) {
          return 'File accepted';
        } else {
          return `Error: Duration needs to be longer than 15 mins`;
        }
      } else {
        const context = new (window.AudioContext ||
          window.webkitAudioContext)();
        const response = await fetch(uri);
        const arrayBuffer = await response.arrayBuffer();
        return new Promise((resolve, reject) => {
          context.decodeAudioData(
            arrayBuffer,
            audioBuffer => {
              console.log(
                `Duration fetched: ${audioBuffer.duration * 1000} ms`
              );
              context.close(); // Ensure to close the AudioContext to free up resources
              if (audioBuffer.duration * 1000 >= MIN_DURATION_MS) {
                resolve('File accepted');
              } else {
                resolve(`Error: Duration needs to be longer than 15 mins`);
              }
            },
            error => {
              console.error('Error decoding audio data (web):', error);
              context.close(); // Ensure to close the AudioContext to free up resources
              reject(`Error validating file: ${error.message}`);
            }
          );
        });
      }
    } catch (error) {
      console.error('Error validating audio file:', error);
      return `Error validating file: ${error.message}`;
    }
  };

  const formValidation = () => {
    // Check if variables are not null, not undefined, and not empty strings
    const isDatePickerValid =
      datePickerValue !== undefined &&
      datePickerValue !== null &&
      datePickerValue !== '';
    const isRecordingUriValid =
      recording_uri !== undefined &&
      recording_uri !== null &&
      recording_uri !== '';
    const isFileAccepted = notices == 'File accepted';

    if (
      lessonTitle_Input &&
      lessonTitle_Input.length > 0 &&
      teacherName_Input &&
      teacherName_Input.length > 0 &&
      isDatePickerValid &&
      isRecordingUriValid &&
      isFileAccepted
    ) {
      console.log('Inputs are valid!');
      setErrorMessage('');
      return true;
    } else {
      // Log the invalid state for debugging purposes
      console.log('Invalid inputs:', {
        lessonTitle_Input,
        teacherName_Input,
        datePickerValue,
      });

      // Determine the specific error message
      let message = 'Please complete all the fields';
      setErrorMessage(message);
      return false;
    }
  };

  const uploadLesson = async (
    Variables,
    setGlobalVariableValue,
    lesson_title,
    teacher_name,
    lesson_date,
    lesson_uri
  ) => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('lesson_title', lesson_title);
    formData.append('teacher_name', teacher_name);
    formData.append('lesson_date', lesson_date);

    function getExtension(mimeType) {
      // Default to m4a if the MIME type is not recognized
      const extensions = {
        'audio/mpeg': 'mp3',
        'audio/mp4': 'm4a',
        'audio/x-m4a': 'm4a',
        'audio/wav': 'wav',
      };
      return extensions[mimeType] || 'm4a';
    }

    if (Platform.OS === 'web') {
      fetch(lesson_uri)
        .then(response => {
          response.blob().then(blob => {
            const fileType = blob.type;
            const fileExtension = getExtension(fileType);

            formData.append(
              'lesson_recording',
              blob,
              `${lesson_title} - ${lesson_date}.${fileExtension}`
            );

            sendFormData(formData); // Call a function to handle the XHR request
          });
        })
        .catch(error => {
          console.error('Error fetching the blob:', error);
        });
    } else {
      // Extract the file extension from the URI or use MIME type if available
      const fileExtension = lesson_uri.split('.').pop(); // This assumes the URI ends with the file extension
      formData.append('lesson_recording', {
        uri: lesson_uri,
        type: `audio/${fileExtension}`,
        name: `${lesson_title} - ${lesson_date}.${fileExtension}`,
      });
      sendFormData(formData); // Directly call the function to handle the XHR request for native environment
    }

    function sendFormData(formData) {
      const xhr = new XMLHttpRequest();

      xhr.onreadystatechange = function () {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          console.log('Response:', xhr.response);
          console.log('Response Headers:', xhr.getAllResponseHeaders());

          if (xhr.status === 200) {
            const result = JSON.parse(xhr.response);
            console.log('Upload successful:', result);
            setStateStatus('uploaded');

            const lesson_id = result.id;
            console.log('Lesson ID:', lesson_id);
            // Additional logic or state updates can be handled here
          } else {
            console.error('HTTP error status:', xhr.status);
            setStateStatus('upload error');
          }
        }
      };

      xhr.onerror = function () {
        console.error('Error during file upload:', xhr.statusText);
      };

      xhr.open(
        'POST',
        'https://api.heymaestro.me/api:EJWp6KFE/auth-lessons/upload',
        true
      );
      xhr.setRequestHeader('Authorization', authToken);

      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100) + '%';
          console.log('Upload progress:', progress);
          setUploadProgress(progress);
          setStateStatus('uploading');
        }
      };

      xhr.send(formData);
    }
  };
  const isOnline = useIsOnline();
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setStateStatus('ready');
      setGlobalVariableValue({
        key: 'activePage',
        value: 'Uploader',
      });
      setRecording_uri('');
      setDatePickerValue('');
      setTeacherName_Input('');
      setLessonTitle_Input('');
      setExecutionStatus('');
      setUploadProgress('');
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { alignContent: 'flex-start', alignItems: 'stretch', width: '100%' },
        dimensions.width
      )}
    >
      {/* Main */}
      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'stretch', flex: 1, justifyContent: 'space-between' },
          dimensions.width
        )}
      >
        {/* Top Section */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, justifyContent: 'flex-start', paddingBottom: 20 },
            dimensions.width
          )}
        >
          {/* Typewriter */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignSelf: 'center',
                marginTop: 80,
                maxHeight: 200,
                minHeight: 50,
                paddingLeft: 20,
                paddingRight: 20,
              },
              dimensions.width
            )}
          >
            {/* Initial */}
            <>
              {!(stateStatus === 'ready') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={"Let's get your lesson uploaded..."}
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Uploading Container */}
            <>
              {!(stateStatus === 'uploading') ? null : (
                <View>
                  {/* Uploading */}
                  <Utils.CustomCodeErrorBoundary>
                    <typewriter_general.GreetingComponent
                      bottomText={"I'm uploading your lesson now."}
                    />
                  </Utils.CustomCodeErrorBoundary>
                  {/* Upload % */}
                  <Text
                    accessible={true}
                    {...GlobalStyles.TextStyles(theme)['Text'].props}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'].style,
                        {
                          fontFamily: 'Roboto_400Regular',
                          fontSize: 18,
                          marginTop: 10,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Progress: '}
                    {uploadProgress}
                  </Text>
                </View>
              )}
            </>
            {/* Uploaded */}
            <>
              {!(stateStatus === 'uploaded') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={
                      "I've uploaded your lesson. I'll begin analysing it now. Please check back later."
                    }
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
            {/* Error */}
            <>
              {!(stateStatus === 'upload error') ? null : (
                <Utils.CustomCodeErrorBoundary>
                  <typewriter_general.GreetingComponent
                    bottomText={
                      "I'm sorry, something went wrong. Please refresh and try again."
                    }
                  />
                </Utils.CustomCodeErrorBoundary>
              )}
            </>
          </View>
        </View>
        {/* Upload Complete */}
        <>
          {!(stateStatus === 'uploaded') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  alignContent: 'center',
                  alignSelf: 'stretch',
                  justifyContent: 'flex-end',
                  marginBottom: 50,
                  marginTop: 10,
                  position: 'relative',
                },
                dimensions.width
              )}
            >
              {/* Lessons */}
              <Button
                iconPosition={'left'}
                onPress={() => {
                  try {
                    if (navigation.canGoBack()) {
                      navigation.popToTop();
                    }
                    navigation.replace('StudentStack', {
                      screen: 'LessonsStack',
                      params: { screen: 'LessonsScreen' },
                    });
                  } catch (err) {
                    console.error(err);
                  }
                }}
                {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.ButtonStyles(theme)['Button'].style,
                    {
                      backgroundColor: theme.colors['Custom Color_26'],
                      marginLeft: 10,
                      marginRight: 10,
                    }
                  ),
                  dimensions.width
                )}
                title={'Lesson List'}
              />
            </View>
          )}
        </>
        {/* Uploader */}
        <>
          {!(stateStatus !== 'uploaded') ? null : (
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['White'],
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 30,
                  flex: 2,
                  flexDirection: 'column',
                  justifyContent: 'center',
                  paddingBottom: 30,
                  paddingLeft: 20,
                  paddingRight: 20,
                  paddingTop: 30,
                  width: '100%',
                },
                dimensions.width
              )}
            >
              {/* Form */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignContent: 'center',
                    alignItems: 'stretch',
                    alignSelf: 'auto',
                    borderRadius: 10,
                    marginLeft: 5,
                    marginRight: 5,
                    padding: 10,
                    position: 'relative',
                  },
                  dimensions.width
                )}
              >
                {/* Lesson Title Field */}
                <TextField
                  activeBorderColor={theme.colors.primary}
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newLessonTitleFieldValue => {
                    try {
                      setLessonTitle_Input(newLessonTitleFieldValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  underlineColor={theme.colors.light}
                  webShowOutline={true}
                  label={'Name Your Recording:'}
                  placeholder={'My Lesson Recording'}
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                  type={'underline'}
                  value={lessonTitle_Input}
                />
                {/* Lesson Teacher Field */}
                <TextField
                  activeBorderColor={theme.colors.primary}
                  autoCapitalize={'none'}
                  autoCorrect={true}
                  changeTextDelay={500}
                  onChangeText={newLessonTeacherFieldValue => {
                    try {
                      setTeacherName_Input(newLessonTeacherFieldValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  underlineColor={theme.colors.light}
                  webShowOutline={true}
                  label={'Teacher Name:'}
                  placeholder={'Awesome Teacher'}
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                  type={'underline'}
                  value={teacherName_Input}
                />
                <DatePicker
                  autoDismissKeyboard={true}
                  disabled={false}
                  hideLabel={false}
                  leftIconMode={'inset'}
                  mode={'date'}
                  onDateChange={newDatePickerValue => {
                    const date = newDatePickerValue;
                    try {
                      setDatePickerValue(newDatePickerValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  date={datePickerValue}
                  label={'Lesson Date'}
                  style={StyleSheet.applyWidth(
                    { marginBottom: 20 },
                    dimensions.width
                  )}
                  type={'underline'}
                />
                {/* Pressable File Picker */}
                <Pressable
                  onPress={() => {
                    const handler = async () => {
                      try {
                        const pickedFile = await selectFileUtil({
                          returnNameAndValue: true,
                        });
                        setNotices('Loading file...');
                        const validateFileResponse = await validateFile(
                          pickedFile
                        );
                        (
                          await xanoAPIInteractionTrackingPOST.mutateAsync({
                            UUID: Constants['UUID'],
                            action: 'Picked File for Upload',
                          })
                        )?.json;
                        setNotices(validateFileResponse);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  disabled={notices === 'File accepted'}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: theme.colors['Custom Color_25'],
                        borderBottomLeftRadius: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: notices === 'File accepted' ? 0 : 10,
                          },
                        ],
                        borderBottomRightRadius: [
                          { minWidth: Breakpoints.Mobile, value: 10 },
                          {
                            minWidth: Breakpoints.Mobile,
                            value: notices === 'File accepted' ? 0 : 10,
                          },
                        ],
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        height: 90,
                        justifyContent: 'center',
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Good */}
                    <>
                      {!!notices.includes('Error') ? null : (
                        <Icon
                          size={24}
                          color={
                            notices === 'File accepted'
                              ? theme.colors['App Green']
                              : theme.colors['Medium']
                          }
                          name={'AntDesign/clouduploado'}
                          style={StyleSheet.applyWidth(
                            { marginBottom: 5 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    {/* Error */}
                    <>
                      {!notices.includes('Error') ? null : (
                        <Icon
                          size={24}
                          color={theme.colors['Error']}
                          name={'MaterialIcons/error-outline'}
                          style={StyleSheet.applyWidth(
                            { marginBottom: 5 },
                            dimensions.width
                          )}
                        />
                      )}
                    </>
                    <Text
                      accessible={true}
                      {...GlobalStyles.TextStyles(theme)['Text'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'].style,
                          {
                            marginLeft: 10,
                            marginRight: 10,
                            textAlign: 'center',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {notices}
                    </Text>
                  </View>
                </Pressable>
                <>
                  {!(notices === 'File accepted') ? null : (
                    <AudioPlayer
                      hideDuration={false}
                      hidePlaybackIcon={false}
                      hideSlider={false}
                      interruptionMode={'lower volume'}
                      mode={'interface'}
                      playThroughEarpieceAndroid={false}
                      playsInBackground={false}
                      playsInSilentModeIOS={false}
                      thumbColor={theme.colors.primary}
                      togglePlaybackIconColor={theme.colors.primary}
                      togglePlaybackIconSize={24}
                      {...GlobalStyles.AudioPlayerStyles(theme)['Audio Player']
                        .props}
                      completedTrackColor={theme.colors['Primary']}
                      remainingTrackColor={theme.colors['Medium']}
                      source={{ uri: `${recording_uri}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.AudioPlayerStyles(theme)['Audio Player']
                            .style,
                          {
                            backgroundColor: theme.colors['Custom Color_25'],
                            borderBottomLeftRadius: 8,
                            borderBottomRightRadius: 8,
                            borderRadius: null,
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderWidth: 0,
                            padding: 15,
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  )}
                </>
                {/* Error */}
                <Text
                  accessible={true}
                  {...GlobalStyles.TextStyles(theme)['Text'].props}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextStyles(theme)['Text'].style,
                      {
                        color: theme.colors['Error'],
                        fontSize: 10,
                        textAlign: 'center',
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {errorMessage}
                </Text>
              </View>
              {/* Buttons */}
              <>
                {!(uploadProgress === '') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignContent: 'center',
                        alignSelf: 'stretch',
                        justifyContent: 'flex-end',
                        marginTop: 10,
                        position: 'relative',
                      },
                      dimensions.width
                    )}
                  >
                    {/* Upload */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const formValidation_Response = formValidation();
                            if (!formValidation_Response) {
                              return;
                            }
                            const lessonUpload_Response = await uploadLesson(
                              Variables,
                              setGlobalVariableValue,
                              lessonTitle_Input,
                              teacherName_Input,
                              datePickerValue.toISOString(),
                              recording_uri
                            );
                            setGlobalVariableValue({
                              key: 'newestLessonID',
                              value: lessonUpload_Response,
                            });
                            (
                              await xanoAPIInteractionTrackingPOST.mutateAsync({
                                UUID: Constants['UUID'],
                                action: 'Upload Lesson',
                              })
                            )?.json;
                            /* hidden 'Show Alert' action */
                            /* hidden 'Navigate' action */
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      disabled={notices !== 'File accepted'}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: theme.colors['Custom Color_26'],
                            marginLeft: 10,
                            marginRight: 10,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Upload'}
                    />
                    {/* Cancel Upload */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await xanoAPIInteractionTrackingPOST.mutateAsync({
                                UUID: Constants['UUID'],
                                action: 'Clicked to Cancel Upload',
                              })
                            )?.json;
                            if (navigation.canGoBack()) {
                              navigation.popToTop();
                            }
                            navigation.replace('StudentStack', {
                              screen: 'DashboardStudentScreen',
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          {
                            backgroundColor: theme.colors['Custom Color_26'],
                            marginLeft: 10,
                            marginRight: 10,
                            marginTop: 10,
                          }
                        ),
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                )}
              </>
            </View>
          )}
        </>
      </View>
    </ScreenContainer>
  );
};

export default withTheme(UploaderScreen);
