import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import feedbackPromptCheck from '../global-functions/feedbackPromptCheck';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  Button,
  HStack,
  IconButton,
  Link,
  Picker,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  SwipeableItem,
  SwipeableItemButton,
  TextField,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { BlurView } from 'expo-blur';
import {
  ActivityIndicator,
  Modal,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const LessonDetailsScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activeSnippet, setActiveSnippet] = React.useState(0);
  const [edit_action_item_description, setEdit_action_item_description] =
    React.useState('');
  const [edit_action_item_id, setEdit_action_item_id] = React.useState(null);
  const [edit_action_item_status, setEdit_action_item_status] =
    React.useState('');
  const [edit_action_item_title, setEdit_action_item_title] =
    React.useState('');
  const [edit_action_toggle, setEdit_action_toggle] = React.useState(false);
  const [feedbackPrompt, setFeedbackPrompt] = React.useState(false);
  const [feedbackPromptManualClose, setFeedbackPromptManualClose] =
    React.useState(false);
  const [lessonRecording_URL, setLessonRecording_URL] = React.useState('');
  const [summary_button, setSummary_button] = React.useState('Show More');
  const [summary_readmore, setSummary_readmore] = React.useState(false);
  const [tabsToggle, setTabsToggle] = React.useState('snippets');
  const [teacherNotes, setTeacherNotes] = React.useState('');
  const [refreshingEroZjLQx, setRefreshingEroZjLQx] = React.useState(false);
  const [refreshing1hyWEeRj, setRefreshing1hyWEeRj] = React.useState(false);
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const xanoAPIDeleteActionDELETE = XanoAPIApi.useDeleteActionDELETE();
  const xanoAPIActionStatusPUT = XanoAPIApi.useActionStatusPUT();
  const xanoAPIEditActionPUT = XanoAPIApi.useEditActionPUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'lesson details',
      });
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const audioPlayerbeipWiWuRef = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { flex: 1, maxHeight: dimensions.height },
        dimensions.width
      )}
    >
      <HeaderBlock />
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        refreshColor={theme.colors['Primary']}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        style={StyleSheet.applyWidth(
          { flex: 1, paddingBottom: 80 },
          dimensions.width
        )}
      >
        {/* Main */}
        <View style={StyleSheet.applyWidth({ marginTop: 0 }, dimensions.width)}>
          {/* Fetch Lesson Single */}
          <XanoAPIApi.FetchGetSingleLessonGET
            UUID={Constants['UUID']}
            handlers={{
              onData: fetchLessonSingleData => {
                try {
                  setLessonRecording_URL(
                    fetchLessonSingleData?.lesson_recording?.url
                  );
                  if (feedbackPromptManualClose === parseBoolean(true)) {
                    return;
                  }
                  setFeedbackPrompt(
                    feedbackPromptCheck(
                      fetchLessonSingleData?.created_at,
                      parseBoolean(fetchLessonSingleData?.Feedback_Given)
                    )
                  );
                  setTeacherNotes(fetchLessonSingleData?.teacher_notes);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            lesson_id={props.route?.params?.lessonID ?? 67}
          >
            {({ loading, error, data, refetchGetSingleLesson }) => {
              const fetchLessonSingleData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Summary */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        justifyContent: 'space-between',
                        marginTop: 0,
                        paddingBottom: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Lesson Title */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          marginBottom: 20,
                          marginLeft: 20,
                          marginRight: 20,
                          marginTop: 10,
                        },
                        dimensions.width
                      )}
                    >
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontSize: 20,
                              textAlign: 'center',
                              textTransform: 'capitalize',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {fetchLessonSingleData?.lesson_title}
                      </Text>
                    </View>
                    {/* Summary */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          borderRadius: 4,
                          marginLeft: 20,
                          marginRight: 20,
                          paddingBottom: 20,
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Heading */}
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              fontFamily: 'System',
                              fontSize: 16,
                              fontWeight: '700',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Summary'}
                      </Text>
                      {/* Preview Text */}
                      <>
                        {summary_readmore ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            ellipsizeMode={'tail'}
                            numberOfLines={5}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  paddingTop: 10,
                                  textAlign: 'justify',
                                  whiteSpace: 'pre-line',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchLessonSingleData?.lesson_summary}
                          </Text>
                        )}
                      </>
                      {/* Full Text */}
                      <>
                        {!summary_readmore ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            ellipsizeMode={'tail'}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontSize: 14,
                                  paddingTop: 10,
                                  textAlign: 'justify',
                                  whiteSpace: 'pre-line',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchLessonSingleData?.lesson_summary}
                          </Text>
                        )}
                      </>
                      {/* Summary Toggle Button */}
                      <Link
                        accessible={true}
                        onPress={() => {
                          try {
                            setSummary_readmore(!summary_readmore);
                            if (summary_readmore === true) {
                              setSummary_button('Show More');
                            }
                            if (summary_readmore === false) {
                              setSummary_button('Show Less');
                            }
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.LinkStyles(theme)['Link'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.LinkStyles(theme)['Link'].style,
                            { alignSelf: 'flex-end', marginTop: 10 }
                          ),
                          dimensions.width
                        )}
                        title={`${summary_button}`}
                      />
                    </View>
                  </View>
                  {/* Feedback */}
                  <Modal
                    animationType={'none'}
                    supportedOrientations={['portrait', 'landscape']}
                    transparent={true}
                    visible={feedbackPrompt === true}
                  >
                    <BlurView
                      {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
                      intensity={10}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
                          { alignItems: 'center', justifyContent: 'center' }
                        ),
                        dimensions.width
                      )}
                      tint={'dark'}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            backgroundColor: theme.colors['White'],
                            borderRadius: 10,
                            height: '80%',
                            margin: 30,
                            marginBottom: 40,
                            marginTop: 40,
                            padding: 0,
                            width: '80%',
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignSelf: 'flex-end',
                              paddingRight: 15,
                              paddingTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Close Button */}
                          <IconButton
                            onPress={() => {
                              try {
                                setFeedbackPrompt(false);
                                setFeedbackPromptManualClose(
                                  parseBoolean(true)
                                );
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            color={theme.colors['Medium']}
                            icon={'AntDesign/closecircleo'}
                            size={20}
                          />
                        </View>
                        <WebView
                          allowFileAccessFromFileURLs={false}
                          allowUniversalAccessFromFileURLs={false}
                          cacheEnabled={true}
                          incognito={false}
                          javaScriptCanOpenWindowsAutomatically={false}
                          javaScriptEnabled={true}
                          mediaPlaybackRequiresUserAction={false}
                          startInLoadingState={false}
                          {...GlobalStyles.WebViewStyles(theme)['HTML View']
                            .props}
                          showsHorizontalScrollIndicator={false}
                          showsVerticalScrollIndicator={false}
                          source={{
                            html: `<div 
    style="width:100%; height:100%; overflow:auto; scrollbar-width: none; -ms-overflow-style: none;" 
    data-fillout-id="jwEDPT6Pkwus" 
    data-fillout-embed-type="standard" 
    data-fillout-inherit-parameters 
    data-fillout-dynamic-resize 
    data-UUID=${Constants['UUID']} 
    data-lessonid=${props.route?.params?.lessonID ?? 67}
    onscroll="this.style.overflow='auto'">
</div>
<script src="https://server.fillout.com/embed/v1/"></script>
<style>
    div::-webkit-scrollbar {
        display: none; /* Hide scrollbars for Chrome, Safari, Opera */
    }
</style>
`,
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.WebViewStyles(theme)['HTML View']
                                .style,
                              { borderWidth: 0 }
                            ),
                            dimensions.width
                          )}
                        />
                      </View>
                    </BlurView>
                  </Modal>
                </>
              );
            }}
          </XanoAPIApi.FetchGetSingleLessonGET>
        </View>
        {/* Tabs */}
        <View>
          <HStack
            {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(
                GlobalStyles.HStackStyles(theme)['H Stack'].style,
                { flex: 1, justifyContent: 'space-evenly', marginBottom: 10 }
              ),
              dimensions.width
            )}
          >
            {/* Snippets Toggle */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked Snippets Toggle',
                      })
                    )?.json;
                    setTabsToggle('snippets');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderRadius: 4,
                    minWidth: 100,
                  }
                ),
                dimensions.width
              )}
              title={'Snippets'}
            />
            {/* Actions Toggle */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked Actions Toggle',
                      })
                    )?.json;
                    setTabsToggle('actions');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderRadius: 4,
                    minWidth: 100,
                  }
                ),
                dimensions.width
              )}
              title={'Actions'}
            />
            {/* Teacher Notes */}
            <Button
              iconPosition={'left'}
              onPress={() => {
                const handler = async () => {
                  try {
                    (
                      await xanoAPIInteractionTrackingPOST.mutateAsync({
                        UUID: Constants['UUID'],
                        action: 'Clicked Teacher Notes Toggle',
                      })
                    )?.json;
                    setTabsToggle('notes');
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              {...GlobalStyles.ButtonStyles(theme)['Button'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.ButtonStyles(theme)['Button'].style,
                  {
                    backgroundColor: theme.colors['Strong'],
                    borderRadius: 4,
                    minWidth: 100,
                  }
                ),
                dimensions.width
              )}
              title={'Notes'}
            />
          </HStack>
        </View>
        {/* Snippets */}
        <>
          {!(tabsToggle === 'snippets') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 100 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 16,
                      marginBottom: 5,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Snippets'}
              </Text>
              {/* Fetch_Snippets */}
              <XanoAPIApi.FetchGetSingleLessonSnippetsGET
                UUID={Constants['UUID']}
                lesson_id={props.route?.params?.lessonID ?? 67}
              >
                {({ loading, error, data, refetchGetSingleLessonSnippets }) => {
                  const fetchSnippetsData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Extracts */}
                      <SimpleStyleFlatList
                        data={fetchSnippetsData?.lesson_snippets}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(extractsData, index) => extractsData?.id}
                        keyboardShouldPersistTaps={'never'}
                        listKey={'EroZjLQx'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        refreshControl={
                          <RefreshControl
                            refreshing={refreshingEroZjLQx}
                            onRefresh={() => {
                              const handler = async () => {
                                try {
                                  setRefreshingEroZjLQx(true);
                                  await refetchGetSingleLessonSnippets();
                                  setRefreshingEroZjLQx(false);
                                } catch (err) {
                                  console.error(err);
                                  setRefreshingEroZjLQx(false);
                                }
                              };
                              handler();
                            }}
                            colors={[theme.colors['Primary']]}
                            tintColor={theme.colors['Primary']}
                          />
                        }
                        renderItem={({ item, index }) => {
                          const extractsData = item;
                          return (
                            <>
                              {/* Container */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'stretch',
                                    alignSelf: 'auto',
                                    backgroundColor: theme.colors['White'],
                                    borderRadius: 4,
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    marginBottom: 10,
                                    marginLeft: 20,
                                    marginRight: 20,
                                    marginTop: 10,
                                    padding: 20,
                                    paddingTop: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Left */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 4,
                                      flexDirection: 'column',
                                      flexWrap: 'nowrap',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Snippet Title */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          fontFamily: 'System',
                                          fontSize: 14,
                                          fontWeight: '700',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {extractsData?.title}
                                  </Text>
                                  {/* Snippet Summary */}
                                  <Text
                                    accessible={true}
                                    {...GlobalStyles.TextStyles(theme)['Text']
                                      .props}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)['Text']
                                          .style,
                                        {
                                          paddingTop: 5,
                                          textAlign: 'justify',
                                          whiteSpace: 'pre-line',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {extractsData?.evita_summary}
                                  </Text>
                                </View>
                                {/* Right */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignContent: 'flex-end',
                                      alignItems: 'stretch',
                                      alignSelf: 'center',
                                      flex: 1,
                                      flexDirection: 'column',
                                      flexWrap: 'wrap',
                                      justifyContent: 'center',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <>
                                    {!(
                                      activeSnippet !== extractsData?.id
                                    ) ? null : (
                                      <IconButton
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              const snippet_startTime =
                                                extractsData?.start_time;
                                              audioPlayerbeipWiWuRef.current.seekToPosition(
                                                snippet_startTime
                                              );
                                              audioPlayerbeipWiWuRef.current.play();
                                              (
                                                await xanoAPIInteractionTrackingPOST.mutateAsync(
                                                  {
                                                    UUID: Constants['UUID'],
                                                    action:
                                                      'Lesson: ' +
                                                      ((props.route?.params
                                                        ?.lessonID ?? 67) +
                                                        (' / Snippet Played: ' +
                                                          extractsData?.id)),
                                                  }
                                                )
                                              )?.json;

                                              const valuexE4J21id =
                                                extractsData?.id;
                                              setActiveSnippet(valuexE4J21id);
                                              const snippetCheck =
                                                valuexE4J21id;
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        color={theme.colors['Primary']}
                                        icon={'FontAwesome/play-circle'}
                                        size={30}
                                      />
                                    )}
                                  </>
                                  {/* Icon Button 2 */}
                                  <>
                                    {!(
                                      activeSnippet === extractsData?.id
                                    ) ? null : (
                                      <IconButton
                                        onPress={() => {
                                          const handler = async () => {
                                            try {
                                              audioPlayerbeipWiWuRef.current.pause();
                                              (
                                                await xanoAPIInteractionTrackingPOST.mutateAsync(
                                                  {
                                                    UUID: Constants['UUID'],
                                                    action:
                                                      'Lesson: ' +
                                                      ((props.route?.params
                                                        ?.lessonID ?? 67) +
                                                        (' / Snippet Paused: ' +
                                                          extractsData?.id)),
                                                  }
                                                )
                                              )?.json;
                                              setActiveSnippet(0);
                                            } catch (err) {
                                              console.error(err);
                                            }
                                          };
                                          handler();
                                        }}
                                        color={theme.colors['Error']}
                                        icon={'FontAwesome/stop-circle'}
                                        size={30}
                                      />
                                    )}
                                  </>
                                </View>
                              </View>
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={false}
                        showsVerticalScrollIndicator={false}
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'column' },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
              </XanoAPIApi.FetchGetSingleLessonSnippetsGET>
            </View>
          )}
        </>
        {/* Notes */}
        <>
          {!(tabsToggle === 'notes') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 100 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 16,
                      marginBottom: 5,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Teacher Notes'}
              </Text>
              {/* Container */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'stretch',
                    alignSelf: 'auto',
                    backgroundColor: theme.colors['White'],
                    borderRadius: 4,
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    marginBottom: 10,
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: 10,
                    padding: 20,
                    paddingTop: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Main */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 4, flexDirection: 'column', flexWrap: 'nowrap' },
                    dimensions.width
                  )}
                >
                  {/* Note */}
                  <>
                    {!(teacherNotes?.length > 0) ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              paddingTop: 5,
                              textAlign: 'justify',
                              whiteSpace: 'pre-line',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {teacherNotes}
                      </Text>
                    )}
                  </>
                  {/* Error */}
                  <>
                    {!(teacherNotes?.length === 0) ? null : (
                      <Text
                        accessible={true}
                        {...GlobalStyles.TextStyles(theme)['Text'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'].style,
                            {
                              paddingTop: 5,
                              textAlign: 'justify',
                              whiteSpace: 'pre-line',
                            }
                          ),
                          dimensions.width
                        )}
                      >
                        {'Your teacher has not uploaded any notes yet.'}
                      </Text>
                    )}
                  </>
                </View>
              </View>
            </View>
          )}
        </>
        {/* Actions */}
        <>
          {!(tabsToggle === 'actions') ? null : (
            <View
              style={StyleSheet.applyWidth(
                { paddingBottom: 100 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                accessible={true}
                {...GlobalStyles.TextStyles(theme)['Text'].props}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.TextStyles(theme)['Text'].style,
                    {
                      fontFamily: 'Roboto_700Bold',
                      fontSize: 16,
                      marginBottom: 5,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 20,
                    }
                  ),
                  dimensions.width
                )}
              >
                {'Actions'}
              </Text>
              {/* Fetch_Actions */}
              <XanoAPIApi.FetchGetSingleLessonActionsGET
                UUID={Constants['UUID']}
                filter={'To Do'}
                lesson_id={props.route?.params?.lessonID ?? 67}
              >
                {({ loading, error, data, refetchGetSingleLessonActions }) => {
                  const fetchActionsData = data?.json;
                  if (loading) {
                    return <ActivityIndicator />;
                  }

                  if (error || data?.status < 200 || data?.status >= 300) {
                    return <ActivityIndicator />;
                  }

                  return (
                    <>
                      {/* Catch - No Actions */}
                      <>
                        {!(
                          fetchActionsData?.lesson_actions?.length === 0
                        ) ? null : (
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                { marginLeft: 20, marginRight: 20 }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              "Ooops. We can't find any actions for this lesson. That might be because you've completed them all!"
                            }
                          </Text>
                        )}
                      </>
                      {/* Actions */}
                      <SimpleStyleFlatList
                        data={fetchActionsData?.lesson_actions}
                        horizontal={false}
                        inverted={false}
                        keyExtractor={(actionsData, index) => actionsData?.id}
                        keyboardShouldPersistTaps={'never'}
                        listKey={'1hyWEeRj'}
                        nestedScrollEnabled={false}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        refreshControl={
                          <RefreshControl
                            refreshing={refreshing1hyWEeRj}
                            onRefresh={() => {
                              const handler = async () => {
                                try {
                                  setRefreshing1hyWEeRj(true);
                                  await refetchGetSingleLessonActions();
                                  setRefreshing1hyWEeRj(false);
                                } catch (err) {
                                  console.error(err);
                                  setRefreshing1hyWEeRj(false);
                                }
                              };
                              handler();
                            }}
                            colors={[theme.colors['Primary']]}
                            tintColor={theme.colors['Primary']}
                          />
                        }
                        renderItem={({ item, index }) => {
                          const actionsData = item;
                          return (
                            <SwipeableItem
                              closeOnPress={true}
                              friction={20}
                              swipeActivationPercentage={80}
                              swipeToClosePercent={50}
                              swipeToOpenPercent={50}
                              {...GlobalStyles.SwipeableItemStyles(theme)[
                                'Swipeable Item'
                              ].props}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.SwipeableItemStyles(theme)[
                                    'Swipeable Item'
                                  ].style,
                                  {
                                    marginBottom: 10,
                                    marginTop: 10,
                                    paddingLeft: 20,
                                    paddingRight: 20,
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {/* Swipe Left - Edit */}
                              <SwipeableItemButton
                                closeOnPress={true}
                                onPress={() => {
                                  try {
                                    setEdit_action_item_id(actionsData?.id);
                                    setEdit_action_toggle(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                revealSwipeDirection={'left'}
                                backgroundColor={theme.colors['Primary']}
                                icon={'Feather/edit'}
                                title={'Edit'}
                              />
                              {/* Swipe Left - Delete */}
                              <SwipeableItemButton
                                closeOnPress={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const deleteResponse = (
                                        await xanoAPIDeleteActionDELETE.mutateAsync(
                                          {
                                            UUID: Constants['UUID'],
                                            action_id: actionsData?.id,
                                            lesson_id: actionsData?.lessons_id,
                                          }
                                        )
                                      )?.json;

                                      showAlertUtil({
                                        title: 'Delete Complete',
                                        message: deleteResponse,
                                        buttonText: 'Okay',
                                      });
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                revealSwipeDirection={'left'}
                                backgroundColor={theme.colors['Error']}
                                icon={'Feather/trash'}
                                title={'Delete'}
                              />
                              {/* Swipe Right - Mark Done */}
                              <SwipeableItemButton
                                closeOnPress={true}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      (
                                        await xanoAPIActionStatusPUT.mutateAsync(
                                          {
                                            UUID: Constants['UUID'],
                                            action_id: actionsData?.id,
                                            lesson_id: actionsData?.lessons_id,
                                            status: 'Done',
                                          }
                                        )
                                      )?.json;
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                {...GlobalStyles.SwipeableItemButtonStyles(
                                  theme
                                )['Swipeable Button'].props}
                                backgroundColor={theme.colors['App Green']}
                                icon={'Feather/check-square'}
                                revealSwipeDirection={'right'}
                                style={StyleSheet.applyWidth(
                                  GlobalStyles.SwipeableItemButtonStyles(theme)[
                                    'Swipeable Button'
                                  ].style,
                                  dimensions.width
                                )}
                                title={'Done'}
                              />
                              {/* Container Outer */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'stretch',
                                    alignSelf: 'auto',
                                    backgroundColor: theme.colors['White'],
                                    borderRadius: 4,
                                    flexDirection: 'column',
                                    flexWrap: 'nowrap',
                                    padding: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* H Stack */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'stretch',
                                      flexDirection: 'row',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Left */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 4,
                                        flexDirection: 'column',
                                        flexWrap: 'nowrap',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Action Title */}
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          {
                                            fontFamily: 'System',
                                            fontSize: 14,
                                            fontWeight: '700',
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {actionsData?.action_title}
                                    </Text>
                                  </View>
                                  {/* Right */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'flex-end',
                                        alignItems: 'stretch',
                                        alignSelf: 'center',
                                        flex: 1,
                                        flexDirection: 'column',
                                        flexWrap: 'wrap',
                                        justifyContent: 'center',
                                      },
                                      dimensions.width
                                    )}
                                  />
                                </View>
                                {/* Action Summary */}
                                <Text
                                  accessible={true}
                                  {...GlobalStyles.TextStyles(theme)['Text']
                                    .props}
                                  style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                      GlobalStyles.TextStyles(theme)['Text']
                                        .style,
                                      {
                                        paddingTop: 5,
                                        textAlign: 'justify',
                                        whiteSpace: 'pre-line',
                                      }
                                    ),
                                    dimensions.width
                                  )}
                                >
                                  {actionsData?.action_item}
                                </Text>
                              </View>
                            </SwipeableItem>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'column' },
                          dimensions.width
                        )}
                      />
                    </>
                  );
                }}
              </XanoAPIApi.FetchGetSingleLessonActionsGET>
            </View>
          )}
        </>
      </SimpleStyleScrollView>
      {/* Edit Action */}
      <Modal
        animationType={'none'}
        supportedOrientations={['portrait', 'landscape']}
        transparent={true}
        visible={edit_action_toggle}
      >
        <BlurView
          {...GlobalStyles.BlurViewStyles(theme)['Blur View'].props}
          intensity={10}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.BlurViewStyles(theme)['Blur View'].style,
              { alignItems: 'center', justifyContent: 'center' }
            ),
            dimensions.width
          )}
          tint={'dark'}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['White'],
                borderRadius: 10,
                height: '80%',
                margin: 30,
                marginBottom: 40,
                marginTop: 40,
                padding: 30,
                width: '80%',
              },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              {...GlobalStyles.TextStyles(theme)['Text'].props}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextStyles(theme)['Text'].style,
                  { fontFamily: 'Roboto_700Bold' }
                ),
                dimensions.width
              )}
            >
              {'Edit Action'}
            </Text>

            <XanoAPIApi.FetchSingleActionGET
              UUID={Constants['UUID']}
              action_id={edit_action_item_id}
              handlers={{
                onData: fetchData => {
                  try {
                    setEdit_action_item_status(fetchData?.action_status);
                    setEdit_action_item_title(fetchData?.action_title);
                    setEdit_action_item_description(fetchData?.action_item);
                  } catch (err) {
                    console.error(err);
                  }
                },
              }}
              lesson_id={props.route?.params?.lessonID ?? 67}
            >
              {({ loading, error, data, refetchSingleAction }) => {
                const fetchData = data?.json;
                if (loading) {
                  return <ActivityIndicator />;
                }

                if (error || data?.status < 200 || data?.status >= 300) {
                  return <ActivityIndicator />;
                }

                return (
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, justifyContent: 'flex-start', marginTop: 20 },
                      dimensions.width
                    )}
                  >
                    {/* Status Input */}
                    <Picker
                      autoDismissKeyboard={true}
                      dropDownBorderColor={theme.colors.divider}
                      dropDownBorderRadius={8}
                      dropDownBorderWidth={1}
                      dropDownTextColor={theme.colors.strong}
                      iconSize={24}
                      leftIconMode={'inset'}
                      mode={'native'}
                      onValueChange={newStatusInputValue => {
                        try {
                          setEdit_action_item_status(newStatusInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Select an option'}
                      selectedIconColor={theme.colors.strong}
                      selectedIconName={'Feather/check'}
                      selectedIconSize={20}
                      type={'solid'}
                      dropDownBackgroundColor={theme.colors['White']}
                      options={[
                        { label: 'To Do', value: 'To Do' },
                        { label: 'In Progress', value: 'In Progress' },
                        { label: 'Done', value: 'Done' },
                      ]}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_status}
                    />
                    {/* Title Input */}
                    <TextField
                      activeBorderColor={theme.colors.primary}
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      onChangeText={newTitleInputValue => {
                        try {
                          setEdit_action_item_title(newTitleInputValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      }
                      type={'solid'}
                      underlineColor={theme.colors.light}
                      webShowOutline={true}
                      label={'Title'}
                      maxLength={75}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_title}
                    />
                    {/* Task Description Input */}
                    <TextField
                      autoCapitalize={'none'}
                      autoCorrect={true}
                      changeTextDelay={500}
                      multiline={true}
                      onChangeText={newTaskDescriptionInputValue => {
                        try {
                          setEdit_action_item_description(
                            newTaskDescriptionInputValue
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={
                        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
                      }
                      type={'solid'}
                      webShowOutline={true}
                      label={'Task Description'}
                      maxLength={600}
                      numberOfLines={10}
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['White'],
                          marginBottom: 10,
                        },
                        dimensions.width
                      )}
                      value={edit_action_item_description}
                    />
                    {/* Update */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        const handler = async () => {
                          try {
                            (
                              await xanoAPIEditActionPUT.mutateAsync({
                                UUID: Constants['UUID'],
                                action_date: fetchData?.action_date,
                                action_id: edit_action_item_id,
                                action_item: edit_action_item_description,
                                action_title: edit_action_item_title,
                                lesson_id: fetchData?.lessons_id,
                                status: edit_action_item_status,
                              })
                            )?.json;

                            showAlertUtil({
                              title: undefined,
                              message: 'You successfully updated this Action.',
                              buttonText: 'Okay',
                            });

                            setEdit_action_toggle(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        GlobalStyles.ButtonStyles(theme)['Button'].style,
                        dimensions.width
                      )}
                      title={'Update'}
                    />
                    {/* Cancel */}
                    <Button
                      iconPosition={'left'}
                      onPress={() => {
                        try {
                          setEdit_action_toggle(false);
                          setEdit_action_item_id('');
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'].style,
                          { marginTop: 15 }
                        ),
                        dimensions.width
                      )}
                      title={'Cancel'}
                    />
                  </View>
                );
              }}
            </XanoAPIApi.FetchSingleActionGET>
          </View>
        </BlurView>
      </Modal>
      {/* Audio Player Sticky */}
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['White'],
            borderTopWidth: 0.5,
            bottom: 0,
            left: 0,
            padding: 10,
            paddingTop: 10,
            position: 'absolute',
            right: 0,
            width: '100%',
            zIndex: 999999,
          },
          dimensions.width
        )}
      >
        <AudioPlayer
          hideDuration={false}
          hidePlaybackIcon={false}
          hideSlider={false}
          interruptionMode={'lower volume'}
          mode={'interface'}
          playThroughEarpieceAndroid={false}
          playsInBackground={false}
          playsInSilentModeIOS={false}
          thumbColor={theme.colors.primary}
          togglePlaybackIconColor={theme.colors.primary}
          togglePlaybackIconSize={24}
          {...GlobalStyles.AudioPlayerStyles(theme)['Audio Player'].props}
          completedTrackColor={theme.colors['Primary']}
          ref={audioPlayerbeipWiWuRef}
          remainingTrackColor={theme.colors['Light']}
          source={{ uri: `${lessonRecording_URL}` }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(
              GlobalStyles.AudioPlayerStyles(theme)['Audio Player'].style,
              {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderWidth: 0,
                padding: null,
                position: 'relative',
              }
            ),
            dimensions.width
          )}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(LessonDetailsScreen);
