import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as XanoAPIApi from '../apis/XanoAPIApi.js';
import HeaderBlock from '../components/HeaderBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as AV from '../custom-files/AV';
import feedbackPromptCheck from '../global-functions/feedbackPromptCheck';
import sleep from '../global-functions/sleep';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  AudioPlayer,
  Button,
  HStack,
  Icon,
  IconButton,
  Link,
  Pressable,
  ScreenContainer,
  SimpleStyleFlatList,
  SimpleStyleScrollView,
  TextInput,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  Image,
  Platform,
  RefreshControl,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const StudentLessonDetailsScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [activateSubmission_Button, setActivateSubmission_Button] =
    React.useState(false);
  const [activeLessonInstance, setActiveLessonInstance] = React.useState(0);
  const [activeSnippet, setActiveSnippet] = React.useState(0);
  const [lessonNotes, setLessonNotes] = React.useState('');
  const [lessonRecording_URL, setLessonRecording_URL] = React.useState('');
  const [lessonStatus, setLessonStatus] = React.useState('');
  const [lesson_id, setLesson_id] = React.useState(0);
  const [recording, setRecording] = React.useState({});
  const [recording_uri, setRecording_uri] = React.useState('');
  const [saveButtonColour, setSaveButtonColour] = React.useState('#1e1e1e');
  const [snippetsToggle, setSnippetsToggle] = React.useState(true);
  const [stateStatus, setStateStatus] = React.useState('');
  const [summary_button, setSummary_button] = React.useState('Show More');
  const [summary_readmore, setSummary_readmore] = React.useState(false);
  const [tabsToggle, setTabsToggle] = React.useState('snippets');
  const [refreshingt4pVV4da, setRefreshingt4pVV4da] = React.useState(false);
  const startRecording = async () => {
    try {
      console.log('Requesting permissions..');
      await AV.Audio.requestPermissionsAsync();
      await AV.Audio.setAudioModeAsync({
        allowsRecordingIOS: true,
        playsInSilentModeIOS: true,
      });

      console.log('Starting recording..');
      const { recording } = await AV.Audio.Recording.createAsync(
        AV.Audio.RecordingOptionsPresets.HIGH_QUALITY
      );
      setRecording(recording);
      console.log('Recording started');
    } catch (err) {
      console.error('Failed to start recording', err);
    }
  };

  const stopRecording = async (Variables, setGlobalVariableValue) => {
    console.log('Stopping recording...');

    let uri = '';
    if (recording) {
      try {
        await recording.stopAndUnloadAsync(); // Stop the recording
        uri = recording.getURI(); // Get the recording URI
        setRecording_uri(uri);

        // Since Audio is imported as AV, use AV.Audio.Sound.createAsync
        const creationResult = await AV.Audio.Sound.createAsync(
          { uri: uri },
          { shouldPlay: false }
        );
        const sound = creationResult.sound;

        // Use a timeout to allow the metadata to load
        setTimeout(async () => {
          const status = await sound.getStatusAsync();
          if (status.isLoaded) {
            console.log('Sound is loaded');
          } else {
            console.log('Failed to load sound');
          }

          // Unload the sound to clean up
          await sound.unloadAsync();
        }, 1000); // Adjust the delay time as needed
      } catch (error) {
        console.error('Failed to stop recording', error);
      }
    }

    console.log('recording data from expo-av: ', recording);

    return uri;
  };

  const handleRecording = async Variables => {
    const UUID = Variables.UUID;
    const authToken = Variables.authToken;
    const deviceCheck = Platform.OS;
    const userName = Variables.name;

    const formData = new FormData();
    formData.append('UUID', UUID);
    formData.append('lesson_id', lesson_id);
    formData.append('device', deviceCheck);
    formData.append('pathway', 'audio');

    // Use the recording object to dynamically set the MIME type and extension
    let fileType = 'audio/m4a'; // Default MIME type
    let fileExtension = 'm4a'; // Default extension
    let fileUri = recording.getURI(); // Method to get URI from the recording object

    if (Platform.OS === 'android') {
      fileType = `audio/${recording._options.android.extension.replace(
        '.',
        ''
      )}`;
      fileExtension = recording._options.android.extension.replace('.', '');
    } else if (Platform.OS === 'ios') {
      fileType = `audio/${recording._options.ios.extension
        .trim()
        .replace('.', '')}`;
      fileExtension = recording._options.ios.extension.trim().replace('.', '');
    } else if (Platform.OS === 'web') {
      fileType = recording._options.web.mimeType;
      fileExtension = fileType.split('/')[1];
    }

    // When creating the name, only add one period between the UUID and file extension
    const audioFile = {
      uri: fileUri,
      type: fileType,
      name: `recording-${UUID}.${fileExtension}`, // Ensure only one period here
    };

    if (Platform.OS === 'web') {
      try {
        const response = await fetch(fileUri);
        const blob = await response.blob();
        formData.append(
          'message_audio',
          blob,
          `recording-${UUID}.${fileExtension}`
        );

        // Convert blob to base64
        const base64data = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            // Resolve only the base64 string without the Data URI scheme prefix
            const result = reader.result;
            const base64String = result.split(',')[1]; // This removes the Data URI prefix
            resolve(base64String);
          };
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });

        // Append base64 data to formData
        formData.append('message_audio_raw', base64data);
      } catch (error) {
        console.error('Error fetching the blob:', error);
        return; // Exit the function if an error occurs
      }
    } else {
      formData.append('message_audio', audioFile);
    }

    // Xano API endpoint
    const apiEndpoint =
      'https://api.heymaestro.me/api:EJWp6KFE/teacher/lesson-notes';

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          Authorization: authToken,
        },
        body: formData,
      });

      if (response.ok) {
        const responseData = await response.json(); // Parse the JSON response
        const teacherNote = responseData.teacherNote; // Access the teacherNote property
        setLessonNotes(teacherNote);
        setStateStatus('ready');
      } else {
        console.error('Upload Failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error during upload:', error);
    }
  };
  const xanoAPITeacherNotePOST = XanoAPIApi.useTeacherNotePOST();
  const xanoAPIInteractionTrackingPOST =
    XanoAPIApi.useInteractionTrackingPOST();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setGlobalVariableValue({
        key: 'activePage',
        value: 'student lesson details',
      });
      setStateStatus('ready');
      setLesson_id(props.route?.params?.lessonID ?? 67);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const audioPlayerlgK0p1F3Ref = React.useRef();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      hasTopSafeArea={true}
      style={StyleSheet.applyWidth(
        { maxHeight: dimensions.height, paddingBottom: 20 },
        dimensions.width
      )}
    >
      {/* Audio Player Sticky */}
      <>
        {!(lessonStatus === 'done') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: theme.colors['White'],
                borderColor: theme.colors['Primary'],
                borderTopWidth: 0.5,
                bottom: 0,
                left: 0,
                padding: 10,
                paddingTop: 10,
                position: 'absolute',
                right: 0,
                width: '100%',
                zIndex: 999999,
              },
              dimensions.width
            )}
          >
            <AudioPlayer
              hideDuration={false}
              hidePlaybackIcon={false}
              hideSlider={false}
              interruptionMode={'lower volume'}
              mode={'interface'}
              playThroughEarpieceAndroid={false}
              playsInBackground={false}
              playsInSilentModeIOS={false}
              thumbColor={theme.colors.primary}
              togglePlaybackIconColor={theme.colors.primary}
              togglePlaybackIconSize={24}
              {...GlobalStyles.AudioPlayerStyles(theme)['Audio Player'].props}
              completedTrackColor={theme.colors['Primary']}
              ref={audioPlayerlgK0p1F3Ref}
              remainingTrackColor={theme.colors['Light']}
              source={{ uri: `${lessonRecording_URL}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.AudioPlayerStyles(theme)['Audio Player'].style,
                  {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderWidth: 0,
                    padding: null,
                    position: 'relative',
                  }
                ),
                dimensions.width
              )}
            />
          </View>
        )}
      </>
      <HeaderBlock />
      <SimpleStyleScrollView
        bounces={true}
        horizontal={false}
        keyboardShouldPersistTaps={'never'}
        nestedScrollEnabled={false}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
      >
        {/* Main */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginTop: 0, paddingLeft: 20, paddingRight: 20 },
            dimensions.width
          )}
        >
          {/* Fetch Lesson Single */}
          <XanoAPIApi.FetchStudentLessonGET
            UUID={Constants['UUID']}
            handlers={{
              onData: fetchLessonSingleData => {
                try {
                  setLessonRecording_URL(
                    fetchLessonSingleData?.lesson_recording?.url
                  );
                  setLessonStatus(fetchLessonSingleData?.status);
                  setLessonNotes(fetchLessonSingleData?.teacher_notes);
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            learnerID={props.route?.params?.learnerID ?? 2}
            lesson_id={props.route?.params?.lessonID ?? 67}
          >
            {({ loading, error, data, refetchStudentLesson }) => {
              const fetchLessonSingleData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <>
                  {/* Summary */}
                  <>
                    {!(fetchLessonSingleData?.status === 'done') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            justifyContent: 'flex-start',
                            marginTop: 0,
                            paddingBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Lesson Title */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 20,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontSize: 20,
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {fetchLessonSingleData?.lesson_title}
                          </Text>
                        </View>
                        {/* Summary */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['White'],
                              borderRadius: 4,
                              marginLeft: 20,
                              marginRight: 20,
                              paddingBottom: 20,
                              paddingLeft: 20,
                              paddingRight: 20,
                              paddingTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Heading */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '700',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Summary'}
                          </Text>
                          {/* Preview Text */}
                          <>
                            {summary_readmore ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                ellipsizeMode={'tail'}
                                numberOfLines={5}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      paddingTop: 10,
                                      textAlign: 'justify',
                                      whiteSpace: 'pre-line',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchLessonSingleData?.lesson_summary}
                              </Text>
                            )}
                          </>
                          {/* Full Text */}
                          <>
                            {!summary_readmore ? null : (
                              <Text
                                accessible={true}
                                {...GlobalStyles.TextStyles(theme)['Text']
                                  .props}
                                ellipsizeMode={'tail'}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text']
                                      .style,
                                    {
                                      fontSize: 14,
                                      paddingTop: 10,
                                      textAlign: 'justify',
                                      whiteSpace: 'pre-line',
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {fetchLessonSingleData?.lesson_summary}
                              </Text>
                            )}
                          </>
                          {/* Summary Toggle Button */}
                          <Link
                            accessible={true}
                            onPress={() => {
                              try {
                                setSummary_readmore(!summary_readmore);
                                if (summary_readmore === true) {
                                  setSummary_button('Show More');
                                }
                                if (summary_readmore === false) {
                                  setSummary_button('Show Less');
                                }
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            {...GlobalStyles.LinkStyles(theme)['Link'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.LinkStyles(theme)['Link'].style,
                                { alignSelf: 'flex-end', marginTop: 10 }
                              ),
                              dimensions.width
                            )}
                            title={`${summary_button}`}
                          />
                        </View>
                      </View>
                    )}
                  </>
                  {/* In Progress */}
                  <>
                    {!(fetchLessonSingleData?.status === 'pending') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: 1,
                            justifyContent: 'flex-start',
                            marginTop: 0,
                            paddingBottom: 20,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Lesson Title */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              marginBottom: 20,
                              marginLeft: 20,
                              marginRight: 20,
                              marginTop: 10,
                            },
                            dimensions.width
                          )}
                        >
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontSize: 20,
                                  textAlign: 'center',
                                  textTransform: 'capitalize',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Current Lesson'}
                          </Text>
                          {/* Heading */}
                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  fontFamily: 'System',
                                  fontSize: 16,
                                  fontWeight: '700',
                                  marginTop: 15,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {'Your lesson is currently in progress.'}
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                  {/* Tabs */}
                  <View>
                    <HStack
                      {...GlobalStyles.HStackStyles(theme)['H Stack'].props}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.HStackStyles(theme)['H Stack'].style,
                          {
                            flex: 1,
                            justifyContent: 'space-evenly',
                            marginBottom: 10,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {/* Snippets Toggle */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setTabsToggle('snippets');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            {
                              backgroundColor: theme.colors['Strong'],
                              borderRadius: 4,
                              minWidth: 100,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Snippets'}
                      />
                      {/* Teacher Notes */}
                      <Button
                        iconPosition={'left'}
                        onPress={() => {
                          try {
                            setTabsToggle('notes');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        {...GlobalStyles.ButtonStyles(theme)['Button'].props}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'].style,
                            {
                              backgroundColor: theme.colors['Strong'],
                              borderRadius: 4,
                              minWidth: 100,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'Notes'}
                      />
                    </HStack>
                  </View>
                  {/* Notes */}
                  <>
                    {!(tabsToggle === 'notes') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 2, marginTop: 20 },
                          dimensions.width
                        )}
                      >
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              { color: null }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Lesson Notes'}
                        </Text>

                        <View
                          style={StyleSheet.applyWidth(
                            { flex: 1 },
                            dimensions.width
                          )}
                        >
                          {/* Recording */}
                          <>
                            {!(stateStatus === 'recording') ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={Images.Recording}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      height: 10,
                                      position: 'absolute',
                                      right: 10,
                                      top: 20,
                                      width: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          {/* Processing */}
                          <>
                            {!(stateStatus === 'processing') ? null : (
                              <Image
                                resizeMode={'cover'}
                                {...GlobalStyles.ImageStyles(theme)['Image']
                                  .props}
                                source={Images.Loading}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ImageStyles(theme)['Image']
                                      .style,
                                    {
                                      height: 10,
                                      position: 'absolute',
                                      right: 10,
                                      top: 20,
                                      width: 10,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              />
                            )}
                          </>
                          <TextInput
                            autoCorrect={true}
                            changeTextDelay={500}
                            multiline={true}
                            onChangeText={newTextAreaValue => {
                              try {
                                setLessonNotes(newTextAreaValue);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            textAlignVertical={'top'}
                            webShowOutline={true}
                            {...GlobalStyles.TextInputStyles(theme)['Text Area']
                              .props}
                            numberOfLines={10}
                            placeholder={'Your lesson notes here...'}
                            placeholderTextColor={theme.colors['Light']}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextInputStyles(theme)['Text Area']
                                  .style,
                                { marginTop: 10 }
                              ),
                              dimensions.width
                            )}
                            value={lessonNotes}
                          />
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-end',
                                marginRight: 10,
                                marginTop: -30,
                              },
                              dimensions.width
                            )}
                          >
                            <IconButton
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setStateStatus('processing');
                                    (
                                      await xanoAPITeacherNotePOST.mutateAsync({
                                        UUID: Constants['UUID'],
                                        lesson_id:
                                          props.route?.params?.lessonID ?? 67,
                                        note: lessonNotes,
                                        pathway: 'text',
                                      })
                                    )?.json;
                                    setSaveButtonColour('#32CD32');
                                    await sleep(setGlobalVariableValue, 1000);
                                    setSaveButtonColour('#1e1e1e');
                                    setStateStatus('ready');
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              color={saveButtonColour}
                              icon={'FontAwesome/save'}
                              size={20}
                            />
                          </View>
                        </View>
                        {/* Recording Buttons */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              flex: 1,
                              justifyContent: 'flex-end',
                              marginTop: 20,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Buttons */}
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignSelf: 'center',
                                flex: 1,
                                flexDirection: 'row',
                                gap: 5,
                              },
                              dimensions.width
                            )}
                          >
                            {/* Start Recording */}
                            <Pressable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setStateStatus('recording');
                                    await startRecording();
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              disabled={stateStatus !== 'ready'}
                              style={StyleSheet.applyWidth(
                                { height: '100%' },
                                dimensions.width
                              )}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor: theme.colors['White'],
                                    borderRadius: 4,
                                    height: 150,
                                    justifyContent: 'center',
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={theme.colors['Error']}
                                  name={'Entypo/controller-record'}
                                />
                              </View>
                            </Pressable>
                            {/* Resume Recording */}
                            <>
                              {!(stateStatus === 'recording paused') ? null : (
                                <Pressable
                                  style={StyleSheet.applyWidth(
                                    { height: '100%' },
                                    dimensions.width
                                  )}
                                >
                                  {/* View 2 */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        backgroundColor: theme.colors['White'],
                                        borderRadius: 4,
                                        height: 150,
                                        justifyContent: 'center',
                                        width: 100,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Text
                                      accessible={true}
                                      {...GlobalStyles.TextStyles(theme)['Text']
                                        .props}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)['Text']
                                            .style,
                                          { fontFamily: 'Roboto_900Black' }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {'RESUME'}
                                    </Text>
                                  </View>
                                </Pressable>
                              )}
                            </>
                            {/* Stop Recording */}
                            <Pressable
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    setStateStatus('recording complete');
                                    await stopRecording(
                                      Variables,
                                      setGlobalVariableValue
                                    );
                                    setStateStatus('processing');
                                    await handleRecording(Variables);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              disabled={stateStatus !== 'recording'}
                              style={StyleSheet.applyWidth(
                                { height: '100%' },
                                dimensions.width
                              )}
                            >
                              {/* View 2 */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    backgroundColor: theme.colors['White'],
                                    borderRadius: 4,
                                    height: 150,
                                    justifyContent: 'center',
                                    width: 100,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Icon
                                  size={24}
                                  color={theme.colors['Custom Color_26']}
                                  name={'Ionicons/stop'}
                                />
                              </View>
                            </Pressable>
                          </View>

                          <Text
                            accessible={true}
                            {...GlobalStyles.TextStyles(theme)['Text'].props}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.TextStyles(theme)['Text'].style,
                                {
                                  color: theme.colors['Medium'],
                                  fontSize: 10,
                                  textAlign: 'center',
                                }
                              ),
                              dimensions.width
                            )}
                          >
                            {
                              'Recording will replace any text in your lesson notes above.'
                            }
                          </Text>
                        </View>
                      </View>
                    )}
                  </>
                  {/* Snippets */}
                  <>
                    {!(tabsToggle === 'snippets') ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { paddingBottom: 100 },
                          dimensions.width
                        )}
                      >
                        {/* Title */}
                        <Text
                          accessible={true}
                          {...GlobalStyles.TextStyles(theme)['Text'].props}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)['Text'].style,
                              {
                                fontFamily: 'Roboto_700Bold',
                                fontSize: 16,
                                marginBottom: 5,
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 20,
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {'Snippets'}
                        </Text>
                        {/* Fetch_Snippets */}
                        <XanoAPIApi.FetchStudentLessonSnippetsGET
                          UUID={Constants['UUID']}
                          learnerID={props.route?.params?.learnerID ?? 2}
                          lesson_id={props.route?.params?.lessonID ?? 67}
                        >
                          {({
                            loading,
                            error,
                            data,
                            refetchStudentLessonSnippets,
                          }) => {
                            const fetchSnippetsData = data?.json;
                            if (loading) {
                              return <ActivityIndicator />;
                            }

                            if (
                              error ||
                              data?.status < 200 ||
                              data?.status >= 300
                            ) {
                              return <ActivityIndicator />;
                            }

                            return (
                              <>
                                {/* Extracts */}
                                <SimpleStyleFlatList
                                  data={fetchSnippetsData?.lesson_snippets}
                                  horizontal={false}
                                  inverted={false}
                                  keyExtractor={(extractsData, index) =>
                                    extractsData?.id
                                  }
                                  keyboardShouldPersistTaps={'never'}
                                  listKey={'t4pVV4da'}
                                  nestedScrollEnabled={false}
                                  numColumns={1}
                                  onEndReachedThreshold={0.5}
                                  refreshControl={
                                    <RefreshControl
                                      refreshing={refreshingt4pVV4da}
                                      onRefresh={() => {
                                        const handler = async () => {
                                          try {
                                            setRefreshingt4pVV4da(true);
                                            await refetchGetSingleLessonSnippets();
                                            setRefreshingt4pVV4da(false);
                                          } catch (err) {
                                            console.error(err);
                                            setRefreshingt4pVV4da(false);
                                          }
                                        };
                                        handler();
                                      }}
                                      colors={[theme.colors['Primary']]}
                                      tintColor={theme.colors['Primary']}
                                    />
                                  }
                                  renderItem={({ item, index }) => {
                                    const extractsData = item;
                                    return (
                                      <>
                                        {/* Container */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'stretch',
                                              alignSelf: 'auto',
                                              backgroundColor:
                                                theme.colors['White'],
                                              borderRadius: 4,
                                              flexDirection: 'row',
                                              flexWrap: 'nowrap',
                                              marginBottom: 10,
                                              marginLeft: 20,
                                              marginRight: 20,
                                              marginTop: 10,
                                              padding: 20,
                                              paddingTop: 20,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Left */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 4,
                                                flexDirection: 'column',
                                                flexWrap: 'nowrap',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* Snippet Title */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    fontFamily: 'System',
                                                    fontSize: 14,
                                                    fontWeight: '700',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {extractsData?.title}
                                            </Text>
                                            {/* Snippet Summary */}
                                            <Text
                                              accessible={true}
                                              {...GlobalStyles.TextStyles(
                                                theme
                                              )['Text'].props}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'].style,
                                                  {
                                                    paddingTop: 5,
                                                    textAlign: 'justify',
                                                    whiteSpace: 'pre-line',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {extractsData?.evita_summary}
                                            </Text>
                                          </View>
                                          {/* Right */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignContent: 'flex-end',
                                                alignItems: 'stretch',
                                                alignSelf: 'center',
                                                flex: 1,
                                                flexDirection: 'column',
                                                flexWrap: 'wrap',
                                                justifyContent: 'center',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <>
                                              {!(
                                                activeSnippet !==
                                                extractsData?.id
                                              ) ? null : (
                                                <IconButton
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          const snippet_startTime =
                                                            extractsData?.start_time;
                                                          audioPlayerlgK0p1F3Ref.current.seekToPosition(
                                                            snippet_startTime
                                                          );
                                                          audioPlayerlgK0p1F3Ref.current.play();
                                                          (
                                                            await xanoAPIInteractionTrackingPOST.mutateAsync(
                                                              {
                                                                UUID: Constants[
                                                                  'UUID'
                                                                ],
                                                                action:
                                                                  'Lesson: ' +
                                                                  ((props.route
                                                                    ?.params
                                                                    ?.lessonID ??
                                                                    67) +
                                                                    (' / Snippet Played: ' +
                                                                      extractsData?.id)),
                                                              }
                                                            )
                                                          )?.json;

                                                          const valuePnLQfdjO =
                                                            extractsData?.id;
                                                          setActiveSnippet(
                                                            valuePnLQfdjO
                                                          );
                                                          const snippetCheck =
                                                            valuePnLQfdjO;
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  color={
                                                    theme.colors['Primary']
                                                  }
                                                  icon={
                                                    'FontAwesome/play-circle'
                                                  }
                                                  size={30}
                                                />
                                              )}
                                            </>
                                            {/* Icon Button 2 */}
                                            <>
                                              {!(
                                                activeSnippet ===
                                                extractsData?.id
                                              ) ? null : (
                                                <IconButton
                                                  onPress={() => {
                                                    const handler =
                                                      async () => {
                                                        try {
                                                          (
                                                            await xanoAPIInteractionTrackingPOST.mutateAsync(
                                                              {
                                                                UUID: Constants[
                                                                  'UUID'
                                                                ],
                                                                action:
                                                                  'Lesson: ' +
                                                                  ((props.route
                                                                    ?.params
                                                                    ?.lessonID ??
                                                                    67) +
                                                                    (' / Snippet Paused: ' +
                                                                      extractsData?.id)),
                                                              }
                                                            )
                                                          )?.json;
                                                          audioPlayerlgK0p1F3Ref.current.pause();
                                                          setActiveSnippet(0);
                                                        } catch (err) {
                                                          console.error(err);
                                                        }
                                                      };
                                                    handler();
                                                  }}
                                                  color={theme.colors['Error']}
                                                  icon={
                                                    'FontAwesome/stop-circle'
                                                  }
                                                  size={30}
                                                />
                                              )}
                                            </>
                                          </View>
                                        </View>
                                      </>
                                    );
                                  }}
                                  showsHorizontalScrollIndicator={false}
                                  showsVerticalScrollIndicator={false}
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: 'column' },
                                    dimensions.width
                                  )}
                                />
                              </>
                            );
                          }}
                        </XanoAPIApi.FetchStudentLessonSnippetsGET>
                      </View>
                    )}
                  </>
                </>
              );
            }}
          </XanoAPIApi.FetchStudentLessonGET>
        </View>
      </SimpleStyleScrollView>
    </ScreenContainer>
  );
};

export default withTheme(StudentLessonDetailsScreen);
